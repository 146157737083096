import React from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { config } from '@utilities/ConfigUtils';
import { Formik, Form, FormikHelpers, Field, ErrorMessage  } from 'formik';
import MainLayout from '@components/layouts/MainLayout';
import { useGuestOrRedirect } from '@utilities/AuthUtils';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import {password_show_mapping} from './../../assets/data';
import { toast } from 'react-toastify';


/**
 * Props
 */
export interface Props {

}

/**
 * Password Reset Page Component
 */
export default function PasswordResetPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Password reset page';
    }, []);

    // Query params 
    const {token} = useParams();

    // Query params 
    const [searchParams, setSearchParams] = useSearchParams();

    // Set search params
    const email = searchParams.get("email");

    // Get request instance
    const requestInstance = useRequestInstance();

    // Ensure user is not authenticated to access this page
    useGuestOrRedirect();

    React.useEffect(() => {

        // Exit if token is not provided
        if(!token && !email) return;

        (async()=>{
            try {

                // Execute api request to register user
                let response = await requestInstance.post((token ? '/auth/password/token' : ''), {token, email});

                if(response?.data?.error)
                    toast(response?.data?.error, {type: "error", autoClose: 5000, onClose: () => navigation('/auth/login')});
                
            } 
            
            catch (exception: any) {

                navigation('/auth/login')
            }
        })();

    }, [ token, email ]);

    // Use navigation
    const navigation = useNavigate();
    
    // Initial values
    const initialValues = { 
        email: email, 
        password: '', 
        password_confirmation : '', 
        token: token };

    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = React.useState(false);

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute api request to register user
            let response = await requestInstance.post('/auth/password/reset', data);

            toast(response?.data?.message || '', {type: "info", autoClose: 5000, onClose: () => navigation('/auth/login')});
        } 
        
        catch (exception: any) {

            // Handle form errors
            handleFormError(exception, helpers);
        }

    }

    return (
        <MainLayout>
            <div className="flex items-center w-full lg:max-w-md justify-center lg:justify-start min-h-[calc(100vh-100px)]">
                <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-2xl md:text-3xl font-extrabold text-white">Reset password</h2>
                </div>

                <Formik
                    enableReinitialize
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="mt-8 space-y-6">
                        <input type="hidden" name="token" value={token} />
                        <input type="hidden" name="email" value="email" />
                        <div className="mb-6">
                            <label htmlFor="password" className="text-white text-md">
                            Password
                            </label>
                            <div className="relative">
                                <Field
                                    id="password"
                                    name="password"
                                    type={passwordShown ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.password ? 'border-1 border-danger' : 'border-none'}`}
                                    placeholder="Password"
                                />
                                <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                <img src={password_show_mapping[`${passwordShown}`]} onClick={ () => setPasswordShown((prev) => !prev)} className="w-4 h-4 inline ml-2" />
                                </div>
                            </div>
                            <FieldErrorMessage message={ helpers.errors?.password } />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="password_confirmation" className="text-white text-md">
                            Confirm password
                            </label>
                            <Field
                                id="password_confirmation"
                                name="password_confirmation"
                                type={passwordShown ? "text" : "password"}
                                autoComplete="off"
                                required
                                className="relative mt-1 h-12 bg-gray-800 block w-full px-3 py-2 text-white placeholder-gray-300 border-none appearance-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Confirm password"
                            />
                            <FieldErrorMessage message={ helpers.errors?.password_confirmation } />
                        </div>

                        <div>
                            <FormButton loading={helpers.isSubmitting} label='Confirm' type='auth' />
                        </div>
                    </Form>
                    )}
                </Formik>
                
                </div>
            </div>
        </MainLayout>
    )
}