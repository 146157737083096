import { Fragment, useRef, useState, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {actionsList} from './../../assets/data';

interface Props {

    open: boolean,

    onClose: () => void,

    confirmAction: ReactNode,

    action: string,

    title?: string,

    text?: string,

    page?: string,
}
 
export default function ConfirmationModal({ ...props }) {

    const cancelButtonRef = useRef(null)

    return (

        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-md sm:mx-0 sm:h-10 sm:w-10 ${ actionsList.find(a => a.action === props.action)?.type === 'danger' ? 'bg-danger/30' : 'bg-success/30'}`}>
                            <img src={actionsList.find(a => a.action === props.action)?.icon ?? 'confirm'} className="w-5 h-5" />
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-md leading-6 font-medium text-white">
                                { props.title ? props.title : actionsList.find(a => a.action === props.action)?.title  }
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-white text-md leading-6">
                                {props.text
                                    ? props.text
                                    : actionsList.find(a => a.action === props.action)?.text
                                }
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                            type="button"
                            className={`w-full inline-flex justify-center rounded-md shadow-sm px-10 py-3 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm ${ actionsList.find(a => a.action === props.action)?.type === 'danger' ? 'bg-danger' : 'bg-gradient'}`}
                            onClick={() => props.confirmAction()}
                            >
                            { actionsList.find(a => a.action === props.action)?.name }
                            </button>
                            <button
                            className="mt-3 w-full inline-flex justify-center rounded-md border-none shadow-sm px-10 py-3 text-base font-medium text-white hover:bg-gray-600 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => props.onClose(false)}
                            ref={cancelButtonRef}
                            >
                                {actionsList.find(a => a.action === props.action)?.name == 'Yes' ? 'No' : 'Cancel'}
                            </button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}