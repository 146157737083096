import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { TransactionInterface } from "@contracts/TransactionContract";
import { WalletInterface } from "@contracts/WalletContract";
import { useUserOrRedirect } from '@utilities/AuthUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import LoadingSpinner from '@components/ui/LoadingSpinner';
import NoResults from '@components/ui/NoResults';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import TableScript from '@components/tables/TableScript';
import { Tooltip } from 'react-tooltip';

import { 
    copy_w
} from './../../assets/images';

let PageSize = 25;

/**
 * Props
 */
export interface Props {

}

/**
 * Transactions Page Component
 */
export default function TransactionsPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Transactions';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Get query param 
    const {id} = useParams<{ id: any }>();

    // Create query state
    const [ selectedWallet, setSelectedWallet ] = React.useState<number>(id ? id : 0);

    // Fetch transactions
    const { data: transactions, error, loading } = usePublicApiFetch<TransactionInterface[]>('/api/transactions');

    // Fetch wallets
    const { data: wallets } = usePublicApiFetch<WalletInterface[]>('/api/wallets');

    // Sort transactions
    const { items, requestSort, sortConfig } = useSortableData(transactions);

    // Tooltip text settings
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Pagination settings
    const [currentPage, setCurrentPage] = useState<number>(1);

    // Get current page transactions
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <DashboardLayout title="Transactions" page="transactions">

            <div className="mt-10">
                {!id && (
                    <div className="space-y-1 flex justify-between items-end">
                        <div className="pr-6">
                            <p className="mt-1 text-sm text-gray-200">Filter by wallet</p>

                            <div className="mt-6 flex space-x-4">
                            <div className="flex-1 min-w-0">
                                <label htmlFor="search" className="sr-only">
                                Filter
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                <select  
                                    name="wallet" 
                                    id="wallet" 
                                    value={ selectedWallet }
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedWallet(parseInt(e.target.value))}
                                    className="focus:ring-secondary focus:border-secondary text-white block w-full pl-10 sm:text-sm border-none bg-gray-700 rounded-md placeholder-gray-300" 
                                >
                                    <option value="0">Select wallet</option>
                                    {wallets?.map((wallet, walletIdx) => (
                                        <option key={ wallet.id } value={ wallet.id }>{ wallet.address }</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="mt-10 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg">
                    {(loading ? <LoadingSpinner /> : 
                        <>
                        {(transactions && transactions.length ?
                        <>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'block_number' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('block_number')}
                                    >
                                    Block #
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                    TX ID
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                    From
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"
                                >
                                    To
                                </th>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'amount' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('amount')}
                                    >
                                    Amount
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"
                                >
                                    Type
                                </th>

                                </tr>
                            </thead>
                            <tbody className="border-l border-b border-r border-gray-600 rounded-md">

                                {currentTableData?.filter((transaction) => transaction?.wallets?.[0]?.id == selectedWallet || selectedWallet === 0).map((transaction, transactionIdx) => (

                                <tr key={transactionIdx}>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                    {transaction.block_number}
                                    </td>
                                    <td
                                    className={` py-4 pl-4 sm:pl-6 pr-3 text-sm hidden-text ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                    {transaction.txid &&
                                        <div id={`token-${transactionIdx+1}`} className="inline-flex mt-1 text-gray-300 justify-start h-[20px] cursor-pointer">
                                            <span className="show">{transaction.txid}</span><span className="hide">{transaction.txid.slice(transaction.txid.length - 6)}</span>
                                            <img 
                                                src={copy_w} 
                                                className="w-4 ml-2" 
                                                onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(transaction.txid); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                            />
                                            <Tooltip 
                                                anchorId={`token-${transactionIdx+1}`}
                                                place="top"
                                                className='tooltip-sm'
                                                html={keyCopied == true ? 'Copied!' : transaction.txid}
                                            />
                                        </div>
                                    }
                                    {transactionIdx !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-500" /> : null}
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                        <div className="break-all">
                                            {transaction.from_address}
                                        </div>
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                        <div className="break-all">
                                            {transaction.to_address}
                                        </div>
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                        {transaction.amount 
                                        ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(transaction.amount) + ' ' + transaction.currency?.code
                                        : '-'
                                        }
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ transactionIdx === 0 ? '' : 'border-t border-gray-500' }`}
                                    >
                                        {transaction.type}
                                    </td>
                                    
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        <TableScript />
                        <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={transactions?.filter((transaction) => transaction?.wallets?.[0]?.id == selectedWallet || selectedWallet === 0).length}
                        pageSize={PageSize}
                        onPageChange={(page: any) => setCurrentPage(page)}
                        />
                        </>
                        : <NoResults item="transactions" /> )}
                        </>
                    )}
                </div>

            </div>

        </DashboardLayout>
    )
}