import React from 'react';
import {
    TooltipProps
  } from "recharts";

/**
 * Display timeframe selector
 */
 export default function CustomTooltip({ active, payload, label }: TooltipProps<number, string>): any {
    if (active && payload && payload.length) {
        return (
        <div className="bg-gray-600 p-3 text-sm rounded-md">
            <p className="text-white mb-1"><span className="font-medium">Date: </span> {label}</p>
            <p className="text-white"><span className="font-medium">Count: </span> {payload?.[0].value}</p>
        </div>
        );
    }

    return null;
}