import React from 'react';
import { ProjectContext } from '@utilities/ProjectUtils';
import { useWebApiFetch } from '@utilities/RequestUtils';
import { ProjectInterface } from '@contracts/ProjectContract';

/**
 * Props
 */
export interface Props {

    // Children
    children: React.ReactNode
}

/**
 * Project Provider
 */
export default function ProjectProvider(props: Props) {

    // Projects
    const { data: projects, error, loading, mutate } = useWebApiFetch<ProjectInterface[]>('/projects');

    return (
        <ProjectContext.Provider value={{
            projects: projects ?? [],
            projectsLoading: loading,
            projectsUpdate: mutate
        }}>
            { props.children }
        </ProjectContext.Provider>
    )
}