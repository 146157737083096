import React from 'react';
import __ from "lodash";


export default function useSortableData(items: any) {

    // define sort options
    const [sortConfig, setSortConfig] = React.useState({ key: '', direction: '' });

    // sort items by direction 
    const sortedItems = React.useMemo(() => {
      let sortableItems = items ? [...items] : [];
      if (sortConfig !== null) {


          sortableItems.sort((a, b) => {
            if (__.get(a, sortConfig.key) < __.get(b, sortConfig.key))
              return sortConfig.direction === 'ascending' ? -1 : 1;
            if (__.get(a, sortConfig.key) > __.get(b, sortConfig.key))
              return sortConfig.direction === 'ascending' ? 1 : -1;
            return 0;
          });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    // set sorting direction
    const requestSort = (key: string) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
}