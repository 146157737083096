import React, { useState, Fragment  } from 'react';
import { useParams } from "react-router-dom";
import { RepaysInterface } from "@contracts/RepaysContract";
import { usePublicApiFetch } from "@utilities/RequestUtils";
import { useRequestInstance } from '@utilities/RequestUtils';
import ConfirmationModal from '@components/ui/ConfirmationModal';
import { useProject } from '@utilities/ProjectUtils';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import NoResults from '@components/ui/NoResults';
import {sort_mapping, statusList} from '../../../assets/data';
import { Menu } from '@headlessui/react';
import { Tooltip } from 'react-tooltip';
import DatePicker from "react-datepicker";
import moment from 'moment/moment.js';

import { 
    check_info
} from '../../../assets/images';
import { CalendarIcon } from '@heroicons/react/solid'
import { ExclamationIcon } from '@heroicons/react/outline'

let PageSize = 25;

const filterItems = (items: RepaysInterface[]|undefined, filters: any) => {
    return items 
    ? items.filter((payment: any) => payment.wallet_token?.customer?.email.toLowerCase().includes(filters.search.toLowerCase()) || payment.wallet_token?.customer?.name.toLowerCase().includes(filters.search.toLowerCase()) || payment.wallet_token?.collection_token?.name.toLowerCase().includes(filters.search.toLowerCase()))
	.filter((payment: any) => payment.status == filters.status || filters.status === '')
    .filter((payment: any) => (filters.updated_at_from && payment.updated_at_timestamp && new Date(payment.updated_at_timestamp * 1000) > filters.updated_at_from) || filters.updated_at_from === null)
    .filter((payment: any) => (filters.updated_at_to && payment.updated_at_timestamp && new Date(payment.updated_at_timestamp * 1000) < filters.updated_at_to) || filters.updated_at_to === null) 
    : items;
};


interface Props {

}
 
export default function RepaymentsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Fetch repayments
    const { data, error, loading, mutate: mutatePayment } = usePublicApiFetch<RepaysInterface[]>('/api/repayments');

   // Filters state
   const [ filters, setFilters ] = React.useState({search: '', status: '', updated_at_from: null, updated_at_to: null});

    // Filter payments
    const repayments = filterItems(data, filters);

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(repayments);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    // Modals settings
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(0);

    // Cancel repay
    const confirmAction = async () => {

        // Hide confirmation modal
        setOpen(false);

        // Update the list of repayments
        await mutatePayment();

        try {
           
            // Execute request
            await requestInstance.post(`/api/repayments/${item}/cancel`, {cancelled_by_user: false});

        } catch (err) {
          console.log(err);
        }
    };

    return (
        <>
        
        <div className="w-full border rounded-md border-gray-600 p-2 sm:p-6 mb-6 flex justify-start items-end flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-[24%] lg:mr-2 mb-4 lg:mb-0">
                <input 
                    name="search" 
                    id="search" 
                    placeholder="Search by NFT or Recipient"
                    value={ filters.search }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilters((prev) => ({...prev, search: e.target.value}))}
                    className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent" 
                />
            </div>
            <div className="w-full lg:w-[24%] lg:mr-2 mb-4 lg:mb-0">
                <div className="relative mr-2">
                    <label htmlFor="status" className="block text-sm text-white mb-1">Status</label>
                    <Menu>
                        <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                            {filters.status && 
                                <span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === filters.status)?.class  }`}>
                                    {filters.status && filters.status.replace(/_/g, ' ')}
                                </span>
                            }
                            {!filters.status && 'All'}
                        </Menu.Button>

                        <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: ''}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="text-white px-1">All</div>
                                    {filters.status == '' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'pending'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'pending')?.class  }`}>Pending</span></div>
                                    {filters.status == 'pending' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'paid'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'paid')?.class  }`}>Paid</span></div>
                                    {filters.status == 'paid' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'canceled_by_user'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'canceled_by_user')?.class  }`}>Canceled by user</span></div>
                                    {filters.status == 'canceled_by_user' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'canceled_by_client'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'canceled_by_client')?.class  }`}>Canceled by client</span></div>
                                    {filters.status == 'canceled_by_client' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>

                </div>
            </div> 
            <div className="w-full lg:w-[26%]">
                <div className="flex items-end justify-start">
                    <div className="min-w-0 w-full">
                        <label className="mb-1">
                            Updated at
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                            <DatePicker 
                                selected={filters.updated_at_from} 
                                onChange={(date: any) => { if(!filters.updated_at_from && !filters.updated_at_to) {setFilters((filters) => ({...filters, updated_at_from: date}))} else if(filters.updated_at_from && !filters.updated_at_to){setFilters((filters) => ({...filters, updated_at_to: date}))} else {setFilters((filters) => ({...filters, updated_at_from: date}))} }} 
                                placeholderText={'From date'} 
                                selectsStart
                                startDate={filters.updated_at_from}
                                endDate={filters.updated_at_to}
                                maxDate={filters.updated_at_to}
                                monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                calendarStartDay={1}
                                dateFormat="dd/MM/yyyy"
                                className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                            />
                        </div>
                    </div>

                    <span className="text-white ml-2">to</span>

                    <div className="min-w-0 w-full ml-2">
                        <label className="sr-only">
                            Filter by end date
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                            <DatePicker 
                                selected={filters.updated_at_to} 
                                onChange={(date: any) => { if(!filters.updated_at_from && !filters.updated_at_to) {setFilters((filters) => ({...filters, updated_at_from: date}))} else if(filters.updated_at_from && !filters.updated_at_to){setFilters((filters) => ({...filters, updated_at_to: date}))} else {setFilters((filters) => ({...filters, updated_at_to: date}))} }}
                                selectsEnd
                                startDate={filters.updated_at_from}
                                endDate={filters.updated_at_to}
                                minDate={filters.updated_at_from}
                                monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                calendarStartDay={1}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={'To date'}
                                className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        {/* end filters */}

        {(!props.loading || (currentTableData && currentTableData.length) ?
        <>


        <div className="table-scroll overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
        <table className="min-w-full rounded-md">
            <thead>
                <tr>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                >
                    Customer address
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                >
                    NFT
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                >
                    Repay Amount
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                >
                    Status
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'updated_at_timestamp' ? sortConfig.direction : undefined}`}
                    onClick={() => requestSort('updated_at_timestamp')}
                >
                    Last update
                    <img src={sort_mapping[sortConfig?.key === 'updated_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                ></th>

                </tr>
            </thead>
            <tbody className="border-l border-b border-r border-gray-600 rounded-md">
                { currentTableData?.map((payment, paymentIndex) => 
                    <tr key={`payment-${payment.id}`}>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <div className="font-medium text-white break-all">
                                {payment.customer_address?.address}
                            </div>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <div id={`repayments-${paymentIndex+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                <a target="_blank" href={payment.wallet_token?.collection_token?.asset_url}>
                                    { payment.wallet_token?.collection_token?.name }: #{payment.wallet_token?.collection_token?.token_id}
                                </a> 
                                <Tooltip 
                                    anchorId={`repayments-${paymentIndex+1}`}
                                    place="top"
                                    className='tooltip-sm'
                                    html={payment.wallet_token?.collection_token?.token_id}
                                />
                            </div>
                            <div className="flex flex-col mt-1 text-gray-400">
                                { payment.wallet_token?.collection_token?.collection?.name }
                            </div>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.total_paid ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(payment.total_paid) : '0'}
                            {' '}/{' '}
                            {payment.total ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(payment.total) : '0'}
                            {' '}{payment.payment?.currency?.code}
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === payment.status)?.class  }`}>
                                {payment.status}
                            </span>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.updated_at_timestamp ? moment.unix(payment.updated_at_timestamp).format("MMM D, YYYY H:s") : ''}
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.status === 'pending' 
                            &&   
                                <a 
                                    href="#" 
                                    className="text-red-500 cursor-pointer border-none text-sm font-medium focus:outline-none"
                                    onClick={(e) => { e.preventDefault(); setOpen(true); setItem(payment.id) }}
                                >
                                    <ExclamationIcon id={`ExclamationIcon${paymentIndex}`} className="w-4 h-4 inline mr-2 text-danger cursor-pointer" />
                                    Cancel
                                </a>
                            }
                        </td>
                    </tr>
                ) }
            </tbody>
        </table>

        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
        />

        <ConfirmationModal open={open} onClose={() => setOpen(false)} confirmAction={confirmAction} action="cancel" title="Cancel repay?" text="Are you sure you want to cancel repay?" />

        </div>
        </div>
        </>
        : <NoResults item="payments" /> )}
        </>
    )
}