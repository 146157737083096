import React, { useState } from 'react';
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { WalletInterface } from "@contracts/WalletContract";
import { WalletBalanceInterface } from "@contracts/WalletBalanceContract";
import { useUserOrRedirect } from '@utilities/AuthUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import LoadingSpinner from '@components/ui/LoadingSpinner';
import NoResults from '@components/ui/NoResults';
import TableScript from '@components/tables/TableScript';
import { Tooltip } from 'react-tooltip';

import { 
    copy_w
} from './../assets/images';

/**
 * Props
 */
export interface Props {

}

/**
 * Wallets Page Component
 */
export default function WalletsPage(props: Props) {

     // Set page title
     React.useEffect(() => {
        document.title = config('app.name') + ' | Wallets';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Tooltip text settings
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Fetch wallets
    const { data: wallets, error, loading } = usePublicApiFetch<WalletInterface[]>('/api/wallets');

    return (
        <DashboardLayout title="Wallets" page="wallets">

            <div className="mt-10">
                
                <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow rounded-lg">
                    {(loading ? <LoadingSpinner /> : 
                        <>
                        {(wallets && wallets.length ?

                        <>

                        { wallets?.map((wallet, walletIndex) => (
                            <div key={walletIndex} className="mt-8">
                            <h3 className="text-white text-md font-bold mb-2">Wallet {walletIndex+1}</h3>

                            <table className="min-w-full rounded-md">
                                <thead>
                                    <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                        Blockchain
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                        Address
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                        Balance
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                        Available balance
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                                    </tr>
                                </thead>
                                <tbody className="border-l border-b border-r border-gray-600 rounded-md">
                                    <tr className="align-top">
                                        <td className={`px-3 py-3.5 text-sm text-white ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            { wallet?.blockchain?.name }
                                        </td>
                                        <td className={`px-3 py-3.5 text-sm text-white hidden-text ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            {wallet.address
                                                ?   <div id={`wallet-${walletIndex}`} className="flex mt-1 justify-start h-[20px] cursor-pointer"><span className="show">{wallet.address}</span><span className="hide">{wallet.address.slice(wallet.address.length - 6)}</span>
                                                        <img 
                                                            src={copy_w} 
                                                            className="w-4 ml-2" 
                                                            onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(wallet.address); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                                        />
                                                        <Tooltip 
                                                            anchorId={`wallet-${walletIndex}`}
                                                            place="top"
                                                            className='tooltip-sm'
                                                            html={keyCopied == true ? 'Copied!' : wallet.address}
                                                        />
                                                    </div>
                                                : <div className="flex flex-col mt-1 text-gray-300">Unknown</div>
                                            }
                                        </td>
                                        <td className={`px-3 py-3.5 text-sm text-white ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            {wallet.balances?.map((walletBalance: WalletBalanceInterface) => (
                                                <div key={`balance-${walletBalance.id}`} className="mb-2">
                                                {walletBalance.balance 
                                                    ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(walletBalance.balance) + ' ' + walletBalance.currency?.code
                                                    : '-'
                                                }
                                                </div>
                                            ))}
                                        </td>
                                        <td className={`px-3 py-3.5 text-sm text-white ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            {wallet.balances?.map((walletBalance: WalletBalanceInterface) => (
                                                <div key={`available-balance-${walletBalance.id}`} className="mb-2">
                                                {walletBalance.available_balance 
                                                    ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(walletBalance.available_balance) + ' ' + walletBalance.currency?.code
                                                    : '-'
                                                }
                                                </div>
                                            ))}
                                        </td>
                                        <td className={`px-3 py-3.5 text-sm text-white ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            <div>
                                                <span className={`w-2 h-2 inline-block rounded-full mr-1 ${wallet.active ? 'bg-info' : 'bg-gray-300'}`}></span>
                                                { wallet.active ? 'Active' : 'Disabled' }
                                            </div>
                                        </td>
                                        <td className={`px-3 py-3.5 text-sm text-white ${ walletIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                            <a href={`/wallets/transactions/${wallet.id}`} className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none">
                                                Transactions
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <TableScript />
                        </div>
                        )
                        ) }
                        </>
                        : <NoResults item="wallets" />

                         )}
                        </>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}