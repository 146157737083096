import { Fragment, useRef, useState, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {formModalTitles} from './../../assets/data';

interface Props {

    open: boolean,

    onClose: () => void,

    action: string,

    item?: number,

    children?: React.ReactNode,

    page?: string,

    title?: string
}
 
export default function FormModal({ ...props }) {

    const cancelButtonRef = useRef(null);

    return (

        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className={`relative bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full sm:p-6 ${props.page == 'collection' || props.page == 'default-collection' ? 'sm:max-w-3xl': 'sm:max-w-lg'}`}>
                            <div className="flex justify-between items-center">
                                <h3 className="text-md font-semibold text-white">{props.title || formModalTitles.find(a => a.action === props.action && a.page === props.page)?.title || props.page + props.action}</h3>

                                <button
                                    type="button"
                                    className=""
                                    onClick={() => props.onClose(false)}
                                    ref={cancelButtonRef}
                                ><XIcon className={`h-6 w-6 text-white`} /></button>
                            </div> 

                            {props.children}

                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}