const currency = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'code', name: 'Code', value: 'String'},
    {column: 'symbol', name: 'Symbol', value: 'String|Null'},
    {column: 'type', name: 'Type', value: 'Enum(fiat|crypto)'},
    {column: 'decimals', name: 'Decimals', value: 'Number'},
];

const project = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'floor_price_difference', name: 'Floor price difference', value: 'Number'},
    {column: 'expiring_days', name: 'Expiring Days', value: 'Number'},
    {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
];

const blockchain = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'}
];

const balance = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'wallet_id', name: 'Wallet ID', value: 'Number'},
    {column: 'currency_id', name: 'Currency ID', value: 'Number'},
    {column: 'balance', name: 'Balance', value: 'Float'},
    {column: 'available_balance', name: 'Available Balance', value: 'Float'},
    {column: 'currency', name: 'Currency', value: 'Object', items: currency},
];

const wallet = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'project_id', name: 'Project ID', value: 'Number'},
    {column: 'blockchain_id', name: 'Blockchain ID', value: 'Number'},
    {column: 'address', name: 'Address', value: 'String'},
    {column: 'active', name: 'Active', value: 'Boolean'},
];

const transaction = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'txid', name: 'Txid', value: 'String'},
    {column: 'from_address', name: 'From Address', value: 'String'},
    {column: 'to_address', name: 'To Address', value: 'String'},
    {column: 'amount', name: 'Amount', value: 'Float'},
    {column: 'block_number', name: 'Block Number', value: 'Number'},
    {column: 'explorer_url', name: 'Explorer Url', value: 'String'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
    {column: 'type', name: 'Type', value: 'Enum(default|topup|token|repay)'},
];

const payment = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'to_address', name: 'To Address', value: 'String'},
    {column: 'amount', name: 'Amount', value: 'Float'},
    {column: 'floor_price', name: 'Floor Price', value: 'Float'},
    {column: 'status', name: 'Status', value: 'Enum(created|pending|successful|reverted)'},
    {column: 'type', name: 'Type', value: 'Enum(manual|automatic)'},
    {column: 'method', name: 'Method', value: 'Enum(internal|external)'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
    {column: 'currency', name: 'Currency', value: 'Object', items: currency},
];

const transfer = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'to_address', name: 'To Address', value: 'String'},
    {column: 'status', name: 'Status', value: 'Enum(created|pending|successful|reverted)'},
    {column: 'type', name: 'Type', value: 'BoolEnum(fiat|crypto)ean'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
];

const repay = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'address', name: 'Address', value: 'String'},
    {column: 'total', name: 'Total', value: 'Float'},
    {column: 'total_paid', name: 'Total paid', value: 'Float'},
    {column: 'status', name: 'Status', value: 'Enum(pending|paid|canceled_by_user|canceled_by_client)'},
    {column: 'updated_at_timestamp', name: 'Updated At Timestamp', value: 'Timestamp'},
];

const market = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'adapter', name: 'Adapter', value: 'String'}
];

const collection = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'forward_id', name: 'Forward ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'contract_address', name: 'Contract Address', value: 'String'},
    {column: 'floor_price_difference', name: 'Floor Price Difference', value: 'Number|Null'},
    {column: 'expiring_days', name: 'Expiring Days', value: 'Number|Null'},
    {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
    {column: 'collection_url', name: 'Collection Url', value: 'String'},
    {column: 'market', name: 'Market', value: 'Object', items: market},
];

const listing_data = [
    {column: 'auto_sale', name: 'Auto Sale', value: 'Boolean'},
    {column: 'listing_floor_price_difference', name: 'Listing Floor Price Difference', value: 'Number'},
    {column: 'listing_days', name: 'Listing_ Days', value: 'Number'},
];

const collection_token = [
    {column: 'token_id', name: 'Token ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'collection', name: 'Collection', value: 'Object', items: collection},
    {column: 'image_url', name: 'Image Url', value: 'String|Null'},
    {column: 'thumbnail_url', name: 'Thumbnail Url', value: 'String|Null'},
    {column: 'asset_url', name: 'Asset Url', value: 'String'},
];

const customer = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'email', name: 'Email', value: 'String'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'details', name: 'Details', value: 'String'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
];

const settings = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'name', name: 'Name', value: 'String'},
    {column: 'value', name: 'Value', value: 'String'}
];

const address = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'address', name: 'Address', value: 'String'}
];

const wallet_token = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'amount', name: 'Amount', value: 'Float'},
    {column: 'from_address', name: 'From Address', value: 'String'},
    {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
    {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
    {column: 'collection_token', name: 'Collection token', value: 'Object', items: collection_token}
];

const sale_listing = [
    {column: 'id', name: 'ID', value: 'Number'},
    {column: 'amount', name: 'Amount', value: 'Float'},
    {column: 'floor_price', name: 'Floor Price', value: 'Float'},
    {column: 'status', name: 'Status', value: 'Enum(created|pending|successful|reverted)'},
    {column: 'type', name: 'Type', value: 'Enum(manual|automatic)'},
    {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
    {column: 'transaction', name: 'Transaction', value: 'Object', items: transaction},
    {column: 'wallet', name: 'Wallet', value: 'Object', items: wallet},
    {column: 'wallet_token', name: 'Wallet Token', value: 'Object', items: wallet_token},
    {column: 'market', name: 'Market', value: 'Object', items: market},
    {column: 'currency', name: 'Currency', value: 'Object', items: currency},
];


// Api docs data
export const apidocs = [
    {
        'title': 'Markets', main: true, type: 'GET | HEAD', color: 'get', url: '/api/markets', href: 'markets', content: market
    },
    {
        'title': 'Wallets', main:true, type: 'GET | HEAD', color: 'get', url: '/api/wallets', href: 'wallets', content: [...wallet, 
            {column: 'project', name: 'Project', value: 'Object', items: project},
            {column: 'blockchain', name: 'Blockchain', value: 'Object', items: blockchain},
            {column: 'balances', name: 'Balances', value: 'Array', collection: true, items: balance},
        ]
    },
    {
        'title': 'Tokens /NFTs/', main: true, type: 'GET | HEAD', color: 'get', url: '/api/tokens', href: 'nfts', content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'amount', name: 'Amount', value: 'Float'},
            {column: 'from_address', name: 'From Address', value: 'String'},
            {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
            {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
            {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
            {column: 'payment', name: 'Payment', value: 'Object', items: [...payment, {column: 'transaction', items: transaction}]},
            {column: 'transfers', name: 'Transfers', value: 'Array', collection: true, items: transfer},
            {column: 'repays', name: 'Repays', value: 'Array', collection: true, items: [...repay, {column: 'payment', name: 'Payment', value: 'Object', items: payment}]},
            {column: 'wallet', name: 'Wallet', value: 'Object', items: wallet},
            {column: 'collection_token', name: 'Collection Token', value: 'Object', items: collection_token},
            {column: 'customer', name: 'Customer', value: 'Object', items: customer},
        ]
    },
    {
        'title': 'Token Transfers', main: false, type: 'POST', color: 'post', url: '/api/tokens/{tokenId}/transfers', href: 'transfers', body: [
            {column: 'token', name: 'Token ID', value: 'Number'},
            {column: 'to_address', name: 'To Address', value: 'String'}
        ]
    },
    {
        'title': 'Sale Listing', main: true, type: 'GET | HEAD', color: 'get', url: '/api/sale-listings', href: 'sale-listing', content: sale_listing
    },
    {
        'title': 'Create Sale Listing', main: false, type: 'GET | HEAD', color: 'get', url: '/api/sale-listings/{tokenId}/create', href: 'sale-listing-create', content: [
            {column: 'floor_price', name: 'Floor Price', value: 'Float'},
            {column: 'floor_price_currency', name: 'Floor Price Currency', value: 'String'},
            {column: 'token', name: 'Token', value: 'Object', items: [
                {column: 'id', name: 'ID', value: 'Number'},
                {column: 'amount', name: 'Amount', value: 'Float'},
                {column: 'from_address', name: 'From Address', value: 'String'},
                {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
                {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
                {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
                {column: 'wallet', collection: true, items: wallet},
                {column: 'collection_token', items: collection_token},
            ]},
            {column: 'collection_stat_id', name: 'Collection Stat ID', value: 'Number'},
            {column: 'floor_price_timestamp', name: 'Floor Price Timestamp', value: 'Number'},
            {column: 'suggested_listing_price', name: 'Suggested Listing Price', value: 'Float'},
            {column: 'suggested_listing_formula', name: 'Suggested Listing Formula', value: 'String'},
        ]
    },
    {
        'title': 'Store Sale Listing', main: false, type: 'POST', color: 'post', url: '/api/sale-listings', href: 'sale-listing-store', body: [
            {column: 'collection_stat_id', name: 'Collection Stat ID', value: 'Number'},
            {column: 'wallet_token_id', name: 'Wallet Token ID', value: 'Number'},
            {column: 'floor_price_currency', name: 'Floor Price Currency', value: 'String'},
            {column: 'market_id', name: 'Market ID', value: 'Number'},
            {column: 'amount', name: 'Amount', value: 'Float'},
        ]
    },
    {
        'title': 'Collections', main: true, type: 'GET | HEAD', color: 'get', url: '/api/collections', href: 'collections', content: collection
    },
    {
        'title': 'Single collection', main: false, type: 'GET | HEAD', color: 'get', url: '/api/collections/{collectionId}', href: 'single-collection', content: collection
    },
    {
        'title': 'Store collection /Whitelist/', main: false, type: 'POST', color: 'post', url: '/api/collections', href: 'store-collection',
        body: [
            {column: 'forward_id', name: 'Forward ID', value: 'Number'},
            {column: 'marketplace', name: 'Marketplace', value: 'Number'},
            {column: 'floor_price_difference', name: 'Floor Price Difference', value: 'Number'},
            {column: 'expiring_days', name: 'Expiring Days', value: 'Number'},
            {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
            {column: 'auto_sale', name: 'Auto Sale', value: 'Boolean'},
            {column: 'listing_floor_price_difference', name: 'Listing Floor Price Difference', value: 'Number'},
            {column: 'listing_days', name: 'Listing_ Days', value: 'Number'},
        ],
        content: collection
    },
    {
        'title': 'Update collection', main: false, type: 'PUT', color: 'put', url: '/api/collections/{collectionId}', href: 'update-collection',
        body: [
            {column: 'floor_price_difference', name: 'Floor Price Difference', value: 'Number'},
            {column: 'expiring_days', name: 'Expiring Days', value: 'Number'},
            {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
            {column: 'auto_sale', name: 'Auto Sale', value: 'Boolean'},
            {column: 'listing_floor_price_difference', name: 'Listing Floor Price Difference', value: 'Number'},
            {column: 'listing_days', name: 'Listing_ Days', value: 'Number'},
        ],
        content: collection
    },
    {
        'title': 'Delete collection', main: false, type: 'DELETE', color: 'delete', url: '/api/collections/{collectionId}', href: 'delete-collection',
    },
    {
        'title': 'Default collection /Project settings/', main: false, type: 'PUT', color: 'put', url: '/api/project-settings', href: 'default-collection',
        body: [
            {column: 'floor_price_difference', name: 'Floor Price Difference', value: 'Number'},
            {column: 'expiring_days', name: 'Expiring Days', value: 'Number'},
            {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
            {column: 'auto_sale', name: 'Auto Sale', value: 'Boolean'},
            {column: 'listing_floor_price_difference', name: 'Listing Floor Price Difference', value: 'Number'},
            {column: 'listing_days', name: 'Listing_ Days', value: 'Number'},
        ],
        content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'name', name: 'Name', value: 'String'},
            {column: 'floor_price_difference', name: 'Floor Price Difference', value: 'Number|Null'},
            {column: 'expiring_days', name: 'Expiring Days', value: 'Number|Null'},
            {column: 'automatic_payout', name: 'Automatic Payout', value: 'Boolean'},
            {column: 'auto_sale', name: 'Auto Sale', value: 'Boolean'},
            {column: 'listing_floor_price_difference', name: 'Listing Floor Price Difference', value: 'Number'},
            {column: 'listing_days', name: 'Listing_ Days', value: 'Number'},
        ]
    },
    {
        'title': 'Customers', main: true, type: 'GET | HEAD', color: 'get', url: '/api/customers', href: 'customers', content: [...customer, {column: 'settings', name: 'Settings', collection: true, value: 'Object', items: settings}]
    },
    {
        'title': 'Single customer', main: false, type: 'GET | HEAD', color: 'get', url: '/api/customers/{customerId}', href: 'single-customer', content: 
        [...customer, {column: 'addresses', name: 'Addresses', collection: true, value: 'Object', items: address},{column: 'settings', name: 'Settings', collection: true, value: 'Object', items: settings}]
    },
    {
        'title': 'Store customer', main: false, type: 'POST', color: 'post', url: '/api/customers', href: 'store-customer',
        body: [
            {column: 'email', name: 'Email', value: 'String'},
            {column: 'name', name: 'Name', value: 'String'},
            {column: 'details', name: 'Details', value: 'String'},
            {column: 'automatic_payout_disabled', name: 'Automatic Payout Disabled', value: 'Boolean'},
            {column: 'addresses', name: 'Addresses', value: '[String]'},
        ],
        content: customer
    },
    {
        'title': 'Update customer', main: false, type: 'PUT', color: 'put', url: '/api/customers/{customerId}', href: 'update-customer',
        body: [
            {column: 'email', name: 'Email', value: 'String'},
            {column: 'name', name: 'Name', value: 'String'},
            {column: 'details', name: 'Details', value: 'String'},
            {column: 'automatic_payout_disabled', name: 'Automatic Payout Disabled', value: 'Boolean'},
            {column: 'addresses', name: 'Addresses', value: 'Object', collection: true, items: [
                {column: "address", name: 'Address', value: 'String'}
            ]},
        ],
        content: customer
    },
    {
        'title': 'Delete customer', main: false, type: 'DELETE', color: 'delete', url: '/api/customers/{customerId}', href: 'delete-customer',
    },
    {
        'title': 'Transactions', main: true, type: 'GET | HEAD', color: 'get', url: '/api/transactions', href: 'transactions', content: 
        [...transaction,  {column: 'currency', name: 'Currency', value: 'Object', items: currency}, {column: 'wallets', name: 'Wallets', value: 'Array', collection: true, items: wallet},]
    },
    {
        'title': 'Payments', main: true, type: 'GET | HEAD', color: 'get', url: '/api/payments', href: 'payments', content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'to_address', name: 'To Address', value: 'String'},
            {column: 'amount', name: 'Amount', value: 'Float'},
            {column: 'floor_price', name: 'Floor Price', value: 'Float'},
            {column: 'status', name: 'Status', value: 'Enum(created|pending|successful|reverted)'},
            {column: 'type', name: 'Type', value: 'Enum(manual|automatic)'},
            {column: 'method', name: 'Method', value: 'Enum(internal|external)'},
            {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
            {column: 'wallet', name: 'Wallet', value: 'Object', items: wallet},
            {column: 'wallet_token', name: 'Wallet Token', value: 'Object', items: 
                [...wallet_token, {column: 'customer', items: customer}]
            },
            {column: 'transaction', name: 'Transaction', value: 'Object', items: transaction},
            {column: 'currency', name: 'Currency', value: 'Object', items: currency},
        ]
    },
    {
        'title': 'Store payment', main: false, type: 'POST', color: 'post', url: '/api/payments', href: 'store-payment',
        body: [
            {column: 'collection_stat_id', name: 'Collection Stat ID', value: 'Number'},
            {column: 'wallet_token_id', name: 'Wallet Token ID', value: 'Number'},
            {column: 'floor_price_currency', name: 'Floor Price Currency', value: 'String'},
            {column: 'amount', name: 'Amount', value: 'Float'},
            // {column: 'paid', name: 'Paid', value: 'Boolean'},
        ],
        content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'to_address', name: 'To Address', value: 'String'},
            {column: 'amount', name: 'Amount', value: 'Float'},
            {column: 'floor_price', name: 'Floor Price', value: 'Float'},
            {column: 'status', name: 'Status', value: 'Enum(created|pending|successful|reverted)'},
            {column: 'type', name: 'Type', value: 'Enum(manual|automatic)'},
            {column: 'method', name: 'Method', value: 'Enum(internal|external)'},
            {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
            {column: 'wallet', name: 'Wallet', value: 'Object', collection: true, items: wallet},
            {column: 'wallet_token', name: 'Wallet Token', value: 'Object', collection: true, items: wallet_token},
            {column: 'currency', name: 'Currency', value: 'Object', items: currency},
        ]
    },
    {
        'title': 'Pending Payments', main: false, type: 'GET | HEAD', color: 'get', url: '/api/pending-payments', href: 'pending-payments', content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'amount', name: 'Amount', value: 'Float'},
            {column: 'from_address', name: 'From Address', value: 'String'},
            {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
            {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
            {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
            {column: 'wallet', name: 'Wallet', value: 'Object', collection: true, items: wallet},
            {column: 'collection_token', name: 'Collection Token', value: 'Object', items: collection_token},
            {column: 'customer', name: 'Customer', value: 'Object', items: customer},
        ]
    },
        {
        'title': 'Cancel Pending Payment', main: false, type: 'POST', color: 'post', url: '/api/pending-payments/{walletTokenId}/cancel', href: 'cancel_pending_payment', body: [

        ]
    },
    {
        'title': 'Single pending payment', main: false, type: 'GET | HEAD', color: 'get', url: '/api/pending-payments/{tokenId}', href: 'single-pending', content: [
            {column: 'floor_price', name: 'Floor Price', value: 'Float'},
            {column: 'floor_price_currency', name: 'Floor Price Currency', value: 'String'},
            {column: 'token', name: 'Token', value: 'Object', items: [
                {column: 'id', name: 'ID', value: 'Number'},
                {column: 'amount', name: 'Amount', value: 'Float'},
                {column: 'from_address', name: 'From Address', value: 'String'},
                {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
                {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
                {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
                {column: 'wallet', collection: true, items: wallet},
                {column: 'collection_token', items: collection_token},
            ]},
            {column: 'collection_stat_id', name: 'Collection Stat ID', value: 'Number'},
            {column: 'floor_price_timestamp', name: 'Floor Price Timestamp', value: 'Number'},
            {column: 'suggested_payment_price', name: 'Suggested Payment Price', value: 'Float'},
            {column: 'suggested_payment_formula', name: 'Suggested Payment Formula', value: 'String'},

        ]
    },
    {
        'title': 'Repayments', main: false, type: 'GET | HEAD', color: 'get', url: '/api/repayments', href: 'repayments', content: [
            {column: 'id', name: 'ID', value: 'Number'},
            {column: 'address', name: 'Address', value: 'String'},
            {column: 'total', name: 'Total', value: 'Float'},
            {column: 'total_paid', name: 'Total Paid', value: 'Float'},
            {column: 'status', name: 'Status', value: 'Enum(pending|paid|canceled_by_user|canceled_by_client)'},
            {column: 'updated_at_timestamp', name: 'Updated At Timestamp', value: 'Timestamp'},
            {column: 'wallet_token', name: 'Wallet Token', value: 'Object', collection: true, items: [
                {column: 'id', name: 'ID', value: 'Number'},
                {column: 'amount', name: 'Amount', value: 'Float'},
                {column: 'from_address', name: 'From Address', value: 'String'},
                {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
                {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
                {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
                {column: 'collection_token', items: collection_token},
                {column: 'payment', items: 
                    [...payment, {column: 'customer_address', items: address}]
                },
            ]},
        ]
    },
    {
        'title': 'Cancel Repay', main: false, type: 'POST', color: 'post', url: '/api/{repayId}/cancel', href: 'cancel_repay', body: [
            {column: 'canceled_by_user', name: 'Canceled By User', value: 'Boolean'},
        ]
    },
    {
        'title': 'Dashboard Statistics', main: true, type: 'GET | HEAD', color: 'get', url: '/api/dashboard-statistics', href: 'dashboard', content: [
            {column: 'nfts', name: 'NFTs', value: 'Number'},
            {column: 'pendingPayments', name: 'Pending Payments', value: 'Number'},
            {column: 'payments', name: 'Payments', value: 'Number'},
            {column: 'wallets', name: 'Wallets', value: 'Array', items: [...wallet, {column: 'balances', collection: true, items: balance}]},
            {column: 'lastNfts', name: 'LastNfts', value: 'Array', items: [
                {column: 'id', name: 'ID', value: 'Number'},
                {column: 'amount', name: 'Amount', value: 'Float'},
                {column: 'from_address', name: 'From Address', value: 'String'},
                {column: 'status', name: 'Status', value: 'Enum(active|expired|redeemed|transferred|overlocated)'},
                {column: 'expiration_at_timestamp', name: 'Expiration At Timestamp', value: 'Timestamp|Null'},
                {column: 'created_at_timestamp', name: 'Created At Timestamp', value: 'Timestamp'},
                {column: 'payment', name: 'Payment', value: 'Object', items: payment},
            ]},
            {column: 'repays', name: 'Repays', value: 'Array', collection: true, items: repay},
            {column: 'collection_token', name: 'Collection Token', value: 'Object', items: collection_token},
            {column: 'dailyPayments', name: 'Daily Payments', value: 'Array', collection: true, items: [
                {column: 'time', name: 'Time', value: 'String'},
                {column: 'value', name: 'Value', value: 'String'}
            ]},
            {column: 'dailyNfts', name: 'Daily Nfts', value: 'Array', collection: true, items: [
                {column: 'time', name: 'Time', value: 'String'},
                {column: 'value', name: 'Value', value: 'String'}
            ]},
            {column: 'paymentsTimeframe', name: 'Payments Timeframe', value: 'String'},
            {column: 'nftsTimeframe', name: 'Nfts Timeframe', value: 'String'}
        ]
    },
];