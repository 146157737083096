import React from 'react';
import { AuthContext } from '@utilities/AuthUtils';
import { BasicCredentialsInterface } from '@contracts/AuthContract';
import { UserInterface } from '@contracts/UserContract';
import { useRequestInstance } from '@utilities/RequestUtils';
import { error } from 'console';

/**
 * Props
 */
export interface Props {

    // Children
    children: React.ReactNode
}

/**
 * Auth Provider
 */
export default function AuthProvider(props: Props) {

    // Authenticated user
    const [ user, setUser ] = React.useState<UserInterface | undefined>(undefined);

    // User loading indicator
    const [ userLoading, setUserLoading ] = React.useState<boolean>(true);

    // User loading indicator
    const [ menuCollapsed, setMenuCollapsed ] = React.useState<string>(localStorage.getItem('menu') || 'true');

    // Get request instance
    const requestInstance = useRequestInstance();

    React.useEffect(() => {

        // Try to find used, stored in storage and 
        // update state with his data.
        // This is used on fresh page loads, when
        // state data is clean.
        const storedUser = localStorage.getItem('user');

        // User exists
        if(storedUser) {

            setUser(JSON.parse(storedUser));
        }

        // Set loading
        setUserLoading(false);

        // Get stored menu state
        const storedMenu = localStorage.getItem('menu');

        // Check if state exist
        if(storedMenu) {

            setMenuCollapsed(storedMenu);
        }

    }, []);

    // Sync user with session
    const syncUser = (sessionUser: UserInterface | undefined) => {

        // No user given, remove from session
        if(!sessionUser) {

            // Remove
            localStorage.removeItem('user');

            return;
        }

        // Stores user in session.
        // This may require more logic to be implemented.
        // Keep it simple for now.
        // @TODO
        localStorage.setItem('user', JSON.stringify(sessionUser));
    }

    // Sign In
    const signIn = async (credentials: BasicCredentialsInterface) => {
        
        try {

            // Execute request
            const response = await requestInstance.post('/auth/login', credentials);

            // Create user object to be stored in state & storage
            const createdUserObject = {
                id: response.data?.data.id,
                email: response.data?.data.email,
                name: response.data?.data.name,
                verified: response.data?.data.verified,
                token: response.data?.meta.token,
                remember_token: response.data?.data.remember_token
            };

            // Store new user
            setUser(createdUserObject);

            // Sync user
            syncUser(createdUserObject);

            // Save logged in time
            localStorage.setItem("loggedAt", JSON.stringify(new Date().getTime()));
        }

        catch (exception: any) {

            // Return raw error message
            throw exception?.response?.data?.message;
        }
    }

    // Sign out
    const signOut = async () => {

        // Remove from session
        syncUser(undefined);

        // Remove user from state
        setUser(undefined);
    }

    // Update user info
    const updateUser = async (userObj: UserInterface | undefined) => {

        // Store new user
        setUser(userObj);

        // Sync user
        syncUser(userObj);
    }

    // Update menu state
    const updateMenu = async (value: string) => {

        // Set menu state
        setMenuCollapsed(value);

        // Store menu state in session
        localStorage.setItem('menu', value);
    }

    return (
        <AuthContext.Provider value={{ user, userLoading, signIn, signOut, updateUser, menuCollapsed, updateMenu }}>
            { props.children }
        </AuthContext.Provider>
    )
}