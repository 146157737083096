import React from 'react';

/**
 * Props
 */
export interface Props {

}

/**
 * Tables script Component
 */
export default function TableScript(props: Props) {

    React.useEffect(() => {
        const tableEls = document.querySelectorAll('table');

        if(tableEls && window.innerWidth < 600){

            tableEls.forEach( table => {
                const ths = table.querySelectorAll('thead th');
                const tdLabels = Array.from(ths).map((el: any) => el.innerText);
                const tdTooltips = Array.from(ths).map((el: any) => el.getAttribute('data-tooltip'));

                table.querySelectorAll('tbody tr').forEach( (tr, i) => {
                    Array.from(tr.children).forEach( 
                    (td, ndx) =>  {
                        // Show mobile labels
                        if(td.innerHTML != '')
                            td.setAttribute('label', tdLabels[ndx]);
                        else
                            td.classList.add('hidden');

                        // Show tooltips
                        if(tdTooltips[ndx]){
                            td.setAttribute('data-html', tdTooltips[ndx]);
                        }
                    }
                    );
                });
            });
        }
    }, []);

    return <></>;
}