/**
 * Footer Component
 */
export default function Footer() {

    return (
        <div className="footer text-center hidden py-4">
            {/* <p> &copy; 2022 eurocoders.com</p> */}
        </div>
    )
}