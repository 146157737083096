/**
 * This is application entrypoint, designed for front-end use case.
 * Please, do not put any logic here.
 */
import './assets/css/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ApplicationProvider from '@components/providers/ApplicationProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ApplicationProvider />
    </React.StrictMode>
);
