import RouterProvider from "@components/providers/RouterProvider";
import { SWRConfig as SWRProvider } from "swr";
import AuthProvider from "@components/providers/AuthProvider";
import ProjectProvider from "@components/providers/ProjectProvider";

/**
 * Application Provider Component
 */
export default function ApplicationProvider() {
    return (
        <SWRProvider>
            <AuthProvider>
                <ProjectProvider>
                    <RouterProvider />
                </ProjectProvider>
            </AuthProvider>
        </SWRProvider>
    )
}