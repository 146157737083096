/**
 * Project utilities 
 */
import React from "react";
import { ProjectInterface, ProjectProviderValue } from "@contracts/ProjectContract";

/**
 * Project context, used by provider component
 * @see ProjectProvider
 */
export const ProjectContext = React.createContext<ProjectProviderValue | undefined>(undefined);

/**
 * Use project context
 */
export function useProjectContext() {
    return React.useContext(ProjectContext) as ProjectProviderValue;
}

/**
 * Use project selected by user. This is in case there's a project selection UI.
 * Currently by default pick the single project existing for user (Default Project) 
 */
export function useProject(): { project?: ProjectInterface, loading: boolean } {

    // Use project context
    const { projects, projectsLoading } = useProjectContext();
    
    // Get default project
    const defaultProject = projects.length > 0 ? projects[0] : undefined;

    // Return
    return { project: defaultProject, loading: projectsLoading };
}