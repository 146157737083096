import React from 'react'
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import { Formik, Form, FormikHelpers, Field, FieldArray, getIn } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { useProject } from '@utilities/ProjectUtils';
import { Tooltip } from 'react-tooltip';
import { 
    info,
    redbin
} from './../../assets/images';

import {
    InformationCircleIcon
  } from '@heroicons/react/outline'
  
import { PlusSmIcon as PlusSmIconSolid, TrashIcon } from '@heroicons/react/solid'


interface Props {

    onClose: () => void
}
 
export default function CustomerCreateForm({ ...props }) {

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Initial values
    const initialValues = { name: '', email: '', details: '', automatic_payout_disabled: false, addresses: [] };

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.post('/api/customers', data);

            props.onClose();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    return (

        <div className="mt-8">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle">

                <Formik
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="space-y-6 w-full mx-auto">
                        <div className="grid grid-cols-12 gap-6 mt-6">

                            <div className="col-span-12">
                                <label 
                                    htmlFor="name" 
                                    className="block text-sm text-white">Name</label>
                                <Field 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.name ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.name } />
                            </div>
                            
                            <div className="col-span-12">
                                <label 
                                    htmlFor="email" 
                                    className="block text-sm text-white">Email</label>
                                <Field 
                                    type="text" 
                                    name="email" 
                                    id="email" 
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.email } />
                            </div>

                            <div className="col-span-12">
                                <label 
                                    htmlFor="details" 
                                    className="flex text-sm text-white relative cursor-pointer items-center">
                                        Additional Identificator
                                        <img id="Identificator" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                        <Tooltip 
                                            anchorId="Identificator" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="Unique identification which helps you identify the user in your systems. (Ex: user id, UUID)."
                                        />
                                </label>
                                <Field 
                                    type="text" 
                                    name="details" 
                                    id="details" 
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.details ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.details } />
                            </div>

                            <div className="col-span-12">
                                <div className="relative flex items-start py-4">
                                    <div className="flex items-center h-5">
                                    <input
                                        id='automatic_payout_disabled'
                                        name='automatic_payout_disabled'
                                        type="checkbox"
                                        checked={helpers.values.automatic_payout_disabled}
                                        value={ helpers.values.automatic_payout_disabled.toString() }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("automatic_payout_disabled", e.target.checked ? 1 : 0)}
                                        className="ml-1 w-4 h-4 text-indigo-600 border-gray-500 bg-gray-900 rounded"
                                    />
                                    <label htmlFor='automatic_payout_disabled' className="text-white select-none ml-2 flex relative cursor-pointer  flex items-center">
                                        Disable automatic payouts
                                    </label>
                                    <img id="payouts" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                    <Tooltip 
                                        anchorId="payouts" 
                                        place="top"
                                        className='tooltip-sm'
                                        html="Disable automatic payouts for this particular user only."
                                    />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                             <div className="grid col-span-12">
                                <label 
                                    htmlFor="address" 
                                    className="block text-sm text-white">Wallet address</label>
                                <FieldArray
                                    name="addresses"
                                    render={arrayHelpers => ( 
                                        <div>
                                            {helpers.values.addresses?.map((a, addressIdx) => (
                                                <>
                                                    <div className={`flex  ${addressIdx != 0 && 'mt-4'} `}>
                                                        <Field 
                                                            type="text" 
                                                            name={`addresses.${addressIdx}`} 
                                                            id={`addresses.${addressIdx}`}  
                                                            key={addressIdx}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue(`addresses.${addressIdx}`, e.target.value)}
                                                            className="mr-2 w-full block h-12 border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm" 
                                                        />
                                                        {addressIdx > 0 && 
                                                        <button
                                                                type="button"
                                                                className="mt-1 ml-1 inline-flex w-[48px] items-center justify-center px-2 py-0 border border-transparent text-sm leading-4 font-sm rounded-md focus:outline-none"
                                                                onClick={() => arrayHelpers.remove(addressIdx)}
                                                            >
                                                            <img src={redbin} className="w-5 h-5" />
                                                        </button>
                                                        }
                                                    </div>

                                                        <FieldErrorMessage message={ getIn(helpers.errors, `addresses.${addressIdx}`) } />
                                                </>
                                            ))}

                                            <div className={helpers.values.addresses && helpers.values.addresses?.length !== 0 ? 'hidden' : ''}>
                                                <FieldErrorMessage message={ helpers.errors?.addresses } />
                                            </div>
                                        <button
                                            type="button"
                                            className="inline-flex mt-1 items-center justify-center text-primary cursor-pointer border-none text-sm focus:outline-none"
                                            onClick={() => arrayHelpers.push('')}
                                        >
                                            <PlusSmIconSolid className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                            Add address
                                        </button>
                                        </div>
                                    )} />

                            </div>
                        </div>

                    <div className="pb-4 flex justify-end">
                        <FormButton loading={helpers.isSubmitting} label='Add new customer' type='submit' />
                    </div>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>
    )
}