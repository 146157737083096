import './../../assets/css/LoadingSpinner.css';

/**
 * Loading spinner component
 */
export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}