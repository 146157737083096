import React from 'react';
import { useNavigate } from "react-router-dom";
import { config } from '@utilities/ConfigUtils';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import MainLayout from '@components/layouts/MainLayout';
import { useAuthContext, useGuestOrRedirect } from '@utilities/AuthUtils';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import {password_show_mapping} from './../../assets/data';

/**
 * Props
 */
export interface Props {

}

/**
 * Register Page Component
 */
export default function RegisterPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Sign up';
    }, []);

     // Use navigation
     const navigation = useNavigate();

    // Get request instance
    const requestInstance = useRequestInstance();

    // Ensure user is not authenticated to access this page
    useGuestOrRedirect();

    // Use auth context
    const authContext = useAuthContext();

    // Initial values
    const initialValues = { 
        name: '', 
        email: '', 
        password: '',
        password_confirmation : '',
        privacy: false
    };

    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = React.useState(false);

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {
        
        try {

            // Execute api request to register user
            await requestInstance.post('/auth/register', data);

            // Redirect to login page
            navigation('/registration/success'); 
        } 
        
        catch (exception: any) {

            // Handle form errors
            handleFormError(exception, helpers);
        }

    }

    return (
        <MainLayout>
            <div className="flex items-center w-full lg:max-w-md justify-center lg:justify-start min-h-[calc(100vh-100px)]">
                <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-2xl md:text-3xl font-extrabold text-white">Sign up</h2>
                </div>

                <Formik
                    initialValues={ initialValues }
                    enableReinitialize
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="mt-8 space-y-6">
                        <div className="rounded-md shadow-sm">
                            <div className="mb-6">
                                <label htmlFor="name" className="text-white text-md">
                                Name
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.name ? 'border-1 border-danger' : 'border-none'}`}
                                    placeholder="Name"
                                />
                                <FieldErrorMessage message={ helpers.errors?.name } />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="email-address" className="text-white text-md">
                                Email address
                                </label>
                                <Field
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : 'border-none'}`}
                                    placeholder="Email address"
                                />
                                <FieldErrorMessage message={ helpers.errors?.email } />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="password" className="text-white text-md">
                                Password
                                </label>
                                <div className="relative">
                                    <Field
                                        id="password"
                                        name="password"
                                        type={passwordShown ? "text" : "password"}
                                        autoComplete="current-password"
                                        required
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.password ? 'border-1 border-danger' : 'border-none'}`}
                                        placeholder="Password"
                                    />
                                    <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                        <img src={password_show_mapping[`${passwordShown}`]} onClick={ () => setPasswordShown((prev) => !prev)} className="w-4 h-4 inline ml-2" />
                                    </div>
                                </div>
                                <FieldErrorMessage message={ helpers.errors?.password } />
                            </div>

                            <div className="mb-6">
                                <label htmlFor="password_confirmation" className="text-white text-md">
                                Confirm password
                                </label>
                                <Field
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type={passwordShown ? "text" : "password"}
                                    autoComplete="off"
                                    required
                                    className="relative mt-1 bg-gray-800 block w-full h-12 px-3 py-2 text-white placeholder-gray-300 border-none appearance-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Confirm password"
                                />
                            </div>

                            <fieldset className="">
                                
                                <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                    <Field 
                                        type="checkbox" 
                                        name="privacy" 
                                        id="privacy"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("privacy", e.target.checked ? 1 : 0)} 
                                        className="focus:ring-indigo-500 h-4 w-4 bg-gray-700 text-indigo-600 border-gray-500 rounded"
                                    />
                                    </div>
                                    <div className="ml-3 text-sm">
                                    <label htmlFor="privacy" className="text-white">
                                        <span>I've read and accept the </span> 
                                        <a className="text-primary hover:text-indigo-500 underline" href="https://nftpay.gg/terms-of-use/" target="_blank">Terms & Conditions *</a> and 
                                        <a className="text-primary hover:text-indigo-500 underline" href="https://nftpay.gg/privacy-policy/" target="_blank"> {' '} Privacy Policy *</a>
                                    </label>
                                    </div>
                                </div>
                                <FieldErrorMessage message={ helpers.errors?.privacy } />
            
                            </fieldset>

                        </div>

                        <div>
                            <FormButton loading={helpers.isSubmitting} label='Sign up' type='auth' />
                        </div>

                        <div className="relative">
                            <div className="relative">
                                <span className="px-2 text-white text-sm">Already have an account?
                                    <a href="/auth/login" className="text-secondary underline text-primary hover:text-indigo-500 ml-2">Sign In</a>
                                </span>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                
                </div>
            </div>
        </MainLayout>
    )
}