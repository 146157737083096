/**
 * Configuration utilities
 */
import * as configFiles from '@config/index';
import __ from 'lodash';

/**
 * Access config variable value.
 * Eg: config('web3.endpoints.https') -> https://test.com/example
 */
export function config<T>(name: string, defaultValue: T | undefined = undefined) {

    // All project configurations
    const configs = configFiles as any;

    // Split name into parts
    const parts = name.split('.');

    // Get target configuration file contents
    const contents = configs[parts[0]];

    // Configuration file does not exists
    if(!contents) return defaultValue;

    // Return
    return __.get(contents, parts.slice(1, parts.length), defaultValue);
}