import React from 'react'

/**
 * Props
 */
export interface Props {

    timeframe?: string,

    fetchData: (timeframe: string | undefined) => Promise<void>
}
  
/**
 * Display timeframe selector
 */
 export default function TimeframeSelector(props: Props) {

    // Timeframe state
    const [activeTimeFrame, setActiveTimeFrame] = React.useState(props.timeframe);

    const timeframe_list = [
        {
            key: '7d',
            value: "7D",
          },
          {
            key: '14d',
            value: "14D",
          },
          {
            key: '30d',
            value: "30D",
          },
       ];

    return (
        <div className="flex justify-center align-center md:mb-3">
            {timeframe_list.map((timeframe) => (
            <span 
            key={timeframe.key}
            className={`mx-1 py-1 px-3 h-6 rounded-md flex align-center text-xs lg:text-sm font-medium bg-gray-900 ${activeTimeFrame == timeframe.key ? "text-white" : 'cursor-pointer text-gray-600'}`}
            onClick={(e) => { e.preventDefault(); setActiveTimeFrame(timeframe.key); props.fetchData(timeframe.key) }}
            >{timeframe.value}</span>
            ))}
        </div>
    );
}