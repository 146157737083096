import React, { useState } from 'react';
import { config } from '@utilities/ConfigUtils';
import { useUserOrRedirect } from '@utilities/AuthUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import {request_type_mapping} from './../assets/data';
import {apidocs} from './../assets/apidocs';
import CodeTree from '@components/docs/CodeTree';
// @ts-ignore
import useScrollSpy from 'react-use-scrollspy';

import { 
  up
} from './../assets/images';

/**
 * Props
 */
export interface Props {

}

/**
 * Api docs Page Component
 */
export default function ApidocsPage(props: Props) {

     // Set page title
     React.useEffect(() => {
        document.title = config('app.name') + ' | API Docs';
    }, []);

    // Array with all navigation links refs
    const refs = React.useMemo(
      () => apidocs.map(() => React.createRef<HTMLElement>()),
      []
    );

    // Get active section
    const activeSection = useScrollSpy({
      sectionElementRefs: refs,
      offsetPx: -100,
    });

    // Ensure user is authenticated
    useUserOrRedirect();

    // Highlighted endpoint link
    const [highlighted, setHighlighted] = React.useState<string>('#login');

    const scroll = (href: string) => {
      document.querySelector( '#'+href ) && document.querySelector( '#'+href )?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
      <DashboardLayout title="API Docs" page="apidocs">
        
        <div className="flex justify-between items-start gap-10 flex-wrap lg:flex-nowrap">

          {/* endpoints sidebar */}
          <div className="rounded-md border border-gray-600 p-4 w-full lg:w-[32%] xl:w-[30%] 2xl:w-[25%] h-auto lg:max-h-[calc(100vh-80px)] overflow-y-auto">

            <div data-to-scrollspy-id="login" className="pb-4 flex justify-between items-center">
              <div className={`max-w-[50%] text-md ${highlighted == '#login' ? 'text-primary' : 'text-white'}`}>
                <a href="#login" onClick={(e) => { e.preventDefault(); setHighlighted('#login'); scroll('login');}}>Login user</a></div>
              <div className="text-yellow-300 text-xs">POST</div>
            </div>

            {apidocs.map((endpoint, index) => (
            <div key={endpoint.href} className={`pb-4 flex justify-between items-center ${activeSection == index ? 'highlighted' : ''} ${highlighted == '#'+endpoint.href ? 'text-primary' : (endpoint.main ? 'text-white' : 'text-gray-300')}`}>
              <div className={`max-w-[60%] text-sm lg:text-md ${!endpoint.main && 'pl-4'}`}>
                <a ref={React.createRef()} href={`#${+endpoint.href}`} onClick={(e) => { e.preventDefault(); setHighlighted('#'+endpoint.href); scroll(endpoint.href);}}>{endpoint.title}</a>
              </div>
              <div className={`text-${request_type_mapping[endpoint.color]} text-xs`}>{endpoint.type}</div>
            </div>
            ))}

          </div>
          {/* end endpoints sidebar */}

          {/* documentation */}
          <div className="col-span-2 lg:h-[calc(100vh-80px)] overflow-y-auto w-full lg:w-[68%] xl:w-[70%] 2xl:w-[75%]">
            <h2 className="font-bold text-white text-3xl mb-4">NFT Pay API Documentation</h2>
            <p className="text-white text-md w-full max-w-[750px]">The NFT Pay platform provides an API that enables developers to programmatically interact with the system through REST endpoints.</p>

            {/* start auth endpoint documentation */}
            <div id="login" className="mt-6">
              <h3 className="text-2xl text-white font-bold mb-3">Login user</h3>

              <div className="border-t border-b border-gray-600 py-3 mb-5">
                <p className="text-gray-400 text-md mb-3">Request</p>
                <div className="flex justify-start items-center mb-3">
                  <span className="text-yellow-300 text-xs mr-4">POST</span>
                  <span className="text-sm text-white">{config('app.apiUrl')}/api/login</span>
                </div>
                <p className="text-white text-sm">Auth required: <span className="text-red-400 text-bold">NO</span></p>
              </div>

              <div className="mb-6">
                <h4 className="font-bold text-white text-md mb-3">Headers</h4>
                <div className="flex justify-start items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md flex flex-col divide-y divide-gray-600 w-full xl:w-[38%]">
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Content-Type</div>
                        <div className="text-xs text-gray-400 break-words">application/json</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Accept</div>
                        <div className="text-xs text-gray-400 break-words">application/json</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                  </div>
                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%]">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"Content-Type":</span> <span className="text-sm text-green-400">"application/json",</span>
                        </div>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"Accept":</span> <span className="text-sm text-green-400">"application/json",</span>
                        </div>
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <h4 className="font-bold text-white text-md mb-3">Body</h4>
                <div className="flex justify-start items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md flex flex-col divide-y divide-gray-600 w-full xl:w-[38%]">
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Email</div>
                        <div className="text-xs text-gray-400">String</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Password</div>
                        <div className="text-xs text-gray-400">String</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                  </div>
                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%]">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"email":</span> <span className="text-sm text-green-400">String,</span>
                        </div>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"password":</span> <span className="text-sm text-green-400">String,</span>
                        </div>
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>
              </div>

              <div className="mb-8">
                <h4 className="text-md text-white font-bold mb-4">Success Response</h4>
                <div className="mb-4 flex just-start items-center"><span className="text-md text-white font-bold mr-2">Code: 200</span> <span className="text-green-300 font-bold mr-2">OK</span> <img src={up} className="text-white w-4" /></div>
                <p className="text-sm text-white mb-3">Content example:</p>

                <div className="flex justify-between items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md w-full xl:w-[38%]">
                    <div className='p-4'>
                      <p className="text-md text-white font-bold mb-2">Data</p>
                      {/*<p className="text-xs text-white mb-3">Lorem ipsum dolor sit amet consectetur.</p>*/}

                      <div className="flex justify-start items-start w-full">
                        <div className="w-4 h-[1px] mt-2 bg-gray-600 mx-2"></div>
                        <div className="mb-2">
                          <div className="flex justify-start items-center mb-2">
                            <div className="text-white font-semibold text-sm mr-3">ID</div>
                            <div className="text-xs text-gray-400">Number</div>
                          </div>
                          {/*<p className="text-xs text-white">Lorem ipsum dolor sit amet consectetur.</p>*/}
                        </div>
                      </div>

                      <div className="flex justify-start items-start w-full">
                        <div className="w-4 h-[1px] mt-2 bg-gray-600 mx-2"></div>
                        <div className="mb-2">
                          <div className="flex justify-start items-center mb-2">
                            <div className="text-white font-semibold text-sm mr-3">Email</div>
                            <div className="text-xs text-gray-400">String</div>
                          </div>
                          {/*<p className="text-xs text-white">Lorem ipsum dolor sit amet consectetur.</p>*/}
                        </div>
                      </div>

                      <div className="flex justify-start items-start w-full">
                        <div className="w-4 h-[1px] mt-2 bg-gray-600 mx-2"></div>
                        <div className="mb-2">
                          <div className="flex justify-start items-center mb-2">
                            <div className="text-white font-semibold text-sm mr-3">Name</div>
                            <div className="text-xs text-gray-400">String</div>
                          </div>
                          {/*<p className="text-xs text-white">Lorem ipsum dolor sit amet consectetur.</p>*/}
                        </div>
                      </div>

                      <p className="text-md text-white font-bold mb-2">Meta</p>
                      {/*<p className="text-xs text-white mb-3">Lorem ipsum dolor sit amet consectetur.</p>*/}

                      <div className="flex justify-start items-start w-full">
                        <div className="w-4 h-[1px] mt-2 bg-gray-600 mx-2"></div>
                        <div className="mb-2">
                          <div className="flex justify-start items-center mb-2">
                            <div className="text-white font-semibold text-sm mr-3">Token</div>
                            <div className="text-xs text-gray-400">String</div>
                          </div>
                          {/*<p className="text-xs text-white">Lorem ipsum dolor sit amet consectetur.</p>*/}
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%]">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-yellow-400">data:</span> <span className="text-gray-200 block mb-3">{'{'}</span>
                            <div className="mb-3 ml-8">
                              <span className="text-sm text-fuchsia-300">"id":</span> <span className="text-sm text-green-400">Number,</span>
                            </div>
                            <div className="mb-3 ml-8">
                              <span className="text-sm text-fuchsia-300">"email":</span> <span className="text-sm text-green-400">String,</span>
                            </div>
                            <div className="mb-3 ml-8">
                              <span className="text-sm text-fuchsia-300">"name":</span> <span className="text-sm text-green-400">String,</span>
                            </div>
                            <div className="mb-3 ml-8">
                              <span className="text-sm text-fuchsia-300">"verified":</span> <span className="text-sm text-green-400">Boolean,</span>
                            </div>
                          <span className="text-gray-200">{'}'},</span>
                        </div>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-yellow-400">meta:</span> <span className="text-gray-200 block mb-3">{'{'}</span>
                            <div className="mb-3 ml-8">
                              <span className="text-sm text-fuchsia-300">"token":</span> <span className="text-sm text-green-400">String,</span>
                            </div>
                          <span className="text-gray-200">{'}'}</span>
                        </div>
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>

              </div>

              <div className="border border-gray-600 rounded-md p-3 mb-4">
                <p className="text-sm text-white font-bold">From now on all request to any API endpoints should be performed with authorization header: Authorization: Bearer Token</p>
              </div>

              <div className="mb-6">
                <h4 className="font-bold text-white text-md mb-3">Headers</h4>
                <div className="flex justify-between items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md flex flex-col divide-y divide-gray-600 w-full xl:w-[38%]">
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Authorization</div>
                        <div className="text-xs text-gray-400">Bearer [token]</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Content-Type</div>
                        <div className="text-xs text-gray-400">application/json</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                    <div className='p-4'>
                      <div className="flex justify-start items-center w-full">
                        <div className="text-white font-semibold text-sm mr-3">Accept</div>
                        <div className="text-xs text-gray-400">application/json</div>
                      </div>
                      {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                    </div>
                  </div>
                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%]">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"Authorization":</span> <span className="text-sm text-green-400">"Bearer [token]",</span>
                        </div>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"Content-Type":</span> <span className="text-sm text-green-400">"application/json",</span>
                        </div>
                        <div className="mb-3 ml-8">
                          <span className="text-sm text-fuchsia-300">"Accept":</span> <span className="text-sm text-green-400">"application/json",</span>
                        </div>
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>
              </div>

            </div>
            {/* end auth endpoint documentation */}


            {/* start endpoint documentation */}
            {apidocs.map((endpoint, index) => (
              <section id={endpoint.href} key={`endpoint-${index}`} className="mt-6 section App-section" ref={refs[index] as React.RefObject<HTMLDivElement>}>
              <h3 className="text-2xl text-white font-bold mb-3">{endpoint.title}</h3>

              <div className="border-t border-b border-gray-600 py-3 mb-5">
                <p className="text-gray-400 text-md mb-3">Request</p>
                <div className="flex justify-start items-center mb-3">
                  <span className={`text-${request_type_mapping[endpoint.color]} text-xs mr-4`}>{endpoint.type}</span>
                  <span className="text-sm text-white">{config('app.apiUrl')}{endpoint.url}</span>
                </div>
                <p className="text-white text-sm">Auth required: <span className="text-green-400 text-bold">YES</span></p>
              </div>

              {endpoint.body &&
              <div className="mb-6">
                <h4 className="font-bold text-white text-md mb-3">Body</h4>
                <div className="flex justify-start items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md flex flex-col divide-y divide-gray-600 w-full xl:w-[33%]">
                    {endpoint.body.map((body, bodyIndex) => (
                      <div className='p-4'>
                        <div className="flex justify-start items-center w-full mb-3">
                          <div className="text-white font-semibold text-sm mr-3">{body.name}</div>
                          <div className="text-xs text-gray-400">{body.value}</div>
                        </div>
                        {/*<p className="text-white text-xs">Lorem ipsum dolor sit amet consectetur.</p>*/}
                      </div>
                    ))}
                  </div>
                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%]">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        {endpoint.body.map((body: any, bodyIndex) => (
                          <CodeTree data={body} />
                        ))}
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              }

              {endpoint.content &&
              <div className="mb-8">
                <h4 className="text-md text-white font-bold mb-4">Success Response</h4>
                <div className="mb-4 flex just-start items-center"><span className="text-md text-white font-bold mr-2">Code: 200</span> <span className="text-green-300 font-bold mr-2">OK</span> <img src={up} className="text-white w-4" /></div>
                <p className="text-sm text-white mb-3">Content example:</p>

                <div className="flex justify-between items-start gap-6 flex-wrap xl:flex-nowrap">
                  <div className="border border-gray-600 rounded-md w-full xl:w-[38%] overflow-hidden">
                    <div className="p-4">
                      <p className="text-md text-white font-bold mb-2">Data</p>
                      {/*<p className="text-xs text-white mb-3">Lorem ipsum dolor sit amet consectetur.</p>*/}

                      {endpoint.content.map((data: any, dataIndex: any) => (
                        <div key={`data-${dataIndex}`} className="flex justify-start items-start w-full mb-3">
                          <div className="w-4 h-[1px] mt-2 bg-gray-600 shrink-0"></div>
                          <div className="mb-2 ml-2">
                            <div className="flex justify-start items-center mb-2">
                              <div className="text-white font-semibold text-sm mr-3">{data.name}</div>
                              <div className="text-xs text-gray-400 truncate">{data.value}</div>
                            </div>
                            {/*<p className="text-xs text-white">Lorem ipsum dolor sit amet consectetur.</p>*/}
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>

                  <div className="bg-gray-700 border border-gray-600 rounded-md p-4 col-span-3 flex items-center w-full xl:w-[62%] overflow-x-auto">
                    <pre>
                      <code>
                        <span className="text-gray-200 block mb-3">{'{'}</span>
                        <div className="mb-3 ml-8">
                          <span className="text-gray-200 block mb-3">{'{'}</span>
                          {endpoint.content.map((data: any, dataIndex: any) => (
                            <CodeTree data={data} />
                          ))}
                          <span className="text-gray-200">{'}'}</span>
                        </div>
                        <span className="text-gray-200">{'}'}</span>
                      </code>
                    </pre>
                  </div>
                </div>

              </div>
              }

            </section>
            ))}
            {/* end endpoint documentation */}

          </div>
          {/* end documentation */}

        </div>

      </DashboardLayout>
    )
}
