import React from 'react'
import { MarketInterface } from "@contracts/MarketContract";
import { usePublicApiFetch } from "@utilities/RequestUtils";
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import { CustomerInterface } from "@contracts/CustomerContract";
import { Formik, Form, FormikHelpers, Field, FieldArray, getIn } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { useProject } from '@utilities/ProjectUtils';
import { Tooltip } from 'react-tooltip';
import { 
    info,
    redbin,
    copy_w
} from './../../assets/images';
import { 
    PlusSmIcon as PlusSmIconSolid
} from '@heroicons/react/solid';


interface Props {

    onClose: () => void,

    item: number
}
 
export default function CustomerEditForm({ ...props }) {

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Tooltip text settings
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Fetch collection
    const { data: customer, error, loading } = usePublicApiFetch<CustomerInterface>(`/api/customers/${props.item}`)

    // Initial values
    const initialValues = { 
        id: customer?.id, 
        name: customer?.name, 
        email: customer?.email, 
        details: customer?.details, 
        automatic_payout_disabled: customer?.settings?.find(s => s.name === 'automatic_payout_disabled')?.value ?? '0',
        addresses: customer?.addresses ?? []
    };

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.put(`/api/customers/${data.id}`, data);

            // Close the modal
            props.onClose();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    return (

        <div className="mt-8 divide-y divide-gray-200">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden  bg-gray-700">

                <Formik
                    initialValues={ initialValues }
                    enableReinitialize
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="space-y-6 w-full mx-auto">
                        <div className="grid grid-cols-12 gap-6">

                            <div className="col-span-12">
                                <label 
                                    htmlFor="name" 
                                    className="block text-sm text-white">Name</label>
                                <Field 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    value={ helpers.values.name }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("name", e.target.value)}
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.name ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.name } />
                            </div>
                            
                            <div className="col-span-12">
                                <label 
                                    htmlFor="email" 
                                    className="block text-sm text-white">Email</label>
                                <Field 
                                    type="text" 
                                    name="email" 
                                    id="email" 
                                    value={ helpers.values.email }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("email", e.target.value)}
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.email } />
                            </div>

                            <div className="col-span-12">
                                <label 
                                    htmlFor="details" 
                                    className="flex text-sm text-white relative cursor-pointer">
                                        Additional Identificator
                                        <img id="Identificator" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                        <Tooltip 
                                            anchorId="Identificator" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="Unique identification which helps you identify the user in your systems. (Ex: user id, UUID)."
                                        />
                                </label>
                                <Field 
                                    type="text" 
                                    name="contract_address" 
                                    id="contract_address" 
                                    value={ helpers.values.details }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("details", e.target.value)}
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.details ? 'border-1 border-danger' : ''}`}
                                />
                                <FieldErrorMessage message={ helpers.errors?.details } />
                            </div>

                            <div className="col-span-12">
                                <div className="relative flex items-start py-4">
                                    <div className="flex items-center h-5">
                                    <input
                                        id='automatic_payout_disabled'
                                        name='automatic_payout_disabled'
                                        type="checkbox"
                                        checked={helpers.values.automatic_payout_disabled && helpers.values.automatic_payout_disabled !== '0' || false}
                                        value={ helpers.values.automatic_payout_disabled.toString() || '0' }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("automatic_payout_disabled", e.target.checked ? 1 : 0)}
                                        className="ml-1 w-4 h-4 text-indigo-600 border-gray-500 bg-gray-900 rounded"
                                    />
                                    <label htmlFor='automatic_payout_disabled' className="text-white select-none ml-2 flex relative cursor-pointer flex items-center">
                                        Disable automatic payouts
                                    </label>
                                    <img id="payouts" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                    <Tooltip 
                                        anchorId="payouts" 
                                        place="top"
                                        className='tooltip-sm'
                                        html="Disable automatic payouts for this particular user only."
                                    />
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                             <div className="grid col-span-12">
                                <label 
                                    htmlFor="address" 
                                    className="block text-sm text-white">Addresses</label>
                                <FieldArray
                                    name="addresses"
                                    render={arrayHelpers => ( 
                                        
                                        <div>
                                            {helpers.values.addresses?.map((a, addressIdx) => (
                                                <div key={addressIdx}>
                                                    <div className={`flex  ${addressIdx != 0 && 'mt-4'} `}>
                                                        <Field 
                                                            type="text" 
                                                            name={`addresses.${addressIdx}.address`} 
                                                            id={`addresses.${addressIdx}.address`}  
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue(`addresses.${addressIdx}.address`, e.target.value)}
                                                            className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`}
                                                        />
                                                        {addressIdx === 0 &&
                                                        <button
                                                            id={`copy-${addressIdx}`}
                                                            type="button"
                                                            className="mt-1 ml-1 inline-flex w-[48px] items-center justify-center px-2 py-0 border border-transparent text-sm leading-4 font-sm rounded-md focus:outline-none"
                                                            onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(a.address); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                                        >
                                                            <img src={copy_w} className="w-4" />
                                                            <Tooltip 
                                                                anchorId={`copy-${addressIdx}`}
                                                                place="top"
                                                                className='tooltip-sm'
                                                                html={keyCopied == true ? 'Copied!' : ''}
                                                            />
                                                        </button>
                                                        }
                                                        {addressIdx > 0 && 
                                                        <button
                                                            type="button"
                                                            className="mt-1 ml-1 inline-flex w-[48px] items-center justify-center px-2 py-0 border border-transparent text-sm leading-4 font-sm rounded-md focus:outline-none"
                                                            onClick={() => arrayHelpers.remove(addressIdx)}
                                                        >
                                                            <img src={redbin} className="w-5 h-5" />
                                                        </button>
                                                        }
                                                    </div>
                                                    <FieldErrorMessage message={ getIn(helpers.errors, `addresses.${addressIdx}.address`) } />
                                                </div>
                                            ))}

                                        <button
                                            type="button"
                                            className="inline-flex mt-1 items-center justify-center text-primary cursor-pointer border-none text-sm focus:outline-none"
                                            onClick={() => arrayHelpers.push({id: 0, address: ''})}
                                        >
                                            <PlusSmIconSolid className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                            Add address
                                        </button>
                                        </div>
                                        
                                    )} />

                            </div>
                        </div>

                    <div className="pb-4 flex justify-end">
                        <FormButton loading={helpers.isSubmitting} label='Save changes' type='submit' />
                    </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}