import React from "react";
import { useAuthContext } from '@utilities/AuthUtils';
import Navbar from '@components/ui/Navbar';
import Footer from '@components/ui/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Props
 */
 export interface Props {

    // Layout children components
    children: React.ReactNode,

    title?: string,

    page?: string
}

/**
 * Main Layout
 */
export default function DashboardLayout(props: Props) {

    // Use auth context
    const authContext = useAuthContext();

    return (
        <>
            <div className="bg-gray-700">
                <Navbar page={ props.page } title={ props.title } />
                <main className={`flex-1 transition-all duration-300 ${authContext?.menuCollapsed === 'true' ? 'md:pl-64' : 'md:pl-24'}`}>
                    <div className="relative py-1 min-h-[calc(100vh-80px)] bg-gray-900 rounded-bl-[50px] overflow-x-hidden">
                        <div className="mx-auto px-6 md:px-12 xl:px-16">
                            { props.children }
                        </div>
                    </div>
                </main>
                <ToastContainer theme="dark" />
                <Footer />
            </div>
        </>
    )
}