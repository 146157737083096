import { config } from '@utilities/ConfigUtils';

  import {
    redbin,
    confirm,
    dashboard, dashboard_active,
    image, image_active,
    collections, collections_active, collections_empty,
    customers, customers_active,
    wallet, wallet_active,
    creditcard, creditcard_active,
    gear, gear_active,
    logout,
    sort_down,
    sort_up,
    order,
    eye_slash,
    eye,
    nodata,
    docs
} from './../assets/images';

// Predefined statuses with classes
export const statusList = [
    { status: 'created', class: 'text-primary border-primary ' },
    { status: 'pending', class: 'text-warning border-warning' },
    { status: 'successful', class: 'text-success border-success' },
    { status: 'paid', class: 'text-success border-success' },
    { status: 'reverted', class: 'text-danger border-danger' },
    { status: 'canceled_by_user', class: 'text-danger border-danger' },
    { status: 'canceled_by_client', class: 'text-danger border-danger' },
    { status: 'danger', class: 'text-danger bg-red-100' },
];

export const nftStatusList = [
    { status: 'created', class: 'text-info border-info' },
    { status: 'listed', class: 'text-primary border-primary ' },
    { status: 'pending', class: 'text-info border-info' },
    { status: 'sold', class: 'text-success border-success' },
    { status: 'expired', class: 'text-danger border-danger' },
];

// Define statistic cards
export const cards = [
    { name: 'NFTs', key: 'nfts', secondkey: '', thirdKey: '', href: '/nfts' },
    { name: 'Payments', key: 'pendingPayments', secondkey: 'successfulPayments', thirdKey: 'revertedPayments',  href: '/payments' },
    { name: 'Wallet balances', key: 'walletBalances', secondkey: '', thirdKey: '', href: '/collections' }
];

// Define list of actions
export const actionsList = [
    { action: 'delete', name: 'Delete', type: 'danger', title: "Are you sure you want to delete it?", text: '', icon: redbin },
    { action: 'cancel', name: 'Yes', type: 'danger', title: "Are you sure you want to cancel it?", text: '', icon: redbin },
    { action: 'confirmKey', name: 'Confirm', type: 'confirm', title: "Confirm regenerate key?", text: 'Regenerating the key will remove the current key and replace it with a new one. Are you sure you want to continue?', icon: confirm },
    { action: 'payment', name: 'Confirm', type: 'confirm', title: "Are you sure you want to execute payment?", text: '', icon: confirm },
];

// Define title tooltips
export const titleTooltips = [
    {action: 'customers', tooltip: '<p class="mb-2">The customers are entities which represent your own customers and their addresses. That way the process of knowing which asset and payment is for which user is simplified greatly. The system allows multiple addresses for a single customer.</p><p>Adding customers can be an optional feature, but useful for analytical purposes and when you want to apply certain rules for.</p>' },
    {action: 'collections', tooltip: '<p>Collections are an assortment of digital assets containing a number of individual NFTs. By whitelisting a collection, you can set custom settings and rules for when an asset of this type is received. Settings include the floor price difference margin, how many days the user has for buying back the asset and if the payouts should be automatic or not.</p><p class="font-bold mt-3">Default collection</p><p>If the system detects a received asset, whose collection is not whitelisted, the `default collection` rules will be applied</p>'},
    {action: 'nfts', tooltip: '<p class="mb-2">Active NFTs: Those are all pending (active) NFTs, which are waiting for your payment or repayment from the user.</p><p class="mb-2">Owned NFTs: All assets which are expired or canceled by the user. Expired NFTs are considered as lost and they belong to you.</p><p class="mb-2">Transferred NFTs: Assets which are being returned to the user (redeemed) or transferred to another wallet.</p>'}
];

// Define form modal titles
export const formModalTitles = [
    { action: 'create', page: 'customer', title: 'Add new customer' },
    { action: 'edit', page: 'customer', title: 'Edit customer' },
    { action: 'create', page: 'collection', title: 'Whitelist a collection' },
    { action: 'edit', page: 'collection', title: 'Edit collection' },
    { action: 'create', page: 'default-collection', title: 'Whitelist a collection' },
    { action: 'edit', page: 'default-collection', title: 'Edit default collection' },
    { action: 'create', page: 'payment', title: 'Create payment' },
];

// Define sidebar navigation
export const navigation = [
    { name: 'Dashboard', href: '/', icon: dashboard, icon_active: dashboard_active, current: 'dashboard' },
    { name: 'NFTs', href: '/nfts', icon: image, icon_active: image_active, current: 'nfts', sub: [{item: 'Active NFTs', link: '#active'}, {item: 'Owned NFTs', link: '#owned'}, {item: 'Listed NFTs', link: '#listed'}, {item: 'Transferred NFTs', link: '#transferred'}, {item: 'Transfer History', link: '#history'}] },
    { name: 'Collections', href: '/collections', icon: collections, icon_active: collections_active, current: 'collections' },
    { name: 'Customers', href: '/customers', icon: customers, icon_active: customers_active, current: 'customers' },
    { name: 'Payouts', href: '/payments', icon: creditcard, icon_active: creditcard_active, current: 'payments', sub: [{item: 'Payments', link: '#payments'}, {item: 'Repayments', link: '#repayments'}] },
];

// Define sidebar navigation bottom links
export const bottom_links = [
    { name: 'Wallets', href: '/wallets', icon: wallet, icon_active: wallet_active, current: 'wallets' },
    { name: 'Profile settings', href: '/settings', icon: gear, icon_active: gear_active, current: 'settings' },
    { name: 'API Docs', href: '/api-docs', icon: docs, icon_active: docs, current: 'apidocs', target: '_blank' },
];

// Map table sorting for showing the sort image
export const sort_mapping = {
    descending: sort_down,
    ascending: sort_up,
    undefined: order
}

// Map token tabs
export const token_tabs_mapping = {
    active: 'active',
    expired: 'expired',
    overlocated: 'expired',
    transferred: 'transferred',
    redeemed: 'transferred',
    listed: 'listed'
}

// Map token listing tab
export const listing_tabs_mapping = {
    created: 'active',
    pending: 'active',
    listed: 'active',
    expired: 'expired',
    cancelled: 'expired',
    sold: 'successful'
}

// Map password show image
export const password_show_mapping = {
    true: eye_slash,
    false: eye
}

// No data component mapping
export const noData = [
    { item: 'Customers', title: 'No data yet', image: nodata, text: '<p className="text-white text-md align-center">The customers are entities which represent your own customers and their addresses. That way the process of knowing which asset and payment is for which user is simplified greatly. The system allows multiple addresses for a single customer.</p><p className="text-white text-md align-center">Adding customers can be an optional feature, but useful for analytical purposes and when you want to apply certain rules for.</p>' },
    { item: 'Collections', title: 'No collection yet', image: collections_empty, text: '' },
];

// Map request types
export const request_type_mapping = {
    get: 'green-300',
    post: 'yellow-400',
    put: 'blue-300',
    delete: 'red-400',
}