import React from 'react';
import { useParams } from "react-router-dom";
import { PaymentInterface } from "@contracts/PaymentContract";
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import { useRequestInstance } from '@utilities/RequestUtils';
import ConfirmationModal from '@components/ui/ConfirmationModal';
import { useProject } from '@utilities/ProjectUtils';
import PaymentCreateForm from '@components/forms/PaymentCreateForm';
import FormModal from '@components/ui/FormModal';
import {sort_mapping, formModalTitles} from './../../../assets/data';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment.js';

import { 
    cash,
    red_up
} from './../../../assets/images';
import { ExclamationIcon } from '@heroicons/react/outline';

let PageSize = 25;


interface Props {

    payments: PaymentInterface,

    onUpdate: () => Promise<void>
}
 
export default function PendingPaymentsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Modal settings
    const [openForm, setOpenForm] = React.useState(false);
    const [item, setItem] = React.useState(0);
    const [title, setTitle] = React.useState(formModalTitles.find(a => a.action === 'create' && a.page === 'payment')?.title);
    // Cancel modal settings
    const [open, setOpen] = React.useState(false);
    // Show pending payments
    const [show, setShow] = React.useState<boolean>(false);

    // Show only 7 rows
    const pending_payments = props.payments ? props.payments.slice(0,7) : props.payments;

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(pending_payments);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    // Cancel pending payment
    const confirmAction = async () => {

        // Hide confirmation modal
        setOpen(false);

        try {
           
            // Execute request
            await requestInstance.post(`/api/pending-payments/${item}/cancel`);

            // Update items
            props.onUpdate();

        } catch (err) {
          console.log(err);
        }
    };

    return (
        <>
        
        {(props.payments && props.payments.length ?
            <>
            <div className="border border-t-4 border-red-400 rounded-md px-4 pt-4 pb-6">
                <div className={`flex justify-between items-center transition-all duration-500 cursor-pointer ${!show && 'mb-4'}`} onClick={ () => setShow((prev) => !prev)}>
                    <span className="text-red-400 text-md">You have {props.payments.length} pending payment{props.payments.length > 1 && 's'}!</span>
                    <img src={red_up} className={`w-4 transition-all duration-300 ${show && 'transform -scale-y-100'}`} />
                </div>
            <div className={`table-scroll overflow-x-auto transition-all duration-500 ${show ? 'h-0' : 'h-auto'}`}>
            <div className="inline-block min-w-full align-middle">
            <table className="min-w-full rounded-md">
                <thead>
                    <tr>
                    <th scope="col" 
                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Sender
                    </th>
                    <th scope="col" 
                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        NFT
                    </th>
                    <th
                        scope="col"
                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('created_at_timestamp')}
                    >
                        Added At
                        <img src={sort_mapping[sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>

                    </tr>
                </thead>
                <tbody className="border-l border-b border-r border-gray-600 rounded-md">
                    {currentTableData?.map((payment: any, paymentIdx: number) => (
                    <tr key={`pending-${payment.id}`}>
                        <td
                        className={` relative px-3 py-3.5 text-sm ${ paymentIdx === 0 ? '' : 'border-t border-gray-600' }`}
                        >
                        <div className="font-medium text-white">
                            {payment.customer && 
                                <a href="/customers" className="font-medium text-white">
                                    {payment.customer.name}
                                </a>
                            }
                            <div className="flex flex-col mt-1 text-gray-400 break-all"> { payment.from_address ? payment.from_address : 'Unknown' }</div>
                        </div>
                        {paymentIdx !== 0 ? <div className="absolute right-0 left-6 -top-px h-px" /> : null}
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIdx === 0 ? '' : 'border-t border-gray-600' }`}>
                            <div id={`token-${paymentIdx+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                <a target="_blank" href={payment.collection_token?.asset_url}>
                                    { payment.collection_token?.name }: #{payment.collection_token?.token_id}
                                </a> 
                                <Tooltip 
                                    anchorId={`token-${paymentIdx+1}`}
                                    place="top"
                                    className='tooltip-sm'
                                    html={ payment.collection_token?.token_id }
                                />
                            </div>
                            <div className="flex flex-col mt-1 text-gray-400">
                                { payment.collection_token?.collection?.name }
                            </div>
                        </td>
                        <td
                        className={` px-3 py-3.5 text-sm text-white ${ paymentIdx === 0 ? '' : 'border-t border-gray-600' }`}
                        >
                            {payment.created_at_timestamp ? moment.unix(payment.created_at_timestamp).format("MMM D, YYYY") : '-'}
                        </td>
                        <td
                        className={` px-3 py-3.5 text-sm text-white ${ paymentIdx === 0 ? '' : 'border-t border-gray-600' }`}
                        >
                            <a 
                                href="#" 
                                className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                onClick={() => { setItem(payment.id); setOpenForm(true) }}
                            >
                                <img src={cash} alt="delete" className="w-4 h-4 mr-2" />
                                Create Payment
                            </a>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIdx === 0 ? '' : 'border-t border-gray-600' }`}>
                            <a 
                                href="#" 
                                className="text-red-500 cursor-pointer border-none text-sm font-medium focus:outline-none"
                                onClick={(e) => { e.preventDefault(); setOpen(true); setItem(payment.id) }}
                            >
                                <ExclamationIcon id={`ExclamationIcon${paymentIdx}`} className="w-4 h-4 inline mr-2 text-danger cursor-pointer" />
                                Cancel
                            </a>
                        </td>
                        
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
            </div>

            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.payments?.length}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
            />
            </>
            : '' )}

        <FormModal open={openForm} onClose={() => setOpenForm(false)} item={item} page='payment' action="create" title={title}>

            <PaymentCreateForm onClose={() => {setOpenForm(false); props.onUpdate()}} item={item} onUpdate={(title: string | undefined) => setTitle(title)} /> 

        </FormModal>

        <ConfirmationModal open={open} onClose={() => setOpen(false)} confirmAction={confirmAction} action="cancel" title="Cancel pending payment?" text="Are you sure you want to cancel pending payment?" />
        
        </>
    )
}