import React from 'react'
import { MarketInterface } from "@contracts/MarketContract";
import { usePublicApiFetch } from "@utilities/RequestUtils";
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import { CollectionInterface } from "@contracts/CollectionContract";
import { Formik, Form, FormikHelpers, Field, getIn } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { useProject } from '@utilities/ProjectUtils';
import { Tooltip } from 'react-tooltip'

import { 
    info
} from './../../assets/images';


interface Props {

    onClose: () => void,

    item: number
}
 
export default function CollectionEditForm({ ...props }) {

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Fetch markets
    const { data: markets } = usePublicApiFetch<MarketInterface[]>('/api/markets');

    // Fetch collection
    const { data: collection, error, loading } = usePublicApiFetch<CollectionInterface>(`/api/collections/${props.item}`);

    // Initial values
    const initialValues = { 
        floor_price_difference: collection?.floor_price_difference, 
        automatic_payout: collection?.automatic_payout ?? false, 
        expiring_days: collection?.expiring_days,
        listing_floor_price_difference: collection?.listing_floor_price_difference,
        listing_days: collection?.listing_days,
        // auto_sale: collection?.auto_sale ?? false, 
        auto_sale: false
    };

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.put(`/api/collections/${collection?.id}`, data);

            // Close the modal
            props.onClose();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    return (

        <div className="mt-8">
            <div className="min-w-full overflow-hidden align-middle">

                <Formik
                    initialValues={ initialValues }
                    enableReinitialize
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="space-y-6 w-full mx-auto">
                        <div className="mt-6 grid grid-cols-12 divide-y sm:divide-x divide-gray-600 sm:divide-y-0">

                            {/* start payment settings */}
                            <div className="col-span-12 sm:col-span-6 sm:pr-6">
                                <h4 className="text-gray-200 mb-4">Payment settings</h4>
                                <div className="mb-6">
                                    <label 
                                        htmlFor="floor_price_difference" 
                                        className="block text-sm text-white">FP Payout Diff.
                                        <img id="fpPayout" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                        <Tooltip 
                                            anchorId="fpPayout" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="How many percents of the floor price should be the payout amount."
                                        />
                                    </label>
                                    <Field 
                                        type="text" 
                                        name="floor_price_difference" 
                                        id="floor_price_difference" 
                                        value={ helpers.values.floor_price_difference }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("floor_price_difference", e.target.value)}
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.floor_price_difference ? 'border-1 border-danger' : ''}`}
                                    />
                                    <FieldErrorMessage message={ helpers.errors?.floor_price_difference } />
                                </div>

                                <div className="mb-6">
                                    <label 
                                        htmlFor="expiring_days" 
                                        className="block text-sm text-white">Days until Expire
                                        <img id="daysUntil" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                        <Tooltip 
                                            anchorId="daysUntil" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="How many days, after successful payout, does the user has to return the liquidity, before losing the NFT."
                                        />
                                    </label>
                                    <Field 
                                        type="text" 
                                        name="expiring_days" 
                                        id="expiring_days" 
                                        value={ helpers.values.expiring_days }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("expiring_days", e.target.value)}
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.expiring_days ? 'border-1 border-danger' : ''}`}
                                    />
                                    <FieldErrorMessage message={ helpers.errors?.expiring_days } />
                                </div>

                                <div className="">
                                    <div className="relative flex items-start py-4">
                                        <div className="flex items-center h-5">
                                        <input
                                            id='automatic_payout1'
                                            name='automatic_payout'
                                            type="checkbox"
                                            checked={helpers.values.automatic_payout}
                                            value={ helpers.values.automatic_payout.toString() }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("automatic_payout", e.target.checked ? 1 : 0)}
                                            className="ml-1 h-4 w-4 bg-gray-900 text-indigo-600 border-gray-300 rounded"
                                        />

                                        <label htmlFor='automatic_payout1' className="ml-2 flex text-white select-none items-center">
                                            Automatic payout
                                        </label>
                                        <img id="automatic_payout_collection_edit" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                        <Tooltip 
                                            anchorId="automatic_payout_collection_edit" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="All received NFTs for that collection will be paid out automatically with the selected settings."
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end payment settings */}

                            {/* start listing settings */}
                            <div className="col-span-12 sm:col-span-6 sm:pl-6 pt-6 sm:pt-0">
                                <h4 className="text-gray-200 mb-4">Listing settings</h4>
                                <div className="mb-6">
                                    <label 
                                        htmlFor="listing_floor_price_difference" 
                                        className="block text-sm text-white">Listing FP Diff.
                                    </label>
                                    <Field 
                                        type="text" 
                                        name="listing_floor_price_difference" 
                                        id="listing_floor_price_difference" 
                                        value={ helpers.values.listing_floor_price_difference }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("listing_floor_price_difference", e.target.value)}
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.listing_floor_price_difference ? 'border-1 border-danger' : ''}`}
                                    />
                                    <FieldErrorMessage message={ helpers.errors?.listing_floor_price_difference } />
                                </div>

                                <div className="mb-6">
                                    <label 
                                        htmlFor="listing_days" 
                                        className="block text-sm text-white">Listing days
                                    </label>
                                    <Field 
                                        type="text" 
                                        name="listing_days" 
                                        id="listing_days" 
                                        value={ helpers.values.listing_days }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("listing_days", e.target.value)}
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.listing_days ? 'border-1 border-danger' : ''}`}
                                    />
                                    <FieldErrorMessage message={ helpers.errors?.listing_days } />
                                </div>

                                {/* <div className="">
                                    <div className="relative flex items-start py-4">
                                        <div className="flex items-center h-5">
                                            <input
                                                id='auto_sale'
                                                name='auto_sale'
                                                type="checkbox"
                                                checked={helpers.values.auto_sale}
                                                value={ helpers.values.auto_sale.toString() }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("auto_sale", e.target.checked ? 1 : 0)}
                                                className="ml-1 h-4 w-4 bg-gray-900 text-indigo-600 border-gray-300 rounded"
                                            />

                                            <label htmlFor='auto_sale' className="ml-2 flex text-white select-none items-center">
                                                Auto sale
                                            </label>
                                            <img id="auto_sale_" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                            <Tooltip 
                                                anchorId="auto_sale_" 
                                                place="top"
                                                className='tooltip-sm'
                                                html="Automatically list for sale when a token of this collection expires"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* end listing settings */}

                        </div>

                        <div className="pb-4 flex justify-end">
                            <FormButton loading={helpers.isSubmitting} label='Save changes' type='submit' />
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}