import React from 'react';
import { useParams } from "react-router-dom";
import { WalletTokenInterface } from "@contracts/WalletTokenContract";
import { Menu } from '@headlessui/react';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import PaymentCreateForm from '@components/forms/PaymentCreateForm';
import FormModal from '@components/ui/FormModal';
import {sort_mapping, formModalTitles} from './../../../assets/data';
import { Tooltip } from 'react-tooltip';
import DatePicker from "react-datepicker";
import moment from 'moment/moment.js';

import { 
    eye,
    cash,
    check_info,
    info
} from './../../../assets/images';
import { CalendarIcon } from '@heroicons/react/solid'

let PageSize = 25;

const filterItems = (items: string[], filters: any) => {
    return items 
    ? items.filter((token: any) => filters.status === '' || (filters.status == 'pending' && token.payment) || (filters.status == 'Not created' && !token.payment))
    .filter((token: any) => token.customer?.email.toLowerCase().includes(filters.search.toLowerCase()) || token.customer?.name.toLowerCase().includes(filters.search.toLowerCase()) || token.collection_token?.name.toLowerCase().includes(filters.search.toLowerCase()))
    .filter((token: any) => (filters.received_at_from && new Date(token.created_at_timestamp * 1000) > filters.received_at_from) || filters.received_at_from === null)
    .filter((token: any) => (filters.received_at_to && new Date(token.created_at_timestamp * 1000) < filters.received_at_to) || filters.received_at_to === null) 
    .filter((token: any) => (filters.expire_at_from && new Date(token.expiration_at_timestamp * 1000) > filters.expire_at_from) || filters.expire_at_from === null)
    .filter((token: any) => (filters.expire_at_to && new Date(token.expiration_at_timestamp * 1000) < filters.expire_at_to) || filters.expire_at_to === null)
    : items;
};

interface Props {

    tokens: WalletTokenInterface,

    filters?: Boolean
}
 
export default function ActiveNftsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Filters state
    const [ filters, setFilters ] = React.useState({search: '', status: '', received_at_from: null, received_at_to: null, expire_at_from: null, expire_at_to: null});
   
    // Filter customer tokens
    const tokens = filterItems(props.tokens, filters);

    // Modal settings
    const [openForm, setOpenForm] = React.useState(false);
    const [item, setItem] = React.useState(0);
    const [title, setTitle] = React.useState(formModalTitles.find(a => a.action === 'create' && a.page === 'payment')?.title);

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(tokens);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <>
                {!props.filters &&
                <>
                {/* filters */}
                <div className="w-full border rounded-md border-gray-600 p-2 sm:p-6 mb-6 flex justify-between items-end flex-wrap xl:flex-nowrap">
                    <div className="w-full xl:w-[25%] xl:mr-2 mb-4 xl:mb-0">
                        <input 
                            name="search" 
                            id="search" 
                            placeholder="Search by NFT or Recipient"
                            value={ filters.search }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilters((prev) => ({...prev, search: e.target.value}))}
                            className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent" 
                        />
                    </div>
                    <div className="w-full xl:w-[20%] xl:mr-2 mb-4 xl:mb-0 grid grid-cols-1">
                        <div className="relative">
                            <label htmlFor="status" className="block text-sm text-white mb-1">Payout status</label>
                            <Menu>
                                <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                                    {filters.status && 
                                        <>
                                            <span className={`w-2 h-2 inline-block rounded-full mr-1 ${filters.status == 'pending' ? 'bg-warning' : 'bg-primary'}`}></span>
                                        </>
                                    }
                                    {filters.status || 'All'}
                                </Menu.Button>

                                <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                                    <Menu.Item>
                                        <a 
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: ''}))} 
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div className="text-white px-1">All</div>
                                            {filters.status == '' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a 
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: 'pending'}))} 
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div><span className={`w-2 h-2 inline-block rounded-full mr-1 bg-warning`}></span> Pending</div>
                                            {filters.status == 'pending' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: 'Not created'}))}
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div><span className={`w-2 h-2 inline-block rounded-full mr-1 bg-primary`}></span> Not Created</div>
                                            {filters.status == 'Not created' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                        </div>
                    </div> 
                    <div className="w-full xl:w-[26%] mb-4 xl:mb-0">
                        <div className="flex items-end justify-start">
                            <div className="min-w-0 w-full">
                                <label className="mb-1">
                                    Received at
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsStart
                                        startDate={filters.received_at_from}
                                        endDate={filters.received_at_to}
                                        maxDate={filters.received_at_to}
                                        selected={filters.received_at_from} 
                                        onChange={(date: any) => { if(!filters.received_at_from && !filters.received_at_to) {setFilters((filters) => ({...filters, received_at_from: date}))} else if(filters.received_at_from && !filters.received_at_to){setFilters((filters) => ({...filters, received_at_to: date}))} else {setFilters((filters) => ({...filters, received_at_from: date}))} }} 
                                        placeholderText={'From date'} 
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>

                            <span className="text-white ml-2">to</span>

                            <div className="min-w-0 w-full ml-2">
                                <label className="sr-only">
                                    Filter by end date
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsEnd
                                        startDate={filters.received_at_from}
                                        endDate={filters.received_at_to}
                                        minDate={filters.received_at_from}
                                        selected={filters.received_at_to} 
                                        onChange={(date: any) => { if(!filters.received_at_from && !filters.received_at_to) {setFilters((filters) => ({...filters, received_at_from: date}))} else if(filters.received_at_from && !filters.received_at_to){setFilters((filters) => ({...filters, received_at_to: date}))} else {setFilters((filters) => ({...filters, received_at_to: date}))} }} 
                                        placeholderText={'From date'} 
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-[26%]">
                        <div className="flex items-end justify-start">
                            <div className="min-w-0 w-full">
                                <label className="mb-1">
                                    Expire in
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsStart
                                        startDate={filters.expire_at_from}
                                        endDate={filters.expire_at_to}
                                        selected={filters.expire_at_from} 
                                        maxDate={filters.expire_at_to}
                                        onChange={(date: any) => { if(!filters.expire_at_from && !filters.expire_at_to) {setFilters((filters) => ({...filters, expire_at_from: date}))} else if(filters.expire_at_from && !filters.expire_at_to){setFilters((filters) => ({...filters, expire_at_to: date}))} else {setFilters((filters) => ({...filters, expire_at_from: date}))} }} 
                                        placeholderText={'From date'} 
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>

                            <span className="text-white ml-2">to</span>

                            <div className="min-w-0 w-full ml-2">
                                <label className="sr-only">
                                    Filter by end date
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsEnd
                                        startDate={filters.expire_at_from}
                                        endDate={filters.expire_at_to}
                                        selected={filters.expire_at_to} 
                                        minDate={filters.expire_at_from}
                                        onChange={(date: any) => { if(!filters.expire_at_from && !filters.expire_at_to) {setFilters((filters) => ({...filters, expire_at_from: date}))} else if(filters.expire_at_from && !filters.expire_at_to){setFilters((filters) => ({...filters, expire_at_to: date}))} else {setFilters((filters) => ({...filters, expire_at_to: date}))} }} 
                                        placeholderText={'From date'} 
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* end filters */}
                </>
                }
            <div className="table-scroll overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
            <table className="min-w-full rounded-md">
                <thead>
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                        
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Token / Collection
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Sender
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[150px]`} data-tooltip="Dates and Time are expressed in UTC">
                        <div 
                            className={`inline sort ${sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : undefined}`}
                            onClick={() => requestSort('created_at_timestamp')}
                        >
                            Received at
                            <img src={sort_mapping[sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                        </div>
                        <img id="created_at_timestamp" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                        <Tooltip 
                            anchorSelect="#created_at_timestamp" 
                            place="top"
                            className='tooltip-sm'
                            html="Dates and Time are expressed in UTC"
                        />
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Payout status 
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'expiration_at_timestamp' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('expiration_at_timestamp')}
                    >
                        Expires In
                        <img src={sort_mapping[sortConfig?.key === 'expiration_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'expiration_at_timestamp' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('expiration_at_timestamp')}
                    >
                        Repay amount
                        <img src={sort_mapping[sortConfig?.key === 'expiration_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                    </tr>
                </thead>
                <tbody  className="border-l border-b border-r border-gray-600 rounded-md">
                    {currentTableData?.map((token, tokenIndex) => (
                        <tr key={token.id}>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div className="aspect-w-1 aspect-h-1 w-16 overflow-hidden rounded-lg cursor-pointer bg-gray-200 block">
                                    <img
                                    src={token.collection_token?.thumbnail_url}
                                    alt={token.collection_token?.name}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div id={`token-${tokenIndex+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                    { token.collection_token?.name }: #{ token.collection_token?.token_id }
                                </div>
                                <div className="flex flex-col mt-1 text-gray-400">
                                    { token.collection_token?.collection?.name }
                                </div>
                                <Tooltip 
                                    anchorId={`token-${tokenIndex+1}`}
                                    place="top"
                                    className='tooltip-sm'
                                    html={ token.collection_token?.token_id }
                                />
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.customer && 
                                <div className="font-medium text-white">
                                    {token.customer.email}
                                </div>
                                }
                                {token.from_address 
                                    ? <div className="flex flex-col mt-1 text-gray-300 break-all">{ token.from_address }</div>
                                    : <div className="flex flex-col mt-1 text-gray-300">Unknown</div>
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.created_at_timestamp && moment.unix(token.created_at_timestamp).format("D/MM/YYYY H:s")}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div>
                                    <span className={`w-2 h-2 inline-block rounded-full mr-1 ${token.payment ? 'bg-warning' : 'bg-primary'}`}></span>
                                    {token.payment ? ' Pending' : ' Not created'}
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {(token.expiration_at_timestamp && token.payment) ? moment.unix(token.expiration_at_timestamp).fromNow() : '-'}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {(token.repays && token.repays.length)
                                    ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(token.repays?.[0]?.total) + ' ' + token?.payment?.currency?.code
                                    : '-'
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-primary ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <a href={token.collection_token?.asset_url} target="_blank" className="text-primary w-44 cursor-pointer py-1 px-2 ml-2 border-none rounded-md shadow-sm inline-flex justify-center text-sm font-medium  focus:outline-none">
                                    <img src={eye} alt="view" className="w-4 h-4 mr-2 text-primary" />
                                    View
                                </a>
                            </td>
                            <td className={`px-3 py-3.5 text-sm ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {!token.payment && 
                                    <a 
                                        href="#" 
                                        className="text-primary cursor-pointer whitespace-nowrap border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                        onClick={(e) => { e.preventDefault(); setItem(token.id); setOpenForm(true) }}
                                    >
                                        <img src={cash} alt="delete" className="w-4 h-4 mr-2" />
                                        Create Payment
                                    </a>
                                }
                            </td>
                            
                        
                        </tr>
                    ))}
                </tbody>
            </table>
    
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.tokens?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
            />
            </div>
            </div>

        <FormModal open={openForm} onClose={() => setOpenForm(false)} item={item} page='payment' action="create" title={title}>

            <PaymentCreateForm onClose={() => {setOpenForm(false);}} item={item} onUpdate={(title: string | undefined) => setTitle(title)} /> 

        </FormModal>
        </>
    )
}