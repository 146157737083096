import React from 'react';
import { useParams } from "react-router-dom";
import { WalletTokenInterface } from "@contracts/WalletTokenContract";
import ListedNftsTable from '@components/tables/nfts/ListedNftsTable';
import {listing_tabs_mapping} from './../../../assets/data';


interface Props {

    tokens: WalletTokenInterface,

    filters?: Boolean
}
 
export default function ListedNfts({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Filter tokens by expired status
    const list_tokens = props.tokens?.reduce((acc: any, cur: any) => {
        acc[listing_tabs_mapping[cur['status']] || cur['status']] = [...acc[listing_tabs_mapping[cur['status']] || cur['status']] || [], cur];
        return acc;
    }, {});

    return (
        <>
            <div className="table-scroll overflow-x-auto">
                <div className="inline-block min-w-full align-middle">

                    <ListedNftsTable tokens={list_tokens?.['active']} title="Active listings" />
                    <ListedNftsTable tokens={list_tokens?.['expired']} title="Expired listings" />
                    <ListedNftsTable tokens={list_tokens?.['successful']} title="Successful listings" />
        
                </div>
            </div>
        </>
    )
}