import React from 'react';
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { useAuthContext, useUserOrRedirect } from '@utilities/AuthUtils';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import { useProject } from '@utilities/ProjectUtils';
import { useRequestInstance } from '@utilities/RequestUtils';
import ConfirmationModal from '@components/ui/ConfirmationModal';
import LoadingSpinner from '@components/ui/LoadingSpinner';
import { useProjectContext } from '@utilities/ProjectUtils';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { handleFormError } from '@utilities/FormUtils';
import FormButton from '@components/form/FormButton';
import { check, copy, eye, copy_w, eye_w, eye_slash } from './../assets/images';
import { toast } from 'react-toastify';

import { ClipboardCopyIcon } from '@heroicons/react/outline';

/**
 * Props
 */
export interface Props {}

/**
 * Settings Page Component
 */
export default function SettingsPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Profile settings';
    }, []);

    // Ensure user is authenticated
    const { user } = useUserOrRedirect();

    // Use project context
    const projectContext = useProjectContext();

    // Use auth context
    const authContext = useAuthContext();

    // Use request instance
    const requestInstance = useRequestInstance(user?.token);

    // Create key state
    const [ keyShown, setKeyShown ] = React.useState<boolean>(false);
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Modal settings
    const [open, setOpen] = React.useState<boolean>(false);

    // use current project
    const { project, loading } = useProject();

    // Initial Password values
    const initialPasswordValues = { current_password: '', password: '', password_confirmation: '' };

    // Initial values
    const initialValues = { name: user?.name, email: user?.email, verified: false };

    // Resend verification email
    const resendVerificationEmail = async () => {

        try {
           
            // Execute request
            const response = await requestInstance.get('/auth/email/resend');

            if(response.data?.verified)
                authContext.updateUser({...user, verified: response.data?.verified});

            if(response.data?.message)
                toast(response.data?.message);

        } catch (err) {
          //console.log(err);
        }
    };

    // Handle account form submit
    const handleAccountSettingsSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.put(`/user`, data);

            // Collection is created, redirect to collections page
            if(!response.data?.data?.errors){
                authContext.updateUser({...user, name: data.name, email: data.email, verified: response.data?.data ? response.data?.data?.verified : data.email == user?.email});
                toast("Account information has been successfully updated");
            }
        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    // Handle form submit
    const handleSubmit = async (data: typeof initialPasswordValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.post('/auth/password/change', { current_password: data.current_password, password: data.password, password_confirmation: data.password_confirmation});

            if(response.data?.message)
                toast(response.data?.message);

            // Clear form values
            helpers.resetForm();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    // Copy token to Clipboard
    function handleCopy() {

        // Ensure navigator is defined.
        // Mandatory for different browser & mobile browser types
        if(window?.navigator) 
            window.navigator.clipboard.writeText(project?.auth_token ?? '');
            setKeyCopied(true);
            setTimeout(() => setKeyCopied(false), 1000);
    }

    // Generate new token
    const regenerateToken = async () => {

        // Hide confirmation modal
        setOpen(false);

        try {
           
            // Execute request
            const response = await requestInstance.get(`/projects/${project?.id}/regenerate-api-key`);

            // Mutate projects
            projectContext.projectsUpdate();

            toast("Api key was successfully regenerated");

        } catch (err) {
          console.log(err);
        }
    };
    
    // Token string length
    const authTokenLength = project?.auth_token.length ?? 40;
    // Hide token text
    const authTokenHidden = project?.auth_token.slice(0, 2) + '.'.repeat(10) + project?.auth_token.slice(authTokenLength - 4);

    return (
        <DashboardLayout title="Profile settings" page="settings">

            {(loading ? <LoadingSpinner /> : 
            <div className="mt-0">

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2">

                    {/* start column 1 */}
                    <div className="flex flex-col">
                        <div className="mb-2 px-4 py-4 sm:px-8 sm:py-8 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg bg-gray-700">
                            <Formik
                                enableReinitialize={true}
                                initialValues={ initialValues }
                                onSubmit={ handleAccountSettingsSubmit }>
                                {(helpers) => (
                                <Form className="">

                                    <div>
                                        <h2 className="text-xl leading-6 font-medium text-white">Change account settings</h2>
                                    </div>

                                    <div className="mt-8 grid grid-cols-6 gap-6">

                                        <div className="col-span-6">
                                            <label 
                                                htmlFor="name" 
                                                className="block text-sm font-medium text-gray-200">Name</label>
                                            <Field 
                                                type="text" 
                                                name="name" 
                                                id="name"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("name", e.target.value)}
                                                className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.name ? 'border-1 border-danger' : ''}`}
                                            />
                                            <FieldErrorMessage message={ helpers.errors?.name } />
                                        </div>
                                        <div className="col-span-6">
                                            <div className="flex items-center justify-between">
                                                <label 
                                                htmlFor="email" 
                                                className="block text-sm font-medium text-gray-200">Email address</label>
                                                <div className="flex items-center justify-end">
                                                { user?.verified 
                                                ? <>
                                                    <img src={ check } className="w-4" />
                                                    <span className="text-sm text-white ml-2">Verfied</span>
                                                </> 
                                                : <>
                                                    <span className="text-white text-sm mr-2">Not verified </span>
                                                    <a href="#" className="text-secondary text-sm font-medium" onClick={ resendVerificationEmail }>Resend email</a>
                                                </>
                                                }
                                                </div>
                                            </div>
                                            <Field 
                                                type="text" 
                                                name="email" 
                                                id="email"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("email", e.target.value)}
                                                className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : ''}`} 
                                            />
                                            <FieldErrorMessage message={ helpers.errors?.email } />
                                        </div>

                                    </div>

                                <div className="mt-4 pt-4 flex justify-start">
                                    <FormButton loading={helpers.isSubmitting} label='Save changes' type='submit' />
                                </div>
                                </Form>
                                )}
                            </Formik>
                        </div>

                        <div className="min-w-full px-4 py-4 sm:px-8 sm:py-8 mb-6 overflow-hidden align-middle bg-gray-700 shadow rounded-lg order-first lg:order-2">
                            <div>
                                <h2 className="text-xl leading-6 font-medium text-white">Api key</h2>
                            </div>
                            <div className="mt-8">
                                <div className="block text-sm font-medium text-gray-200">Key</div>

                                    <div className="flex flex-wrap sm:flex-nowrap items-center mt-1 text-sm text-white sm:mt-0 sm:col-span-2">
                                        <div className="mt-1 flex items-center justify-between flex-wrap h-12 w-full border-none bg-gray-900 text-white rounded-md shadow-sm py-2 px-3 sm:text-sm">
                                            <div className="overflow-auto max-w-[70%] block">
                                                <span className={keyShown ? "hidden" : "flex-grow"}>
                                                    { authTokenHidden }
                                                </span>
                                                <span className={keyShown ? "flex-grow" : "hidden"}>
                                                    { project?.auth_token }
                                                </span>
                                            </div>
                                            <div className="flex items-center">
                                                <button
                                                type="button"
                                                className="font-medium text-white px-3 rounded-md hover:text-white focus:outline-none"
                                                onClick={ () => setKeyShown((prev) => !prev)}
                                                >
                                                    {keyShown ? <img src={eye_slash} className="w-4" /> : <img src={eye_w} className="w-4" />}
                                                </button>
                                                <button
                                                type="button"
                                                className="relative font-medium text-white px-3 rounded-md hover:text-white focus:outline-none"
                                                onClick={ handleCopy }
                                                { ...(keyCopied == false && {'data-tooltip': 'Copy to clipboard'})}
                                                { ...(keyCopied == true && {'data-tooltip': 'Copied!'})}
                                                >
                                                    <img src={copy_w} className="w-4" />
                                                </button>
                                            </div>
                                        </div>

                                        <span className="flex items-start flex-shrink-0 ml-0 sm:ml-4 mt-4 sm:mt-0 space-x-4">
                                            <a 
                                                href="#" 
                                                className="inline-flex justify-center text-sm font-medium text-secondary"
                                                onClick={() => setOpen(true)}
                                                >
                                                Regenerate key
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/* end column 1 */}

                    <div>
                        <div className="mt-0 px-4 py-4 sm:px-8 sm:py-8 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg bg-gray-700">
                            <Formik
                                initialValues={ initialPasswordValues }
                                onSubmit={ handleSubmit }>
                                {(helpers) => (
                                <Form className="">
                                    <div>
                                        <h2 className="text-xl leading-6 font-medium text-white">Change password</h2>
                                    </div>

                                    <div className="mt-8 grid grid-cols-6 gap-6">

                                        <div className="col-span-6">
                                            <label 
                                                htmlFor="current_password" 
                                                className="block text-sm font-medium text-white">Current password</label>
                                            <Field 
                                                type="password" 
                                                name="current_password" 
                                                id="current_password" 
                                                className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.current_password ? 'border-1 border-danger' : ''}`}
                                            />
                                            <FieldErrorMessage message={ helpers.errors?.current_password } />
                                        </div>

                                        <div className="col-span-6">
                                            <label 
                                                htmlFor="password" 
                                                className="block text-sm font-medium text-white">New password</label>
                                            <Field 
                                                type="password" 
                                                name="password" 
                                                id="password" 
                                                className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.password ? 'border-1 border-danger' : ''}`}
                                            />
                                            <FieldErrorMessage message={ helpers.errors?.password } />
                                        </div>
                                        
                                        <div className="col-span-6">
                                            <label 
                                                htmlFor="password_confirmation" 
                                                className="block text-sm font-medium text-white">Confirm password</label>
                                            <Field 
                                                type="password" 
                                                name="password_confirmation" 
                                                id="password_confirmation" 
                                                className="mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm" 
                                            />
                                            <FieldErrorMessage message={ helpers.errors?.password_confirmation } />
                                        </div>

                                    </div>

                                <div className="mt-4 pt-4 flex justify-start">
                                    <FormButton loading={helpers.isSubmitting} label='Submit' type='submit' />
                                </div>
                                </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    {/* end column 2 */}
                </div>
                {/* end columns */}
               
            </div>
            )}

            <ConfirmationModal open={open} onClose={() => setOpen(false)} confirmAction={regenerateToken} action="confirmKey" />

        </DashboardLayout>
    )
}