/**
 * Form Utilities
 */

 import { AxiosError } from "axios";
import { info } from "console";
 import { FormikHelpers } from "formik";
 
/**
 * Handle general form errors
 */
export function handleFormError(requestException: AxiosError<any>, helpers: FormikHelpers<any>) {

    // Errors returned in back-end response
    const errors: Record<string, string[]> = requestException?.response?.data?.errors;

    // There are no errors, so no need to map to any field
    if(!errors) return;

    // Map errors to fields if any
    Object.entries(errors).map(([fieldName, fieldErrors], index) => { 
        // Scroll to first element, containing the error
        if(index == 0) {
            const elementWithError = document.querySelector(`[name="${fieldName}"]`);

            elementWithError && elementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        helpers.setFieldError(fieldName, fieldErrors[0]);
    });    
}
 
/**
 * Get single field error
 */
export function getFieldError(requestException: AxiosError<any>, fieldName: string): string {
    
    // Errors returned in back-end response
    const errors: Record<string, string[]> = requestException?.response?.data?.errors;

    // There are no errors, so no need to map to any field
    if(!errors || !errors[fieldName]) {
        return "We've encountered an error, while trying to submit your information. Please try again.";
    }

    // Field field related errors
    return errors[fieldName][0];
}