import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { useRequestInstance } from "@utilities/RequestUtils";
import { useProject } from '@utilities/ProjectUtils';
import { handleFormError } from '@utilities/FormUtils';
import { toast } from 'react-toastify';
import { XIcon } from '@heroicons/react/outline';

interface Props {

    open: boolean,

    onClose: () => void,

    item: number,

    onUpdate: () => Promise<void>
}
 
export default function TransferFormModal({ ...props }) {

    // Cancel button reference
    const cancelButtonRef = React.useRef(null);

    // Initial values
    const initialValues = {token: props.item, to_address: ''};

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Create step state
    const [ step, setStep ] = React.useState<number>(0);

    // Transfer nft
    const handleModalSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {
           
            // Execute request
            const response = await requestInstance.post(`/api/tokens/${props.item}/transfers`, data);

            // Update tokens
            props.onUpdate();

            // Hide confirmation modal
            props.onClose();

            // Show success notification
            toast("Transfer transaction created successfully. The token will be transferred shortly.");

        } catch (exception: any) {
            handleFormError(exception, helpers);
        }
    };

    return (

        <Transition.Root show={props.open} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">

                        <Formik
                                initialValues={ initialValues }
                                enableReinitialize
                                onSubmit={ handleModalSubmit }>
                                {(helpers) => (
                            <Form className="">

                                <div className={`${step != 0 && 'hidden'}`}>
                                    <div className="flex justify-between items-center mb-3">
                                        <h3 className="text-md font-semibold text-white">Transfer</h3>

                                        <button
                                            type="button"
                                            className=""
                                            onClick={() => props.onClose(false)}
                                            ref={cancelButtonRef}
                                        ><XIcon className={`h-6 w-6 text-white`} /></button>
                                    </div>

                                    <p className="text-md text-white">Enter wallet address</p>

                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <div className="mt-8 space-y-6 w-full">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12">
                                                    <label 
                                                        htmlFor="to_address" 
                                                        className="block text-md text-white">Wallet address</label>
                                                    <Field 
                                                        type="text" 
                                                        name="to_address" 
                                                        id="to_address" 
                                                        value={ helpers.values.to_address }
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("to_address", e.target.value)}
                                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`}
                                                    />
                                                    <FieldErrorMessage message={ helpers.errors?.to_address } />
                                                </div>
                                            </div>

                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <a
                                                    href="#"
                                                    className={`w-auto inline-flex justify-center rounded-md h-[48px] leading-8 px-8 py-2 text-md font-medium text-white focus:outline-none bg-gradient`}
                                                    onClick={() => {setStep(1);}}
                                                >
                                                    Send to address
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* end first step */}

                                <div  className={`${step != 1 && 'hidden'}`}>
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-md font-semibold text-white">Confirm transfer</h3>

                                        <button
                                            type="button"
                                            className=""
                                            onClick={() => {props.onClose(false); setStep(0);}}
                                            ref={cancelButtonRef}
                                        ><XIcon className={`h-6 w-6 text-white`} /></button>
                                    </div>

                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <div className="mt-8 space-y-6 w-full">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12">
                                                    <div className="block text-md text-white mb-2">Wallet address</div>
                                                    <p className="text-white text-sm font-medium">{helpers.values.to_address}</p>
                                                </div>
                                            </div>

                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button
                                                type="submit"
                                                className={`w-auto inline-flex justify-center rounded-md h-[48px] leading-8 px-8 py-2 text-md font-medium text-white focus:outline-none bg-gradient`}
                                                disabled={helpers.isSubmitting}
                                                >
                                                    {helpers.isSubmitting ? 'Loading' : 'Send to address'}
                                                    
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </Form>
                            )}
                        </Formik>

                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}