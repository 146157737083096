import React, { useState, Fragment  } from 'react';
import { useParams } from "react-router-dom";
import { PaymentInterface } from "@contracts/PaymentContract";
import { Menu } from '@headlessui/react';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import NoResults from '@components/ui/NoResults';
import {sort_mapping, formModalTitles, statusList} from '../../../assets/data';
import { Tooltip } from 'react-tooltip';
import DatePicker from "react-datepicker";
import moment from 'moment/moment.js';

import { 
    cross,
    check_green,
    check_info,
    info
} from '../../../assets/images';
import { CalendarIcon } from '@heroicons/react/solid'

let PageSize = 25;

const filterItems = (items: string[], filters: any) => {
    return items 
    ? items.filter((payment: any) => payment.wallet_token?.customer?.email.toLowerCase().includes(filters.search.toLowerCase()) || payment.wallet_token?.customer?.name.toLowerCase().includes(filters.search.toLowerCase()) || payment.wallet_token?.collection_token?.name.toLowerCase().includes(filters.search.toLowerCase()))
	.filter((payment: any) => payment.status == filters.status || filters.status === '')
	.filter((payment: any) => payment.type == filters.type || filters.type === '')
	.filter((payment: any) => payment.method == filters.mark_only || filters.mark_only === '')
    .filter((payment: any) => (filters.paid_at_from && payment.transaction?.created_at_timestamp && new Date(payment.transaction?.created_at_timestamp * 1000) > filters.paid_at_from) || filters.paid_at_from === null)
    .filter((payment: any) => (filters.paid_at_to && payment.transaction?.created_at_timestamp && new Date(payment.transaction?.created_at_timestamp * 1000) < filters.paid_at_to) || filters.paid_at_to === null) 
    : items;
};


interface Props {

    payments: PaymentInterface,

    loading: boolean
}
 
export default function PaymentsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

   // Filters state
   const [ filters, setFilters ] = React.useState({search: '', status: '', type: '', mark_only: '', paid_at_from: null, paid_at_to: null});

    // Filter payments
    const payments = filterItems(props.payments, filters);

    // Modal settings
    const [openForm, setOpenForm] = React.useState(false);
    const [item, setItem] = React.useState(0);
    const [title, setTitle] = React.useState(formModalTitles.find(a => a.action === 'create' && a.page === 'payment')?.title);

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(payments);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <>
        
        <div className="w-full border rounded-md border-gray-600 p-2 sm:p-6 mb-6 flex justify-between items-end flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-[24%] lg:mr-2 mb-4 lg:mb-0">
                <input 
                    name="search" 
                    id="search" 
                    placeholder="Search by NFT or Recipient"
                    value={ filters.search }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilters((prev) => ({...prev, search: e.target.value}))}
                    className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent" 
                />
            </div>
            <div className="w-full lg:w-[50%] lg:mr-2 mb-4 lg:mb-0 grid grid-cols-1 sm:grid-cols-2">
                <div className="relative mr-2">
                    <label htmlFor="status" className="block text-sm text-white mb-1">Status</label>
                    <Menu>
                        <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                            {filters.status && 
                                <span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === filters.status)?.class  }`}>
                                    {filters.status}
                                </span>
                            }
                            {!filters.status && 'All'}
                        </Menu.Button>

                        <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: ''}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="text-white px-1">All</div>
                                    {filters.status == '' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'created'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'created')?.class  }`}>Created</span></div>
                                    {filters.status == 'created' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'pending'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'pending')?.class  }`}>Pending</span></div>
                                    {filters.status == 'pending' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'successful'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'successful')?.class  }`}>Successful</span></div>
                                    {filters.status == 'successful' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, status: 'reverted'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border ${ statusList.find(a => a.status === 'reverted')?.class  }`}>Reverted</span></div>
                                    {filters.status == 'reverted' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>

                </div>
                <div className="relative mr-2">
                    <label htmlFor="status" className="block text-sm text-white  mb-1">Type</label>

                    <Menu>
                        <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                            {filters.type && 
                                <>{filters.type == 'automatic' ? <span className="w-2 h-2 bg-info inline-block rounded-full"></span> :  <span className="w-2 h-2 bg-gray-400 inline-block rounded-full"></span>}</>
                            }
                            <span className="text-white ml-1">{filters.type || 'All'}</span>
                        </Menu.Button>

                        <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, type: ''}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="text-white px-1">All</div>
                                    {filters.type == '' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, type: 'manual'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className="w-2 h-2 bg-gray-400 inline-block rounded-full mr-1"></span> Manual</div>
                                    {filters.type == 'manual' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, type: 'automatic'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div><span className="w-2 h-2 bg-info inline-block rounded-full mr-1"></span> Automatic</div>
                                    {filters.type == 'automatic' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
                {/* <div className="relative">
                    <label htmlFor="status" className="block text-sm text-white mb-1">Mark only</label>
                    <Menu>
                        <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                            {filters.mark_only 
                                ?
                                    <><div className="inline-flex">{filters.mark_only == 'external'
                                    ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                    : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                                    }</div></>
                                : 'All'
                            }
                        </Menu.Button>

                        <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, mark_only: ''}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="text-white px-1">All</div>
                                    {filters.mark_only == '' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, mark_only: 'external'}))} 
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="inline-flex"><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></div>
									{filters.mark_only == 'external' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button
                                    onClick={() => setFilters((prev) => ({...prev, mark_only: 'internal'}))}
                                    className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                >
                                    <div className="inline-flex"><img src={cross} className="mr-2" /> <span className="text-danger">No</span></div>
									{filters.mark_only == 'internal' && <img src={check_info} className="w-4" /> }
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div> */}
            </div> 
            <div className="w-full lg:w-[26%]">
                <div className="flex items-end justify-start">
                    <div className="min-w-0 w-full">
                        <label className="mb-1">
                            Paid at
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                            <DatePicker 
                                selected={filters.paid_at_from} 
                                onChange={(date: any) => { if(!filters.paid_at_from && !filters.paid_at_to) {setFilters((filters) => ({...filters, paid_at_from: date}))} else if(filters.paid_at_from && !filters.paid_at_to){setFilters((filters) => ({...filters, paid_at_to: date}))} else {setFilters((filters) => ({...filters, paid_at_from: date}))} }} 
                                placeholderText={'From date'} 
                                selectsStart
                                startDate={filters.paid_at_from}
                                endDate={filters.paid_at_to}
                                maxDate={filters.paid_at_to}
                                monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                calendarStartDay={1}
                                dateFormat="dd/MM/yyyy"
                                className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                            />
                        </div>
                    </div>

                    <span className="text-white ml-2">to</span>

                    <div className="min-w-0 w-full ml-2">
                        <label className="sr-only">
                            Filter by end date
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                            <DatePicker 
                                selected={filters.paid_at_to} 
                                onChange={(date: any) => { if(!filters.paid_at_from && !filters.paid_at_to) {setFilters((filters) => ({...filters, paid_at_from: date}))} else if(filters.paid_at_from && !filters.paid_at_to){setFilters((filters) => ({...filters, paid_at_to: date}))} else {setFilters((filters) => ({...filters, paid_at_to: date}))} }}
                                selectsEnd
                                startDate={filters.paid_at_from}
                                endDate={filters.paid_at_to}
                                minDate={filters.paid_at_from}
                                monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                calendarStartDay={1}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={'To date'}
                                className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        {/* end filters */}

        {(!props.loading || (currentTableData && currentTableData.length) ?
        <>


        <div className="table-scroll overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
        <table className="min-w-full rounded-md">
            <thead>
                <tr>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                >
                    Recipient
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                >
                    NFT
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'amount' ? sortConfig.direction : undefined}`}
                    onClick={() => requestSort('amount')}
                >
                    Amount
                    <img src={sort_mapping[sortConfig?.key === 'amount' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                >
                    Status
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'transaction.created_at_timestamp' ? sortConfig.direction : undefined}`}
                    onClick={() => requestSort('transaction.created_at_timestamp')}
                >
                    Paid at
                    <img src={sort_mapping[sortConfig?.key === 'transaction.created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                </th>
                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'type' ? sortConfig.direction : undefined}`}
                    onClick={() => requestSort('type')}
                >
                    Type
                    <img src={sort_mapping[sortConfig?.key === 'type' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                </th>
                {/* <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[155px]`}
                data-tooltip="The payout was done externally, and the system only marked it as paid."
                >
                    <div 
                        className={`inline sort ${sortConfig?.key === 'method' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('method')}
                    >
                        Mark Only
                        <img src={sort_mapping[sortConfig?.key === 'method' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </div>
                    <img id="method" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                    <Tooltip 
                        anchorId="method" 
                        place="top"
                        className='tooltip-sm'
                        html="The payout was done externally, and the system only marked it as paid."
                    />
                </th> */}

                </tr>
            </thead>
            <tbody className="border-l border-b border-r border-gray-600 rounded-md">
                { currentTableData?.map((payment, paymentIndex) => 
                    <tr key={`payment-${payment.id}`}>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.wallet_token?.customer && 
                            <div className="font-medium text-white">
                                {payment.wallet_token?.customer.email}
                            </div>
                            }
                            <div className="mt-1 text-gray-400 break-all min-w-0"> { payment.wallet_token?.from_address ? payment.wallet_token?.from_address : 'Unknown' }</div>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <div id={`tokens-${paymentIndex+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                <a target="_blank" href={payment.wallet_token?.collection_token?.asset_url}>
                                    { payment.wallet_token?.collection_token?.name }: #{payment.wallet_token?.collection_token?.token_id}
                                </a> 
                                <Tooltip 
                                    anchorId={`tokens-${paymentIndex+1}`}
                                    place="top"
                                    className='tooltip-sm'
                                    html={payment.wallet_token?.collection_token?.token_id}
                                />
                            </div>
                            <div className="flex flex-col mt-1 text-gray-400">
                                { payment.wallet_token?.collection_token?.collection?.name }
                            </div>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.amount
                                ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(payment.amount) + ' ' + payment.currency.code
                                : '-'
                            }
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border self-start ${ statusList.find(a => a.status === payment.status)?.class  }`}>
                                {payment.status}
                            </span>
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            {payment.transaction?.created_at_timestamp ? moment.unix(payment.transaction?.created_at_timestamp).format("MMM D, YYYY H:s") : ''}
                        </td>
                        <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                            <div>
                                {payment.type == 'automatic' ? <span className="w-2 h-2 bg-info inline-block rounded-full"></span> :  <span className="w-2 h-2 bg-gray-400 inline-block rounded-full"></span>} {payment.type}
                            </div>
                        </td>
                        {/* <td className={`px-3 py-3.5 text-sm text-white ${ paymentIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                        <span className={`inline-flex`}>
                            { payment?.method == 'external'
                                ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                            }
                        </span>
                        </td> */}
                        
                    </tr>
                ) }
            </tbody>
        </table>
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.payments?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
        />
        </div>
        </div>
        </>
        : <NoResults item="payments" /> )}
        </>
    )
}