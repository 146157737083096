import React, { useState } from 'react'
import { config } from '@utilities/ConfigUtils';
import { useLocation  } from "react-router-dom";
import DashboardLayout from '@components/layouts/DashboardLayout';
import { WalletTokenInterface } from "@contracts/WalletTokenContract";
import { SaleListingInterface } from "@contracts/SaleListingContract";
import ActiveNftsTable from '@components/tables/nfts/ActiveNftsTable';
import ExpiredNftsTable from '@components/tables/nfts/ExpiredNftsTable';
import ListedNfts from '@components/tables/nfts/ListedNfts';
import TransferredNftsTable from '@components/tables/nfts/TransferredNftsTable';
import HistoryNftsTable from '@components/tables/nfts/HistoryNftsTable';
import { useUserOrRedirect } from '@utilities/AuthUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import LoadingSpinner from '@components/ui/LoadingSpinner';
import TableScript from '@components/tables/TableScript';
import "react-datepicker/dist/react-datepicker.css";
import {token_tabs_mapping} from './../../assets/data';


/**
 * Props
 */
export interface Props {}

/**
 * Nfts Page Component
 */
export default function NftsPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | NFTs';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Fetch wallet tokens
    const { data: tokens, error, loading, mutate: mutateTokens } = usePublicApiFetch<WalletTokenInterface[]>('/api/tokens');

    // Fetch listed for sale wallet tokens
    const { data: listed_tokens, error: errorListed, loading: loadingListed, mutate: mutateListed } = usePublicApiFetch<SaleListingInterface[]>('/api/sale-listings');

    // Filter tokens by expired status
    const list_tokens = tokens?.reduce((acc, cur) => {
        acc[token_tabs_mapping[cur['status']] || cur['status']] = [...acc[token_tabs_mapping[cur['status']] || cur['status']] || [], cur];
        return acc;
    }, {});

    // Hash params
    const { hash } = useLocation();

    // Tabs state
    const [openTab, setOpenTab] = React.useState(hash.substring(1) || 'active');

    React.useEffect(() => {
        if(hash.substring(1))
            setOpenTab(hash.substring(1));
    }, [hash]);

    return (
        <DashboardLayout title="NFTs" page="nfts">

            <div className="mt-10">

            {(loading ? <LoadingSpinner /> : 
            <>

                <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900"></h3>
                </div>
                
                <div className="min-w-full align-middle overflow-hidden min-h-[450px] shadow rounded-lg">

                    {/* tabs */}
                    <div>
                        <div className="xl:hidden">
                            <label htmlFor="tabs" className="sr-only">
                            Select a tab
                            </label>
                            <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md focus:border-secondary bg-gray-600 text-white"
                            defaultValue="Pending Payments"
                            onChange={e => setOpenTab(e.target.value)}
                            >
                                <option value="active">Active NFTs ({list_tokens?.['active']?.length || 0})</option>
                                <option value="owned">Owned NFTs ({list_tokens?.['expired']?.length || 0})</option>
                                <option value="listed">Listed/Sold NFTs ({list_tokens?.['listed']?.length || 0})</option>
                                <option value="transferred">Transferred NFTs ({list_tokens?.['transferred']?.length || 0})</option>
                                <option value="history">Transfer History</option>
                            </select>
                        </div>
                        <div className="hidden xl:block">
                            <div className="border-b border-gray-700">
                            <nav className="-mb-px flex space-x-3 xl:space-x-6" aria-label="Tabs">

                                <a
                                    key="Customers NFTs"
                                    className={`group inline-flex items-center py-4 px-1 border-b-2 text-md ${openTab === 'active' ? "border-secondary text-white" : "border-transparent text-gray-400 hover:text-gray-300"}`}
                                    onClick={e => {
                                        setOpenTab('active');
                                    }}
                                    data-toggle="tab"
                                    href="#active"
                                    role="tablist"
                                >
                                    <span className="truncate">Active NFTs ({list_tokens?.['active']?.length || 0})</span>
                                </a>

                                <a
                                    key="Owned NFTs"
                                    className={`group inline-flex break-keep items-center py-4 px-1 border-b-2 text-md ${openTab === 'owned' ? "border-secondary text-white" : "border-transparent text-gray-400 hover:text-gray-300"}`}
                                    onClick={e => {
                                        setOpenTab('owned');
                                    }}
                                    data-toggle="tab"
                                    href="#owned"
                                    role="tablist"
                                >
                                    <span className="truncate">Owned NFTs ({list_tokens?.['expired']?.length || 0})</span>
                                </a>

                                <a
                                    key="Listed/Sold NFTs"
                                    className={`group inline-flex items-center py-4 px-1 border-b-2 text-md ${openTab === 'listed' ? "border-secondary text-white" : "border-transparent text-gray-400 hover:text-gray-300"}`}
                                    onClick={e => {
                                        setOpenTab('listed');
                                    }}
                                    data-toggle="tab"
                                    href="#listed"
                                    role="tablist"
                                >
                                    <span className="truncate">Listed/Sold NFTs ({listed_tokens?.length || 0})</span>
                                </a>

                                <a
                                    key="Transferred NFTs"
                                    className={`group inline-flex items-center py-4 px-1 border-b-2 text-md ${openTab === 'transferred' ? "border-secondary text-white" : "border-transparent text-gray-400 hover:text-gray-300"}`}
                                    onClick={e => {
                                        setOpenTab('transferred');
                                    }}
                                    data-toggle="tab"
                                    href="#transferred"
                                    role="tablist"
                                >
                                    <span className="truncate">Transferred NFTs ({list_tokens?.['transferred']?.length || 0})</span>
                                </a>

                                <a
                                    key="Transfer History"
                                    className={`group inline-flex items-center py-4 px-1 border-b-2 text-md ${openTab === 'history' ? "border-secondary text-white" : "border-transparent text-gray-400 hover:text-gray-300"}`}
                                    onClick={e => {
                                        setOpenTab('history');
                                    }}
                                    data-toggle="tab"
                                    href="#history"
                                    role="tablist"
                                >
                                    <span className="truncate">Transfer History</span>
                                </a>

                            </nav>
                            </div>
                        </div>
                    </div>
                    {/* end tabs */}

                    <div className="relative flex flex-col min-w-0 break-words text-white w-full rounded bg-gray-900">

                        {/* tabs content */}

                        <div className="mt-6 flex-auto">
                            <div className="tab-content tab-space min-h-[800px]">
                                <div className={openTab === 'active' ? "block" : "hidden"} id="active">
                                    <ActiveNftsTable tokens={list_tokens?.['active']} />
                                </div>
                                {/* end first tab content */}

                                <div className={openTab === 'owned' ? "block" : "hidden"} id="owned">
                                    <ExpiredNftsTable tokens={list_tokens?.['expired']} onUpdate={() => { mutateTokens(); mutateListed(); setOpenTab('listed'); }} />
                                </div>
                                {/* end second tab content */}

                                <div className={openTab === 'listed' ? "block" : "hidden"} id="listed">
                                    <ListedNfts tokens={listed_tokens} onUpdate={() => mutateTokens()} />
                                </div>
                                {/* end third tab content */}

                                <div className={openTab === 'transferred' ? "block" : "hidden"} id="transferred">
                                    <TransferredNftsTable tokens={list_tokens?.['transferred']} />
                                </div>
                                {/* end fourth tab content */}

                                <div className={openTab === 'history' ? "block" : "hidden"} id="history">
                                    <HistoryNftsTable />
                                </div>
                                {/* end fifth tab content */}
                                
                            </div>
                        </div>
                    </div>
                </div>
                <TableScript />
                </>
                )}
            </div>
        </DashboardLayout>
    )
}