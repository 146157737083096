import React from 'react';
import { useRequestInstance } from "@utilities/RequestUtils";
import { ChartDataInterface } from "@contracts/DashboardStatisticContract";
import TimeframeSelector from '@components/charts/TimeframeSelector';
import CustomTooltip from '@components/charts/CustomTooltip';
import { useProject } from '@utilities/ProjectUtils';
import { eachDayOfInterval, format } from 'date-fns';
import moment from 'moment/moment.js';
import {
    BarChart,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from "recharts";

/**
 * Props
 */
export interface Props {

    name: string,

    containerId: string,

    data?: ChartDataInterface[],

    timeframe?: string
}
  
/**
 * Display bar chart dashboard statistics
 */
 export default function ReChart(props: Props) {

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Chart data state
    const [chartData, setChartData] = React.useState({data: props.data, timeframe: props.timeframe});

    // Fetch chart data by timeframe
    const fetchData = async (timeframe: string | undefined) => {

        try {
           
            // Execute request
            const response = await requestInstance.get(`/api/dashboard-statistics?${props.containerId.toLowerCase()}_timeframe=${timeframe}`);

            setChartData({data: response.data?.data?.[`daily${props.containerId}`], timeframe: timeframe});
  
        } catch (err) {
            //console.log(err);
        }
    };

    // Get days from timeframe
    const days = !props.timeframe?.includes('h') ? chartData.timeframe?.split('d')[0] : 1;

    // Get date range in object with values
    if(days !== '24h')
        var data = eachDayOfInterval({
        start: moment().day(`-${days}`).toDate(),
        end: moment().toDate()
        })
        .map(time => format(time, "yyyy-MM-dd"))
        .map(time => ({ time, value: (chartData.data?.find(d => d.time === time) || { value: 0 }).value }));
    else {
        var arr = [];
        for (var i = 0; i <= 24; i++)
            arr.push({time: i.toString(), value: (chartData.data?.find(d => d.time.toString() === i.toString()) || { value: 0 }).value });
        var data = arr;
    }

    // Sum value for all days/hours
    const sum = data.reduce((accumulator, object) => {
        return accumulator + object.value;
      }, 0);

    return (
        <div>
            <div className="mb-3">
                <p className="text-md font-bold text-white">{props.name}</p>
                <p className="text-sm font-medium text-gray-400 md:text-center lg:text-left hidden">{sum} Total</p>
            </div>
            <div className="relative rounded-lg bg-gray-700 p-5">

                <div className="flex justify-end align-center md:mb-3 w-full">
                    <TimeframeSelector timeframe={props.timeframe} fetchData={fetchData} />
                </div>

                <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 5,
                    }}
                    >
                    <Tooltip
                        wrapperStyle={{ outline: "none" }}
                        itemStyle={{ color: "#fff", backgroundColor: "#343e65" }}
                        contentStyle={{ color: "#FFF", backgroundColor: "#343e65", border: 'none' }}
                        cursor={{fill: '#0d1122'}}    
                        content={<CustomTooltip />}
                    />
                    <CartesianGrid strokeDasharray="4 0" opacity={0.2} stroke="#343E65" />
                    <XAxis dataKey="time" allowDecimals={false} stroke="#343E65" />
                    <YAxis stroke="#343E65" />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="value" stroke="#28C9FF" name='count' />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}