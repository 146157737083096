import React from 'react';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import { Dialog, Transition } from '@headlessui/react';
import { SaleListingStatsInterface } from "@contracts/SaleListingStatsContract";
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import LoadingSpinner from '@components/ui/LoadingSpinner';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { useProject } from '@utilities/ProjectUtils';
import { toast } from 'react-toastify';
import { XIcon } from '@heroicons/react/outline';
import moment from 'moment/moment.js';

import { 
    edit
} from './../../assets/images';

interface Props {

    open: boolean,

    onClose: () => void,

    item: number,

    onUpdate: () => Promise<void>
}
 
export default function ListForSaleForm({ ...props }) {

    // Fetch collection stats
    const { data: stats, error: statError, loading: statLoading } = usePublicApiFetch<SaleListingStatsInterface>(props.item ? `/api/sale-listings/${props.item}/create` : null);

    // Cancel button reference
    const cancelButtonRef = React.useRef(null);

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Initial values
    const initialValues = { 
        collection_stat_id: stats?.collection_stat_id,
        wallet_token_id: props.item,
        floor_price_currency: stats?.floor_price_currency,
        market_id: stats?.token?.collection_token?.collection?.market?.id,
        amount: stats?.suggested_listing_price || 0,
    };

    // Create step state
    const [ step, setStep ] = React.useState<number>(0);

    // Create field state
    const [ fieldEnabled, setFieldEnabled ] = React.useState<boolean>(false);

    // Payment amount field
    const [listingAmount, setListingAmount] = React.useState(stats?.suggested_listing_price);

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.post(`/api/sale-listings`, data);

            toast("The NFT will be listed within few minutes");

            props.onUpdate();
            props.onClose();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
            props.onClose();
        }
    }

    return (

        <Transition.Root show={props.open} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">

                {(statLoading ? <LoadingSpinner /> :
                <Formik
                    enableReinitialize
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="w-full mx-auto">

                        <div className={`${step != 0 && 'hidden'}`}>

                            <div className="flex justify-between items-center mb-3">
                                <h3 className="text-md font-semibold text-white">List for sale</h3>

                                <button
                                    type="button"
                                    className=""
                                    onClick={() => props.onClose(false)}
                                    ref={cancelButtonRef}
                                ><XIcon className={`h-6 w-6 text-white`} /></button>
                            </div>

                            <ul role="list" className="divide-y divide-gray-600">

                            <li>
                                <div className="flex items-center py-6">
                                    <div className="min-w-0 flex-1 flex items-start justify-between">
                                        <div className="truncate">
                                            <div className="flex text-md">
                                            <p className="truncate text-white">Collection FP</p>
                                            </div>
                                            <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                                <p></p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="truncate">
                                            <div className="flex text-md justify-end">
                                            <p className="truncate text-white">{stats?.floor_price || 'Not Available'} {stats?.floor_price && stats?.floor_price_currency}</p>
                                            </div>
                                            <div className="mt-2 flex content-end justify-end text-gray-300 text-sm">
                                                <p>{stats?.floor_price_timestamp ? `Parsed at ${moment.unix(stats?.floor_price_timestamp ).format("H:m D/MM/YYYY")}` : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="flex items-center py-6">
                                    <div className="min-w-0 flex-1 flex items-center justify-between">
                                        <div className="">
                                            <div className="flex text-md">
                                            <p className="text-white">Listing Price</p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex text-md justify-end items-center">
                                            <p className="font-medium text-white mx-2">{stats?.floor_price_currency}</p>
                                            <div 
                                                    className="relative" 
                                                    { ...(fieldEnabled == false && {'data-tooltip': stats?.suggested_listing_formula})}
                                                >
                                                <Field 
                                                    type="number" 
                                                    name="amount" 
                                                    id="amount" 
                                                    { ...(fieldEnabled == false && {'disabled': 'disabled!'})}
                                                    // { ...(fieldEnabled == true && {'autoFocus': 'true'})}
                                                    value={ helpers.values.amount }
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { helpers.setFieldValue("amount",  isNaN(parseFloat(e.target.value)) ? undefined : parseFloat(e.target.value)); setListingAmount(isNaN(parseFloat(e.target.value)) ? undefined : parseFloat(e.target.value)) }}
                                                    className={`block h-12 max-w-[110px] sm:max-w-[150px] text-white placeholder-gray-200 shadow-sm py-2 px-1 sm:px-3 rounded-l-md focus:outline-none sm:text-sm border-transparent ${fieldEnabled ? 'bg-gray-900' : 'bg-gray-700'}`}
                                                />
                                                </div>
                                                <div
                                                    className={`flex items-center justify-center bg-gray-900 h-12 p-2 sm:p-3 cursor-pointer ${fieldEnabled ? 'rounded-r-md' : 'rounded-md'}`}
                                                    onClick={ () => setFieldEnabled((prev) => !prev)}    
                                                >
                                                    <img src={edit} className="block cursor-pointer mx-1 text-primary w-4 h-4" />
                                                    <span className="font-medium text-primary text-center text-sm block w-[45px]">Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <a
                                    href="#"
                                    className={`w-auto inline-flex justify-center rounded-md h-[48px] leading-8 px-8 py-2 text-md font-medium text-white focus:outline-none bg-gradient`}
                                    onClick={() => {setStep(1);}}
                                >
                                    List
                                </a>
                            </div>
                        </div>
                        {/* end first step */}

                        <div  className={`${step != 1 && 'hidden'}`}>

                            <div className="flex justify-between items-center">
                                <h3 className="text-md font-semibold text-white">Confirm listing</h3>

                                <button
                                    type="button"
                                    className=""
                                    onClick={() => {props.onClose(false); setStep(0);}}
                                    ref={cancelButtonRef}
                                ><XIcon className={`h-6 w-6 text-white`} /></button>
                            </div>

                            <ul role="list" className="divide-y divide-gray-600">
                                <li>
                                    <div className="flex items-center py-6">
                                        <div className="min-w-0 flex-1 flex items-start justify-between">
                                            <div className="truncate">
                                                <div className="flex text-md">
                                                    <p className="truncate text-white">Collection FP</p>
                                                </div>
                                            </div>
                                            <div className="truncate">
                                                <div className="flex text-md justify-end">
                                                <p className="truncate text-white">{stats?.suggested_listing_price || 'Not Available'} {stats?.suggested_listing_price && stats?.floor_price_currency}</p>
                                                </div>
                                                <div className="mt-2 flex content-end justify-end">
                                                    <span className="text-sm text-right text-gray-300">{stats?.floor_price_timestamp ? `Parsed at ${moment.unix(stats?.floor_price_timestamp).format("H:s D/MM/YYYY")}` : ''}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex items-center py-6">
                                        <div className="min-w-0 flex-1 flex items-center justify-between">
                                            <div className="truncate">
                                                <div className="flex text-md">
                                                <p className="truncate text-white">Listing Price</p>
                                                </div>
                                            </div>
                                            <div className="truncate">
                                                <div className="flex text-md justify-end items-center">
                                                <p className="truncate font-medium text-white mr-1">{stats?.floor_price_currency}</p>
                                                <div className="relative text-white">
                                                    { helpers.values.amount }
                                                    </div>
                                                </div>
                                                <FieldErrorMessage message={ helpers.errors?.amount } />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div className="flex justify-end pb-4">
                                <a
                                    href="#"
                                    className={` relative justify-center h-[48px] leading-8 px-4 py-2 mr-4 text-sm font-medium text-white border-transparent rounded-md group focus:outline-none h-12 leading-9`}
                                    onClick={() => {setStep(0);}}
                                >
                                    Back
                                </a>
                                <FormButton loading={helpers.isSubmitting} label='List for sale' type='submit' />
                            </div>

                        </div>

                    </Form>
                    )}
                </Formik>
                )}
                </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}