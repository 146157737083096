import React from 'react';
import { useMemo } from 'react';

export const DOTS = '...';

/**
 * Props
 */
interface Props {

    onPageChange: () => void,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number,
    className: string
    
}

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export default function usePagination({ ...props }) {

  const paginationRange = useMemo(() => {

    // Get total page count
    const totalPageCount = Math.ceil(props.totalCount / props.pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = (props.siblingCount || 1) + 5;

    // show pagination if the number of pages is less than the page numbers
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // get pagination sibling links
    const leftSiblingIndex = Math.max(props.currentPage - (props.siblingCount || 1), 1);
    const rightSiblingIndex = Math.min(
        props.currentPage + (props.siblingCount || 1),
      totalPageCount
    );

    // show dots if there is position left/right
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    // define first and last pages
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // show if need of right dots
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * (props.siblingCount || 1);
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    // show if need of left dots
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * (props.siblingCount || 1);
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    // show if need of dots
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [props.totalCount, props.pageSize, props.siblingCount, props.currentPage]);

  return paginationRange;
}