import React, { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from '@utilities/AuthUtils';
import './../../assets/css/Navbar.css';
import { useRequestInstance } from '@utilities/RequestUtils';
import { toast } from 'react-toastify';
import { Dialog, Menu, Transition } from '@headlessui/react'
import {titleTooltips, navigation, bottom_links} from './../../assets/data';
import { Tooltip } from 'react-tooltip'
import "react-tooltip/dist/react-tooltip.css";

import { 
    logo, 
    bell,
    prev, 
    next, 
    logo_mobile,
    info,

} from './../../assets/images';

import {
  MenuAlt2Icon,
  XIcon,
  ChevronDownIcon,
  ChevronLeftIcon
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

const userNavigation = [
  { name: 'Sign out', href: '#' },
]


/**
 * Props
 */
 export interface Props {

    page?: string,

    title?: string
}


/**
 * Navbar Component
 */
export default function Navbar(props: Props) {

    // Sidebar state
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // Use auth context
    const authContext = useAuthContext();

    // Current page index
    const currentIndex = navigation.findIndex(x => x.current == props.page);
    const currentIndexBottom = bottom_links.findIndex(x => x.current == props.page);

    // Use request instance
    const requestInstance = useRequestInstance(authContext?.user?.token);

    // Hash params
    const { hash } = useLocation();

    // Resend verification email
    const resendVerificationEmail = async () => {

        try {
           
            // Execute request
            const response = await requestInstance.get('/auth/email/resend');

            if(response.data?.verified)
                authContext.updateUser({...authContext.user, verified: response.data?.verified});

            if(response.data?.message)
                toast(response.data?.message);

        } catch (err) {
            toast("Unable to send verification email");
        }
    };

    return (
        <>
            
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-700 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-40">
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                            type="button"
                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                        >
                            <span className="sr-only">Close sidebar</span>
                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                        <a href="/"><img src={logo} alt="nft pay" className="w-[175px] h-[41px]" /></a>
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                        <nav className="px-2 space-y-1">
                        {navigation.map((item) => (
                            <a
                            key={item.name}
                            href={item.href}
                            className={`group flex items-center px-2 py-2 text-base rounded-md ${ item.current ? 'bg-gray-900 font-medium text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white' }`}
                            >
                                <img src={item.icon} className="w-6 mr-4 hover:text-white" />
                            {item.name}
                            </a>
                        ))}
                        {bottom_links.map((item) => (
                            <a
                            key={item.name}
                            href={item.href}
                            className={`group flex items-center px-2 py-2 text-base rounded-md ${ item.current ? 'bg-gray-900 font-medium text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white' }`}
                            >
                                <img src={item.icon} className="w-6 mr-4 hover:text-white" />
                            {item.name}
                            </a>
                        ))}
                        </nav>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
                </div>
            </Dialog>
            </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className={`hidden md:flex md:flex-col md:fixed md:inset-y-0 transition-all duration-300 ${authContext?.menuCollapsed === 'true' ? 'md:w-64' : 'md:w-24'}`}>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-gray-700">
                <div className="flex items-center justify-between flex-shrink-0 px-4 py-5">
                    <a href="/" className="text-white text-2xl font-normal text-center block"><img src={authContext?.menuCollapsed === 'true' ? logo : logo_mobile} alt="nft pay" className={ authContext?.menuCollapsed ? 'w-[175px] h-[41px] transition-all duration-300' : 'w-[38px] transition-all duration-300' } /></a>
                    <img src={authContext?.menuCollapsed === 'true' ? prev : next} className="w-4 cursor-pointer transition-all duration-300" onClick={() => authContext?.updateMenu(authContext?.menuCollapsed === 'true' ? 'false' : 'true') } />
                </div>
                <div className="flex-1 flex flex-col overflow-y-auto overflow-x-hidden no-scrollbar">
                <nav className="flex-1 pl-2 pt-0 pt-4 pb-8 main-nav">
                    <div className="bg-gray-900">
                        <div className={currentIndex == 0 ? 'bg-gray-700 px-2 py-3 rounded-br-3xl' : 'bg-gray-700 px-2 py-3'}></div>
                    {navigation.map((item, index) => (
                    <div key={item.name} className={item.current == props.page ? 'bg-gray-700' : ''}>
                    <a
                        href={item.href}
                        data-current-index={currentIndex}
                        data-index={index}
                        className={`group flex items-center px-4 py-3 text-md transition-all duration-500 ${ item.current == props.page ? `relative active bg-gray-900 text-white font-medium ${item.sub && authContext?.menuCollapsed === 'true' ? 'rounded-tl-3xl' : 'rounded-l-3xl'}` : 'bg-gray-700 text-gray-400 hover:text-white' } ${ index == currentIndex-1 ? 'rounded-br-3xl' : '' } ${ index == currentIndex+1 && currentIndex != -1 ? 'rounded-tr-3xl' : '' }`}
                    >
                        <img src={item.current == props.page ? item.icon_active : item.icon} className="w-6 mr-4 hover:text-white" />
                        
                        {authContext?.menuCollapsed === 'true' ? item.name : ''}
                    </a>
                    {item.sub && 
                        <div className={`bg-gray-900 flex rounded-bl-3xl transition-all duration-300 ${item.current != props.page && 'hidden'} ${authContext?.menuCollapsed === 'true' ? 'translate-x-0 pb-4' : 'translate-x-64 pb-0'}`}>
                            <div className="border-l border-gray-400 ml-4"></div>
                            <div className={`transition-all duration-500 ${authContext?.menuCollapsed === 'true' ? 'h-auto' : 'h-0'}`}>
                            {item.sub.map((s, indx) => (
                                <a 
                                    key={s.item}
                                    href={s.link} 
                                    className={`block hover:text-white pl-9 text-sm transition-all duration-300 ${indx > 0 && ' pt-3'} ${hash === s.link || (hash === '' && indx === 0) ? 'text-white' : 'text-gray-400'}`}
                                >{s.item}</a>
                            ))}
                            </div>
                        </div>
                    }
                    </div>
                    ))}
                    <div className={currentIndex == navigation.length-1 ? 'bg-gray-700 px-2 py-3 rounded-tr-3xl' : 'bg-gray-700 px-2 py-3'}></div>
                    </div>
                </nav>
                </div>
                <div className="border-t border-gray-700 pl-2 py-4 main-nav">
                    <div className="bg-gray-900">
                        <div className={currentIndexBottom == 0 ? 'bg-gray-700 px-2 py-3 rounded-br-3xl' : 'bg-gray-700 px-2 py-3'}></div>
                        {bottom_links.map((item, index) => (
                        <div key={item.name} className={item.current == props.page ? 'bg-gray-700' : ''}>
                        <a
                            href={item.href}
                            className={`group flex items-center px-4 py-3 text-md ${ item.current == props.page ? 'relative active rounded-l-3xl bg-gray-900 text-white font-medium' : 'text-gray-400 bg-gray-700 hover:text-white' } ${ index == currentIndexBottom-1 ? 'rounded-br-3xl' : '' } ${ index == currentIndexBottom+1 && currentIndexBottom != -1 ? 'rounded-tr-3xl' : '' }`}
                            { ...(item.target == '_blank' && {'target': '_blank'})} 
                            onClick={ e => ( item.name == 'Sign out' ? authContext.signOut() : undefined  ) }
                        >
                            <img src={item.current == props.page ? item.icon_active : item.icon} className="w-6 mr-4 hover:text-white" />
                            {authContext?.menuCollapsed === 'true' ? item.name : ''}
                        </a>
                        </div>
                        ))}
                        <div className={currentIndexBottom == bottom_links.length-1 ? 'bg-gray-700 px-2 py-6 rounded-tr-3xl' : 'bg-gray-700 px-2 py-3'}></div>
                    </div>
                </div>
            </div>
            </div>
            <div className={`flex flex-col transition-all duration-300 ${authContext?.menuCollapsed === 'true' ? 'md:pl-64' : 'md:pl-24'}`}>
            <div className="sticky bg-gray-900 top-0 z-10 flex h-20 rounded-tl-[50px] w-full">
                <button
                type="button"
                className="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
                >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 px-0 sm:px-6 md:px-16 flex justify-between w-full">

                    <h1 className="text-xl sm:text-2xl font-bold text-white flex justify-start items-center">
                        { props.page != 'transactions' ? props.title : <> {<a href="/wallets"><ChevronLeftIcon className="w-4 h-4 mr-2" /> </a>} {props.title}</> }
                        {titleTooltips.find(a => a.action === props.page)?.tooltip 
                        ?
                        <>
                            <div className="ml-4 cursor-pointer">
                                <img id="titleTooltip" src={info} className="w-6 h-6" />
                            </div>
                            <Tooltip 
                                anchorId="titleTooltip" 
                                place="right"
                                className='tooltip-title'
                                html={ titleTooltips.find(a => a.action === props.page)?.tooltip }
                            />
                        </>
                        : ''
                        }
                    </h1>

                    <div className="flex items-center sm:ml-8">

                        {/* Notifications */}
                        <div className="ml-4 sm:ml-6 relative">
                        <Menu>
                            <Menu.Button className="relative text-white whitespace-nowrap py-6 px-1 font-medium text-sm">
                                <span className="sr-only">View notifications</span>
                                <div className="bg-gray-700 rounded-md w-8 h-8 flex items-center justify-center">
                                    <img src={bell} alt="nft pay" className="w-4" />
                                </div>
                                { !authContext?.user?.verified &&
                                <span className="absolute w-2 h-2 rounded-lg bg-[#28C9FF] text-white text-xs top-7 right-3"></span> }
                            </Menu.Button>
                            { !authContext?.user?.verified &&
                            <Menu.Items className="header-notifications origin-top-right absolute right-0 mt-2 rounded-md shadow-lg flex w-[100vw-100px] sm:w-[100vw-260px] md:w-[500px] flex-col p-3 border-secondary border-transparent bg-gray-700 drop-shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                                <Menu.Item>
                                    <div className="flex flex-col md:flex-row justify-between items-center">
                                    <div>
                                        <h2 className="text-md font-medium text-white">Email not verified</h2>
                                        <p className="mt-1 text-sm text-white">
                                            Resend verification email to confirm your account
                                        </p>
                                    </div>
                                    <a
                                        href="#"
                                        type="button" 
                                        className="relative justify-center w-full md:w-36 mt-3 md:mt-0 px-4 py-2 text-sm font-medium text-white border-transparent rounded-md group focus:outline-none inline-flex bg-gradient hover:bg-sky-80"
                                        onClick={ (e) => { e.preventDefault(); resendVerificationEmail()} }
                                        >
                                        Resend email
                                    </a>
                                </div>

                                </Menu.Item>
                            </Menu.Items>
                            }
                        </Menu>
                        </div>

                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-2 sm:ml-8 mr-2 sm:mr-0 relative">
                        <div>
                            <Menu.Button className="text-white whitespace-nowrap py-6 px-1 font-medium text-sm">
                            <span className="sr-only">Open user menu</span>
                            <div className="flex justify-start items-center">
                                <div className="w-8 h-8 bg-secondary rounded-md flex items-center justify-center mr-0 sm:mr-4">{ authContext?.user?.name && authContext?.user?.name.charAt(0) } </div>
                                <div className="group inline-flex items-center">
                                    <span className="hidden sm:block">{ authContext?.user?.name }</span>
                                    <ChevronDownIcon
                                        className='text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500'
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                {({ active }) => (
                                    <a
                                    href={item.href}
                                    className={`block px-4 py-2 text-sm text-white ${ active ? 'bg-gray-800' : '' }`}
                                    onClick={ e => ( item.name == 'Sign out' ? authContext.signOut() : undefined  ) }
                                    >
                                    {item.name}
                                    </a>
                                )}
                                </Menu.Item>
                            ))}
                            </Menu.Items>
                        </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
            </div>
        
        </>
    )
}