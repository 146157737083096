import React from 'react'
import { usePublicApiFetch } from "@utilities/RequestUtils";
import { PendingPaymentInterface } from "@contracts/PendingPaymentContract";
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import LoadingSpinner from '@components/ui/LoadingSpinner';
import { Formik, Form, FormikHelpers, Field, getIn } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { useProject } from '@utilities/ProjectUtils';
import moment from 'moment/moment.js';

import { 
    edit
} from './../../assets/images';

interface Props {

    onClose: () => void,

    item: number,

    OnUpdate: (title: string | undefined) => Promise<void>
}
 
export default function PaymentCreateForm({ ...props }) {

    // Fetch collection stats
    const { data: stats, error: statError, loading: statLoading } = usePublicApiFetch<PendingPaymentInterface>(props.item ? `/api/pending-payments/${props.item}` : null);

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Create step state
    const [ step, setStep ] = React.useState<number>(0);

    // Create field state
    const [ fieldEnabled, setFieldEnabled ] = React.useState<boolean>(false);

    // Payment amount field
    const [paymentAmount, setPaymentAmount] = React.useState(stats?.suggested_payment_price);

    // Set payment amount
    React.useEffect(() => {
        if(!paymentAmount)
            setPaymentAmount(stats?.suggested_payment_price || 0);
      }, [stats]);

    // Initial values
    const initialValues = { 
        collection_stat_id: stats?.collection_stat_id,
        wallet_token_id: props.item,
        floor_price_currency: stats?.floor_price_currency,
        amount: stats?.suggested_payment_price || 0,
        // paid: false  
    };

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.post(`/api/payments`, data);

            // Close the modal
            props.onClose();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    return (

        <div className="mt-8">
            <div className="min-w-full overflow-hidden align-middle">

                {(statLoading ? <LoadingSpinner /> :
                <Formik
                    enableReinitialize
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="w-full mx-auto">
                        
                        <div className={`${step != 0 && 'hidden'}`}>
                        <ul role="list" className="divide-y divide-gray-600">

                            <li>
                                <div className="flex items-center py-6">
                                    <div className="min-w-0 flex-1 flex items-start justify-between">
                                        <div className="truncate">
                                            <div className="flex text-md">
                                            <p className="truncate text-white">Collection FP</p>
                                            </div>
                                            <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                                <p></p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="truncate">
                                            <div className="flex text-md justify-end">
                                            <p className="truncate text-white">{stats?.floor_price || 'Not Available'} {stats?.floor_price && stats?.floor_price_currency}</p>
                                            </div>
                                            <div className="mt-2 flex content-end justify-end text-gray-300 text-sm">
                                                <p>{stats?.floor_price_timestamp ? `Parsed at ${moment.unix(stats?.floor_price_timestamp ).format("H:m D/MM/YYYY")}` : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="flex items-center py-6">
                                    <div className="min-w-0 flex-1 flex items-center justify-between">
                                        <div className="">
                                            <div className="flex text-md">
                                            <p className="text-white">Payment Price</p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex text-md justify-end items-center">
                                            <p className="font-medium text-white mx-2">{stats?.floor_price_currency}</p>
                                            <div 
                                                    className="relative" 
                                                    { ...(fieldEnabled == false && {'data-tooltip': stats?.suggested_payment_formula})}
                                                >
                                                <Field 
                                                    type="number" 
                                                    name="amount" 
                                                    id="amount" 
                                                    { ...(fieldEnabled == false && {'disabled': 'disabled!'})}
                                                    // { ...(fieldEnabled == true && {'autoFocus': 'true'})}
                                                    value={ helpers.values.amount }
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { helpers.setFieldValue("amount",  isNaN(parseFloat(e.target.value)) ? undefined : parseFloat(e.target.value)); setPaymentAmount(isNaN(parseFloat(e.target.value)) ? undefined : parseFloat(e.target.value)) }}
                                                    className={`block h-12 max-w-[110px] sm:max-w-[150px] text-white placeholder-gray-200 shadow-sm py-2 px-1 sm:px-3 rounded-l-md focus:outline-none sm:text-sm border-transparent ${fieldEnabled ? 'bg-gray-900' : 'bg-gray-700'}`}
                                                />
                                                </div>
                                                <div
                                                    className={`flex items-center justify-center bg-gray-900 h-12 p-2 sm:p-3 cursor-pointer ${fieldEnabled ? 'rounded-r-md' : 'rounded-md'}`}
                                                    onClick={ () => setFieldEnabled((prev) => !prev)}    
                                                >
                                                    <img src={edit} className="block cursor-pointer mx-1 text-primary w-4 h-4" />
                                                    <span className="font-medium text-primary text-center text-sm block w-[45px]">Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            {/* <li>
                                <div className="flex items-center py-6">
                                    <input
                                        id='paid'
                                        name='paid'
                                        type="checkbox"
                                        checked={helpers.values.paid}
                                        value={ helpers.values.paid.toString() }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("paid", e.target.checked ? 1 : 0)}
                                        className="focus:ring-indigo-500 h-4 w-4 bg-gray-600 text-indigo-600 border-gray-300 rounded ml-1"
                                    />
                                    <label htmlFor='paid' className="ml-2 flex text-white select-none items-center">
                                        Only mark as paid
                                    </label>
                                    <img id="paid11" src={info} className="w-4 h-4 inline ml-2 cursor-pointer" />
                                    <Tooltip 
                                        anchorId="paid11" 
                                        place="top"
                                        className='tooltip-sm'
                                        html="The payout will be made externally, and the system will only marked it as paid."
                                    />
                                </div>
                            </li> */}

                        </ul>

                        <div className="flex justify-end pb-4">
                            <a
                                href="#"
                                className={` relative justify-center h-[48px] leading-8 px-4 py-2 text-sm font-medium text-white border-transparent rounded-md group focus:outline-none  ${ props?.type === 'auth' ? 'flex w-full bg-gradient hover:bg-indigo-700 h-12 leading-9' : 'inline-flex bg-gradient hover:bg-sky-800'} ${props?.loading && props?.type != 'auth' ? 'pl-10' : ''} ${props?.loading ? 'bg-gray-600' : ''}`}
                                onClick={() => {setStep(1); props.onUpdate('Review payment')}}
                            >
                                Next
                            </a>
                        </div>

                    </div>
                    {/* end first step */}

                    <div  className={`${step != 1 && 'hidden'}`}>

                    <ul role="list" className="divide-y divide-gray-600">

                        <li>
                            <div className="flex items-center py-6">
                                <div className="min-w-0 flex-1 flex items-start justify-between">
                                    <div className="truncate">
                                        <div className="flex text-md">
                                        <p className="truncate text-white">Collection FP</p>
                                        </div>
                                        <div className="mt-2 flex">
                                        <div className="flex items-center text-sm text-gray-500">
                                            <p></p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="truncate">
                                        <div className="flex text-md justify-end">
                                        <p className="truncate text-white">{stats?.floor_price || 'Not Available'} {stats?.floor_price && stats?.floor_price_currency}</p>
                                        </div>
                                        <div className="mt-2 flex content-end justify-end">
                                            <span className="text-sm text-right text-gray-300">{stats?.floor_price_timestamp ? `Parsed at ${moment.unix(stats?.floor_price_timestamp).format("H:s D/MM/YYYY")}` : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="flex items-center py-6">
                                <div className="min-w-0 flex-1 flex items-center justify-between">
                                    <div className="truncate">
                                        <div className="flex text-md">
                                        <p className="truncate text-white">Payment Price</p>
                                        </div>
                                    </div>
                                    <div className="truncate">
                                        <div className="flex text-md justify-end items-center">
                                        <p className="truncate font-medium text-white mr-1">{stats?.floor_price_currency}</p>
                                        <div className="relative text-white">
                                            { helpers.values.amount }
                                            </div>
                                        </div>
                                        <FieldErrorMessage message={ helpers.errors?.amount } />
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="flex items-center py-6">
                                <div className="min-w-0 flex-1 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="grow whitespace-nowrap">
                                        <div className="flex text-md">
                                        <p className=" text-white mr-2">To address</p>
                                        </div>
                                    </div>
                                    <p className="text-white break-all text-sm mr-1">{stats?.token?.from_address}</p>
                                </div>
                            </div>
                        </li>

                        </ul>

                        <div className="flex justify-end pb-4">
                            <a
                                href="#"
                                className={` relative justify-center h-[48px] leading-8 px-4 py-2 mr-4 text-sm font-medium text-white border-transparent rounded-md group focus:outline-none h-12 leading-9`}
                                onClick={() => {setStep(0); props.onUpdate('Create payment')}}
                            >
                                Back
                            </a>
                            <FormButton loading={helpers.isSubmitting} label='Send payment' type='submit' />
                        </div>
                    </div>
                    {/* end second step */}

                    </Form>
                    )}
                </Formik>
                )}
                </div>
            </div>
    )
}