/**
 * Web routes
 */
import { createRouteStack } from "@utilities/RouteUtils";

import LoginPage from "@pages/auth/LoginPage";
import RegisterPage from "@pages/auth/RegisterPage";
import ForgotPasswordPage from "@pages/auth/ForgotPasswordPage";
import PasswordResetPage from "@pages/auth/PasswordResetPage";
import DashboardPage from "@pages/DashboardPage";
import SettingsPage from "@pages/SettingsPage";
import ProfilePage from "@pages/ProfilePage";
import WalletsPage from "@pages/WalletsPage";
import CollectionsPage from "@pages/collections/CollectionsPage";
import NftsPage from "@pages/nfts/NftsPage";
import CustomersPage from "@pages/customers/CustomersPage";
import TransactionsPage from "@pages/transactions/TransactionsPage";
import WalletTransactionsView from "@pages/transactions/TransactionsPage";
import PaymentsPage from "@pages/payments/PaymentsPage";
import EmailVerifySuccessPage from "@pages/auth/EmailVerifySuccessPage";
import EmailVerifyInvalidPage from "@pages/auth/EmailVerifyInvalidPage";
import RegistrationSuccessPage from "@pages/auth/RegistrationSuccessPage";
import ApiDocsPage from "@pages/ApidocsPage";

/**
 * Navigation params typed stack
 */
export type StackParamList = {

    Login: undefined,

    Register: undefined,

    ForgotPassword: undefined,

    PasswordReset: undefined,
    
    Dashboard: undefined,

    Settings: undefined,

    Profile: undefined,
    
    Wallets: undefined,

    Collections: undefined,

    Nfts: undefined,

    Customers: undefined,

    Transactions: undefined,

    WalletTransactionsView: undefined,

    Payments: undefined,

    EmailVerifySuccess: undefined,

    EmailVerifyInvalid: undefined,

    RegistrationSuccess: undefined,

    Apidocs: undefined,

}

export default createRouteStack<StackParamList>([
    
    /**
     * Guest routes
     */
    { name: 'Login', path: '/auth/login', component: LoginPage },
    { name: 'Register', path: '/auth/register', component: RegisterPage },
    { name: 'ForgotPassword', path: '/auth/password/forgot', component: ForgotPasswordPage },
    { name: 'PasswordReset', path: '/auth/password/reset/:token', component: PasswordResetPage },
    { name: 'EmailVerifySuccess', path: '/email/verify/success', component: EmailVerifySuccessPage },
    { name: 'EmailVerifyInvalid', path: '/email/verify/invalid', component: EmailVerifyInvalidPage },
    { name: 'RegistrationSuccess', path: '/registration/success', component: RegistrationSuccessPage },

    /**
     * User routes
     */
    { name: 'Dashboard', path: '/', component: DashboardPage },
    { name: 'Settings', path: '/settings', component: SettingsPage },
    { name: 'Profile', path: '/profile', component: ProfilePage },
    { name: 'Wallets', path: '/wallets', component: WalletsPage },

    { name: 'Collections', path: '/collections', component: CollectionsPage },

    { name: 'Nfts', path: '/nfts', component: NftsPage },

    { name: 'Customers', path: '/customers', component: CustomersPage },

    { name: 'Transactions', path: '/transactions', component: TransactionsPage },
    { name: 'WalletTransactionsView', path: '/wallets/transactions/:id', component: WalletTransactionsView },

    { name: 'Payments', path: '/payments', component: PaymentsPage },

    { name: 'Apidocs', path: '/api-docs', component: ApiDocsPage },

]);