import React from 'react';
import { useParams } from "react-router-dom";
import { WalletTokenInterface } from "@contracts/WalletTokenContract";
import ListForSaleForm from '@components/forms/ListForSaleForm';
import useSortableData from '@utilities/SortingUtils';
import {sort_mapping, nftStatusList} from '../../../assets/data';
import Pagination from '@components/ui/Pagination';
import NoResults from '@components/ui/NoResults';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment.js';

import { 
    eye,
    info,
    shop
} from '../../../assets/images';

let PageSize = 7;

interface Props {

    tokens: WalletTokenInterface,

    title: String
}
 
export default function ListedNftsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(props.tokens);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Modal settings
    const [item, setItem] = React.useState(0);
    const [openForm, setOpenForm] = React.useState(false);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <>
            <h3 className="text-white">{props.title}</h3>
            {currentTableData.length > 0
            ?
            <>
            <table className="min-w-full rounded-md my-8">
                <thead>
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                        
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Token / Collection
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Market
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'status' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('status')}
                    >
                        Status
                        <img src={sort_mapping[sortConfig?.key === 'status' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Amount 
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer`}
                    >
                        Floor price
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[150px]`} data-tooltip="Dates and Time are expressed in UTC">
                        <div 
                            className={`inline sort ${sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : undefined}`}
                            onClick={() => requestSort('created_at_timestamp')}
                        >
                            Listed at
                            <img src={sort_mapping[sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                        </div>
                        <img id="created_at_timestamp" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                        <Tooltip 
                            anchorSelect="#created_at_timestamp" 
                            place="top"
                            className='tooltip-sm'
                            html="Dates and Time are expressed in UTC"
                        />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                    </tr>
                </thead>
                <tbody  className="border-l border-b border-r border-gray-600 rounded-md">
                    {currentTableData.map((token: any, tokenIndex: any) => (
                        <tr key={token.id}>
                            <td className={`px-3 py-3.5 text-sm text-primary ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.status == 'expired' &&
                                    <a 
                                        href="#" 
                                        className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                        onClick={() => { setOpenForm(true); setItem(token.wallet_token.id) }}
                                    >
                                        <img src={shop} alt="shop" className="w-4 h-4 mr-2" />
                                        Relist
                                    </a>
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div className="aspect-w-1 aspect-h-1 w-16 overflow-hidden rounded-lg cursor-pointer bg-gray-200 block">
                                    <img
                                    src={token.wallet_token.collection_token?.thumbnail_url}
                                    alt={token.wallet_token.collection_token?.name}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div id={`token-${tokenIndex+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                    { token.wallet_token.collection_token?.name }: #{ token.wallet_token.collection_token?.token_id }
                                </div>
                                <div className="flex flex-col mt-1 text-gray-400">
                                    { token.wallet_token.collection_token?.collection?.name }
                                </div>
                                <Tooltip 
                                    anchorId={`token-${tokenIndex+1}`}
                                    place="top"
                                    className='tooltip-sm'
                                    html={ token.wallet_token.collection_token?.token_id }
                                />
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.market.name}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <span className={`inline-flex rounded-md px-4 py-1 text-xs font-semibold border self-start ${ nftStatusList.find(a => a.status === token.status)?.class  }`}>
                                    {token.status} 
                                </span>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.amount
                                    ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(token.amount) + ' ' + token?.currency?.code
                                    : '-'
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.floor_price
                                    ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 20}).format(token.floor_price) + ' ' + token?.currency?.code
                                    : '-'
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.created_at_timestamp && moment.unix(token.created_at_timestamp).format("D/MM/YYYY H:s")}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-primary ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <a href={token.wallet_token.collection_token?.asset_url} target="_blank" className="text-primary w-44 cursor-pointer py-1 px-2 ml-2 border-none rounded-md shadow-sm inline-flex justify-center text-sm font-medium  focus:outline-none">
                                    <img src={eye} alt="view" className="w-4 h-4 mr-2 text-primary" />
                                    View
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.tokens?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
            />

            <ListForSaleForm open={openForm} onClose={() => {setOpenForm(false);}} item={item} onUpdate={() => props.onUpdate()} /> 

            </>
            : <NoResults item="nfts" />
            }
    
        </>
    )
}