import React from 'react';
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import ActiveNftsTable from '@components/tables/nfts/ActiveNftsTable';
import { useAuthContext, useUserOrRedirect } from '@utilities/AuthUtils';
import { DashboardStatisticInterface } from "@contracts/DashboardStatisticContract";
import { usePublicApiFetch, useRequestInstance } from "@utilities/RequestUtils";
import { useProject } from '@utilities/ProjectUtils';
import BarChart from '@components/charts/BarChart';
import LineChart from '@components/charts/LineChart';
import { Tooltip } from 'react-tooltip';

import { 
  total_nfts,
  pending_payments,
  wallets,
  info
} from './../assets/images';

/**
 * Props
 */
export interface Props {

}

/**
 * Dashboard Page Component
 */
export default function DashboardPage(props: Props) {

    // Ensure user is authenticated
    useUserOrRedirect();

    // Use auth context
    const authContext = useAuthContext();

    // Set page title
    React.useEffect(() => {
      document.title = config('app.name') + ' | Dashboard';
    }, []);

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    const { data: statistics, error, loading, mutate } = usePublicApiFetch<DashboardStatisticInterface>(`/api/dashboard-statistics` );

    return (
        <DashboardLayout title="Dashboard" page="dashboard">
        <div className="mx-auto px-0 sm:px-6 lg:px-8 min-h-[calc(100vh-290px)]">

            <div className="mx-auto px-0 sm:px-6 lg:px-8">

              <div className={`mt-6 sm:mt-10 grid grid-cols-1 gap-5 ${(statistics?.wallets && statistics?.wallets.length > 1) ? ' xl:grid-cols-2 2xl:grid-cols-4' : ' sm:grid-cols-1 lg:grid-cols-3'}`}>

                  <div className="overflow-hidden rounded-lg bg-gray-700">
                      <div className="flex items-center h-[100px] md:h-[130px] p-5">
                        <div className="flex-shrink-0 p-3 bg-gray-900 rounded-full w-[52px] h-[52px] flex justify-center items-center">
                          <img src={total_nfts} />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <div className="text-2xl text-white font-medium mb-2">
                              {statistics?.nfts}
                          </div>
                          <div className="truncate text-md text-gray-300">
                              Total NFTs
                          </div>
                        </div>
                      </div>
                  </div>
                  {/* Nfts card */}

                  <div id="payment" className="overflow-hidden rounded-lg cursor-pointer bg-gray-700">
                      <div className="flex items-center h-[100px] md:h-[130px] p-5">
                        <div className="flex-shrink-0 p-3 bg-gray-900 rounded-full w-[52px] h-[52px] flex justify-center items-center">
                          <img src={pending_payments} />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <div className="text-2xl text-white font-medium mb-2">
                            {statistics?.pendingPayments}
                          </div>
                          <div className="truncate text-md text-gray-300">
                            Pending payments
                          </div>
                        </div>
                      </div>
                  </div>
                  <Tooltip 
                      anchorId="payment" 
                      place="top"
                      className='tooltip-sm'
                      html={`<strong className="mb-1 text-md">${statistics?.payments}</strong> <div className="w-full text-sm">Done payments</div>`}
                  />
                  {/* Payments card */}

                  <div id="wallet1" className="overflow-hidden rounded-lg cursor-pointer bg-gray-700">
                      <div className="flex items-center h-[100px] md:h-[130px] p-5">
                        <div className="flex-shrink-0 p-3 bg-gray-900 rounded-full w-[52px] h-[52px] flex justify-center items-center">
                          <img src={wallets} />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <div className="text-info text-xs leading-3">Wallet 1 {(statistics?.wallets?.[1]?.balances && statistics?.wallets?.[1]?.balances.length) && statistics?.wallets?.[1]?.balances?.[0]?.currency?.code}</div>
                          <div className="text-2xl text-white font-medium mb-2">
                            {statistics?.wallets?.[0]?.balances && statistics?.wallets?.[0]?.balances.length
                                ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(statistics?.wallets?.[0]?.balances?.[0]?.available_balance)
                                : '-'
                            }
                          </div>
                          <div className="truncate text-md text-gray-300">
                            Available balance
                          </div>
                        </div>
                      </div>
                  </div>
                  <Tooltip 
                      anchorId="wallet1" 
                      place="top"
                      className='tooltip-sm'
                      html={`<strong className="mb-1 text-md"> ${statistics?.wallets?.[0]?.balances && statistics?.wallets?.[0]?.balances.length
                        ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(statistics?.wallets?.[0]?.balances?.[0]?.available_balance)
                        : '-'
                    }</strong> <div className="w-full text-sm">Balance</div>`}
                  />
                  {/* Wallet 1 card */}

                  {(statistics?.wallets && statistics?.wallets.length > 1) &&
                    <>
                    <div id="wallet2" className="overflow-hidden rounded-lg cursor-pointer bg-gray-700">
                        <div className="flex items-center h-[100px] md:h-[130px] p-5">
                          <div className="flex-shrink-0 p-3 bg-gray-900 rounded-full w-[52px] h-[52px] flex justify-center items-center">
                            <img src={wallets} />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <div className="text-info text-xs leading-3">Wallet 2 {(statistics?.wallets?.[1]?.balances && statistics?.wallets?.[1]?.balances.length) && statistics?.wallets?.[1]?.balances?.[0]?.currency?.code}</div>
                            <div className="text-2xl text-white font-medium mb-2">
                              {statistics?.wallets?.[1]?.balances && statistics?.wallets?.[1]?.balances.length
                                  ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(statistics?.wallets?.[1]?.balances?.[0]?.available_balance)
                                  : '-'
                              }
                            </div>
                            <div className="truncate text-md text-gray-300">
                              Available balance
                            </div>
                          </div>
                        </div>
                    </div>
                      <Tooltip 
                        anchorId="wallet2" 
                        place="top"
                        className='tooltip-sm'
                        html={`<strong className="mb-1 text-md"> ${statistics?.wallets?.[1]?.balances && statistics?.wallets?.[1]?.balances.length
                          ? new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(statistics?.wallets?.[1]?.balances?.[0]?.available_balance)
                          : '-'
                      }</strong> <div className="w-full text-sm">Balance</div>`}
                    />
                    {/* Wallet 2 card */}
                    </>
                  }

              </div>
              
              {statistics?.dailyPayments && statistics?.dailyNfts &&
              <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 overflow-hidden">
                  <BarChart data={statistics?.dailyNfts} name="NFTs per day" containerId="Nfts" timeframe={statistics?.nftsTimeframe} />
                  <LineChart data={statistics?.dailyPayments} name="Daily payouts" containerId="Payments" timeframe={statistics?.paymentsTimeframe} />
              </div>
              }

            {(statistics?.lastNfts && statistics?.lastNfts.length &&
            <div className="my-8">
              <div className="space-y-1">
                  <h3 className="text-md font-bold text-white mb-3">Recent NFTs</h3>
              </div>
              <div className="flex-auto overflow-x-auto">
                <ActiveNftsTable tokens={statistics?.lastNfts} filters="false" />
              </div>
          </div>
          )}


            </div>
          </div>

        </DashboardLayout>
    )
}