import React from 'react';
import { useParams } from "react-router-dom";
import { WalletTokenInterface } from "@contracts/WalletTokenContract";
import { Menu } from '@headlessui/react';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import TransferFormModal from '@components/forms/TransferFormModal';
import {sort_mapping} from './../../../assets/data';
import DatePicker from "react-datepicker";
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment.js';

import { 
    check_info,
    copy_w
} from './../../../assets/images';
import { CalendarIcon } from '@heroicons/react/solid'

let PageSize = 25;

const filterItems = (items: string[], filters: any) => {
    return items 
    ? items.filter((token: any) => filters.status === '' || (filters.status == token.status))
    .filter((token: any) => token.customer?.email.toLowerCase().includes(filters.search.toLowerCase()) || token.customer?.name.toLowerCase().includes(filters.search.toLowerCase()) || token.collection_token?.name.toLowerCase().includes(filters.search.toLowerCase()))
    .filter((token: any) => (filters.received_at_from && new Date(token.created_at_timestamp * 1000) > filters.received_at_from) || filters.received_at_from === null)
    .filter((token: any) => (filters.received_at_to && new Date(token.created_at_timestamp * 1000) < filters.received_at_to) || filters.received_at_to === null) 
    : items;
};

interface Props {

    tokens: WalletTokenInterface
}
 
export default function TransferredNftsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Filters state
    const [ filters, setFilters ] = React.useState({search: '', status: '', received_at_from: null, received_at_to: null});

    // Filter customer tokens
    const tokens = filterItems(props.tokens, filters);

    // Modal settings
    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState(0);

    // Tooltip text settings
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(tokens);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <>
        
                {/* filters */}
                <div className="w-full border rounded-md border-gray-600 p-2 sm:p-6 mb-6 flex justify-start items-end flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-[33%] xl:w-[25%] lg:mr-2 mb-4 lg:mb-0">
                        <input 
                            name="search" 
                            id="search" 
                            placeholder="Search by NFT or Recipient"
                            value={ filters.search }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilters((prev) => ({...prev, search: e.target.value}))}
                            className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent" 
                        />
                    </div>
                    <div className="w-full lg:w-[33%] xl:w-[20%] lg:mr-2 mb-4 lg:mb-0 grid grid-cols-1">
                        <div className="relative">
                            <label htmlFor="status" className="block text-sm text-white mb-1">Payout status</label>
                            <Menu>
                                <Menu.Button className="block h-12 w-full text-left bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent">
                                    {filters.status && 
                                        <>
                                            <span className={`w-2 h-2 inline-block rounded-full mr-1 ${filters.status == 'transferred' ? 'bg-info' : 'bg-primary'}`}></span>
                                        </>
                                    }
                                    {filters.status || 'All'}
                                </Menu.Button>

                                <Menu.Items className="absolute z-10 right-0 mt-2 shadow-lg flex w-full flex-col border-transparent bg-gray-700 drop-shadow-lg origin-top-right absolute right-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                                    <Menu.Item>
                                        <a 
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: ''}))} 
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div className="text-white px-1">All</div>
                                            {filters.status == '' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a 
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: 'transferred'}))} 
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div><span className="w-2 h-2 bg-info inline-block rounded-full mr-1"></span>Transferred</div>
                                            {filters.status == 'transferred' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a
                                            href="#"
                                            onClick={() => setFilters((prev) => ({...prev, status: 'redeemed'}))}
                                            className="relative flex justify-between w-full items-center py-2 px-3 rounded-t-md hover:bg-[#152944]"
                                        >
                                            <div><span className="w-2 h-2 bg-primary inline-block rounded-full mr-1"></span>Redeemed</div>
                                            {filters.status == 'redeemed' && <img src={check_info} className="w-4" /> }
                                        </a>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                        </div>
                    </div> 
                    <div className="w-full lg:w-[33%] xl:w-[26%]">
                        <div className="flex items-end justify-start">
                            <div className="min-w-0 w-full">
                                <label className="mb-1">
                                    Received at
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsStart
                                        startDate={filters.received_at_from}
                                        endDate={filters.received_at_to}
                                        selected={filters.received_at_from} 
                                        maxDate={filters.received_at_to}
                                        onChange={(date: any) => { if(!filters.received_at_from && !filters.received_at_to) {setFilters((filters) => ({...filters, received_at_from: date}))} else if(filters.received_at_from && !filters.received_at_to){setFilters((filters) => ({...filters, received_at_to: date}))} else {setFilters((filters) => ({...filters, received_at_from: date}))} }} 
                                        placeholderText={'From date'} 
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>

                            <span className="text-white ml-2">to</span>

                            <div className="min-w-0 w-full ml-2">
                                <label className="sr-only">
                                    Filter by end date
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <DatePicker 
                                        selectsEnd
                                        startDate={filters.received_at_from}
                                        endDate={filters.received_at_to}
                                        selected={filters.received_at_to} 
                                        minDate={filters.received_at_from}
                                        onChange={(date: any) => { if(!filters.received_at_from && !filters.received_at_to) {setFilters((filters) => ({...filters, received_at_from: date}))} else if(filters.received_at_from && !filters.received_at_to){setFilters((filters) => ({...filters, received_at_to: date}))} else {setFilters((filters) => ({...filters, received_at_to: date}))} }} 
                                        placeholderText={'From date'} 
                                        monthsShown={window.innerWidth <= 768 ? 1 : 2}
                                        onFocus={(e) => window.innerWidth <= 768 ? e.target.readOnly = true : null}
                                        calendarStartDay={1}
                                        dateFormat="dd/MM/yyyy"
                                        className="block h-12 w-full bg-gray-700 text-white placeholder-gray-200 shadow-sm py-2 px-3 rounded-md focus:outline-none sm:text-sm border-transparent"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* end filters */}
                <div className="table-scroll overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
            <table className="min-w-full rounded-md">
                <thead>
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                        
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Token / Collection
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Original Owner
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Received at
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer  sort ${sortConfig?.key === 'status' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('status')}
                    >
                        Status 
                        <img src={sort_mapping[sortConfig?.key === 'status' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Transferred To
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        TX ID
                    </th>
                    </tr>
                </thead>
                <tbody  className="border-l border-b border-r border-gray-600 rounded-md">
                    {currentTableData?.map((token, tokenIndex) => (
                        <tr key={token.id}>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div className="aspect-w-1 aspect-h-1 w-16 overflow-hidden rounded-lg bg-gray-200 block">
                                    <img
                                    src={token.collection_token?.thumbnail_url}
                                    alt={token.collection_token?.name}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div> 
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div id={`token2-${tokenIndex+1}`} className="font-medium text-white truncate max-w-[150px] cursor-pointer">
                                    { token.collection_token?.name }: #{ token.collection_token?.token_id }
                                    <Tooltip 
                                        anchorId={`token2-${tokenIndex+1}`}
                                        place="top"
                                        className='tooltip-sm'
                                        html={ token.collection_token?.token_id }
                                    />
                                </div>
                                <div className="flex flex-col mt-1 text-gray-400">
                                    { token.collection_token?.collection?.name }
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.customer && 
                                <div className="font-medium text-white">
                                    {token.customer.email}
                                </div>
                                }
                                {token.from_address 
                                    ? <div className="flex flex-col mt-1 text-gray-300 break-all">{ token.from_address }</div>
                                    : <div className="flex flex-col mt-1 text-gray-300">Unknown</div>
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.created_at_timestamp && moment.unix(token.created_at_timestamp).format("D/MM/YYYY H:s")}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div>
                                    <span className={`w-2 h-2 inline-block rounded-full mr-1 ${token.status == 'transferred' ? 'bg-info' : 'bg-primary'}`}></span>
                                    {token.status}
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-whiten hidden-text ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {(token.statue != 'transferred' && token.customer) && 
                                <div className="font-medium text-white">
                                    {token.customer.name}
                                </div>
                                }
                                {token.from_address 
                                    ?   <div id={`token3-${tokenIndex+1}`} className="flex mt-1 text-gray-300 justify-start h-[20px] cursor-pointer"><span className="show">{token.from_address}</span><span className="hide">{token.from_address.slice(token.from_address.length - 6)}</span>
                                            <img 
                                                src={copy_w} 
                                                className="w-4 ml-2" 
                                                onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(token.from_address); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                            />
                                            <Tooltip 
                                                anchorId={`token3-${tokenIndex+1}`}
                                                place="top"
                                                className='tooltip-sm'
                                                html={keyCopied == true ? 'Copied!' : token.from_address}
                                            />
                                        </div>
                                    : <div className="flex flex-col mt-1 text-gray-300">Unknown</div>
                                }
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-left hidden-text ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.payment?.transaction?.txid &&
                                    <div 
                                        id={`token9-${tokenIndex+1}`} className="flex mt-1 text-gray-300 justify-start cursor-pointer h-[20px]"
                                    >
                                        <span className="show">{token.payment?.transaction?.txid}</span><span className="hide">{token.payment?.transaction?.txid.slice(token.payment?.transaction?.txid.length - 6)}</span>
                                        <img 
                                            src={copy_w} 
                                            className="w-4 ml-2" 
                                            onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(token.payment?.transaction?.txid); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                        />
                                        <Tooltip 
                                            anchorId={`token9-${tokenIndex+1}`}
                                            place="top"
                                            className='tooltip-sm'
                                            html={keyCopied == true ? 'Copied!' : token.payment?.transaction?.txid}
                                        />
                                    </div>
                                }
                                <div className="">
                                    {token.payment?.transaction
                                        ? <a href={token.payment?.transaction?.explorer_url} target="_blank" className="text-primary cursor-pointer border-none rounded-md text-sm font-medium  focus:outline-none">
                                            Etherscan
                                        </a>
                                        : '-'
                                    }
                                </div>
                            </td>
                        
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.tokens?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
            />
            </div>
            </div>

            <TransferFormModal open={open} onClose={() => setOpen(false)} item={item} />
        </>
    )
}