/**
 * General application configuration
 */
export default {

    // Api URL
    apiUrl: process.env.REACT_APP_API_URL,

    // App name
    name: process.env.REACT_APP_NAME,

    // Development mode
    development: process.env.REACT_APP_ENVIRONMENT == 'development',

}