import React from 'react';
import usePagination from '@utilities/PaginationUtils';


/**
 * Props
 */
 export interface Props {

    onPageChange: () => void,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number,
    className: string
    
}

export default function Pagination({ ...props }) {

    // Use pagination
    const paginationRange = usePagination(props);

    // return null if no need of pagination
    if (props.currentPage === 0 || (paginationRange && paginationRange?.length < 2))
        return null;

    // show next page
    const onNext = () => {
        props.onPageChange(props.currentPage + 1);
    };

    // show previous page
    const onPrevious = () => {
        props.onPageChange(props.currentPage - 1);
    };

    // show selected page
    const onPage = (page: number) => {
        props.onPageChange(page);
    };

    // get last page
    let lastPage = paginationRange?.[paginationRange ? paginationRange?.length - 1 : 1] || 1;

    return (
        <div className="border-t border-gray-700 flex justify-end">
        <ul
        className="flex list-none py-2 max-w-md"
        >
            {props.currentPage > 1 && 
                <li
                    className={`h-8 w-8 ml-1 border border-gray-600 text-center m-auto text-white flex items-center justify-center text-sm rounded min-w-6 cursor-pointer hover:bg-gray-600 ${props.currentPage === 1 && 'disabled'}`}
                    { ...(props.currentPage > 1 && {'onClick': onPrevious})}
                >
                    <div className="arrow left" />
                </li>
            }

        {paginationRange?.map((pageNumber: any, indx) => {

            if (pageNumber === '...')
                return <li className={`h-8 w-8 ml-1 border border-gray-600 text-center m-auto text-white flex items-center justify-center text-sm rounded min-w-6 cursor-pointer hover:bg-gray-600 disabled`}>&#8230;</li>;

            return (
            <li
                key={indx}
                className={`h-8 w-8 ml-1 border border-gray-600 text-center m-auto text-white flex items-center justify-center text-sm rounded-md cursor-pointer hover:bg-gray-600 ${pageNumber === props.currentPage && 'bg-gray-500'}`}
                onClick={() => onPage(pageNumber)}
            >
                {pageNumber}
            </li>
            )
        })}

            {props.currentPage < lastPage && 
                <li
                    className={`h-8 w-8 ml-1 border border-gray-600 text-center m-auto text-white flex items-center justify-center text-sm rounded-md cursor-pointer hover:bg-gray-600 ${props.currentPage === lastPage && 'disabled'}`}
                    { ...(props.currentPage < lastPage && {'onClick': onNext})}
                >
                <div className="arrow right" />
            </li>
            }
        </ul>
        </div>
    );
}