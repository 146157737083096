import React from 'react'
import { useNavigate, useLocation  } from "react-router-dom";
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { PaymentInterface } from "@contracts/PaymentContract";
import { useUserOrRedirect } from '@utilities/AuthUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import PendingPaymentsTable from '@components/tables/payments/PendingPaymentsTable';
import PaymentsTable from '@components/tables/payments/PaymentsTable';
import RepaymentsTable from '@components/tables/payments/RepaymentsTable';
import LoadingSpinner from '@components/ui/LoadingSpinner';
import TableScript from '@components/tables/TableScript';   
import "react-datepicker/dist/react-datepicker.css";

/**
 * Props
 */
export interface Props {

}

/**
 * Payments Page Component
 */
export default function PaymentsPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Payouts';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Fetch payments
    const { data: payments, error, loading, mutate: mutatePayment } = usePublicApiFetch<PaymentInterface[]>('/api/payments');

    // Fetch pending payments
    const { data: pending_payments, error: errorPending, loading: loadingPending, mutate: mutatePending } = usePublicApiFetch<PaymentInterface[]>('/api/pending-payments');
    
    // Use navigation
    const navigate = useNavigate();

    // Hash params
    const { hash } = useLocation();

    // Tabs state
    const [openTab, setOpenTab] = React.useState(hash.substring(1) || 'payments');

    React.useEffect(() => {
        if(hash.substring(1))
            setOpenTab(hash.substring(1));
    }, [hash]);

    return (
        <DashboardLayout title="Payouts" page="payments">

            <div className="mt-10">

            {(loading || loadingPending ? <LoadingSpinner /> : 
                <>

                <PendingPaymentsTable payments={pending_payments} onUpdate={() => {mutatePayment(); mutatePending()}} />
                
                <div className="mt-10 align-middle min-w-full">
                    
                        {/* tabs */}
                        <div>
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                Select a tab
                                </label>
                                <select
                                id="tabs"
                                name="tabs"
                                className="block w-full rounded-md focus:border-secondary bg-gray-600 text-white"
                                defaultValue="Pending Payments"
                                onChange={e => setOpenTab(e.target.value)}
                                >
                                    <option value="payments">Payments</option>
                                    <option value="repayments">Repayments</option>
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-700">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">

                                    <a
                                        key="Payments"
                                        className={`group inline-flex items-center py-4 px-1 border-b-2 text-sm ${openTab === 'payments' ? "border-secondary text-secondary font-medium" : "border-transparent text-white hover:text-gray-300"}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            navigate('#payments');
                                            setOpenTab('payments');
                                        }}
                                        data-toggle="tab"
                                        href="#payments"
                                        role="tablist"
                                    >
                                        <span>Payments</span>
                                    </a>

                                    <a
                                        key="repayments"
                                        className={`group inline-flex items-center py-4 px-1 border-b-2 text-sm ${openTab === 'repayments' ? "border-secondary text-secondary font-medium" : "border-transparent text-white hover:text-gray-300"}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            navigate('#repayments');
                                            setOpenTab('repayments');
                                        }}
                                        data-toggle="tab"
                                        href="#repayments"
                                        role="tablist"
                                    >
                                        <span>Repayments</span>
                                    </a>

                                </nav>
                                </div>
                            </div>
                        </div>
                        {/* end tabs */}

                        {/* tabs content */}

                        <div className="relative flex flex-col min-w-0 break-words text-white w-full rounded">
                            <div className="mt-6 flex-auto">
                                <div className="tab-content tab-space min-h-[800px]">

                                    <div className={openTab === 'payments' ? "block" : "hidden"} id="payments">

                                        <PaymentsTable payments={payments} />

                                    </div>
                                    {/* end first tab content */}

                                    <div className={openTab === 'repayments' ? "block" : "hidden"} id="repayments">

                                        <RepaymentsTable />

                                    </div>
                                    {/* end second tab content */}
                                    
                                </div>
                            </div>
                        </div>
                        <TableScript />
                </div>

                </>
                )}

            </div>

        </DashboardLayout>
    )
}