/**
 * Request utilities
 */
import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { config } from '@utilities/ConfigUtils';
import useSWR, { SWRResponse } from 'swr';
import { useAuthContext } from './AuthUtils';
import { useProject } from './ProjectUtils';
import { toast } from 'react-toastify';

// Create request instance
export function createRequestInstance(baseUrl: string = '/', config?: AxiosRequestConfig<any>) {
 
    // Create instance
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 12000, // In milliseconds
        ...config
    });

    // get response message and show error
    instance.interceptors.response.use(undefined, error => {

        // get error message
        const message: string = error?.response?.data?.message;

        // get current date
        const now = new Date();

        // compose time string for every 10 seconds
        const timeString = now.getHours() + ':' + now.getMinutes() + ':' + String(now.getSeconds()).charAt(0);

        if(message && !error?.response?.data?.errors) 
            toast(message, {type: "error", toastId: timeString});
        

        return Promise.reject(error);
    });

    // Return instance
    return instance;
}

/**
 * Use axios instance hook.
 * To reuse request instance between renders.
 */
export function useRequestInstance(authToken?: string) {

    // Headers to pass to instance
    const headers = authToken ? { Authorization: `Bearer ${ authToken }` } : undefined;

    // Get request instance
    return React.useMemo(() => createRequestInstance(config('app.apiUrl'), { headers }), [ authToken ]);
}

/**
 * Fetcher function for SWR
 */
export async function fetchData(url: string, authToken?: string) {

    // Return null if no url
    if(url === null)
        return;

    // Get request instance
    const requestInstance = createRequestInstance(config('app.apiUrl'));

    // Execute request
    const response = await requestInstance.get(url, { 
        headers: {
            Authorization: `Bearer ${ authToken }`
        } 
    });

    // Return data
    return response?.data?.data;
}

/**
 * Fetch data from remote api endpoint on web API
 */
export function useWebApiFetch<T>(endpointUrl: string): SWRResponse<T> &  { loading: boolean } {

    // Use auth context
    const authContext = useAuthContext();

    // Execute SWR fetch
    const swrResponse = useSWR<T>(
        authContext?.user?.token ? [ endpointUrl, authContext?.user?.token ] : null, 
        (url: string, token: string) => fetchData(url, token)
    );

    // Return fetch object
    return { ...swrResponse, loading: !swrResponse?.data && !swrResponse?.error };
}

/**
 * Fetch data from public api
 */
export function usePublicApiFetch<T>(endpointUrl: string | null): SWRResponse<T> &  { loading: boolean } {

    // Use project
    const { project, loading } = useProject();

    // Execute SWR fetch
    const swrResponse = useSWR<T>(
        project ? [endpointUrl, project.auth_token] : null,
        (url: string, token: string) => fetchData(url, token) 
    );

    // Return fetch object
    return { ...swrResponse, loading: !swrResponse?.data && !swrResponse?.error };
}