import React from 'react';

/**
 * Props
 */
export interface Props {

    // Error message to be shown.
    // On undefined doesn't display anything
    message?: string | string[]
}

/**
 * Field Error Message Component
 * Displays error message under form field.
 */
export default function FieldErrorMessage(props: Props) {

    // Nothing to display
    if(!props?.message || props?.message.length < 1) return null;

    return (
        <div className="pt-2 text-xs text-red-600">
            { props?.message }
        </div>
    )
}