import React, { useEffect } from 'react';
import { config } from '@utilities/ConfigUtils';
import { XIcon } from '@heroicons/react/outline';
import MainLayout from '@components/layouts/MainLayout';
import { useAuthContext, useUserOrRedirect } from '@utilities/AuthUtils';


/**
 * Props
 */
export interface Props {

}

/**
 * Email verify success Page Component
 */
export default function EmailVerfyInvalidPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Error page';
    }, []);

    return (
        <MainLayout>
            <div className="h-[100vh] m-full max-w-xl">
                <div className="flex-shrink-0 flex justify-center">
                <XIcon className="text-secondary text-primary w-48 mt-[7vh] md:mt-[20vh]"/>
                </div>
                <div className="py-16">
                    <div className="text-center">
                    <p className="text-sm font-semibold text-gradient uppercase tracking-wide">Error</p>
                    <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Unable to verify email address</h1>
                    <p className="mt-2 text-base text-gray-500"></p>
                    <div className="mt-6">
                        <a href="/" className="text-base font-medium text-gradient hover:text-indigo-500">
                        Go back home<span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}