import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { CollectionInterface } from "@contracts/CollectionContract";
import CollectionCreateForm from '@components/forms/CollectionCreateForm';
import CollectionEditForm from '@components/forms/CollectionEditForm';
import DefaultCollectionEditForm from '@components/forms/DefaultCollectionEditForm';
import LoadingSpinner from '@components/ui/LoadingSpinner';
import ConfirmationModal from '@components/ui/ConfirmationModal';
import FormModal from '@components/ui/FormModal';
import Pagination from '@components/ui/Pagination';
import TableScript from '@components/tables/TableScript';
import { useRequestInstance } from '@utilities/RequestUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import { useUserOrRedirect } from '@utilities/AuthUtils';
import useSortableData from '@utilities/SortingUtils';
import { useProject } from '@utilities/ProjectUtils';
import {sort_mapping} from './../../assets/data';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment.js';

import {
    SearchIcon
} from '@heroicons/react/outline'

import { 
    bin,
    edit,
    info,
    check_green,
    cross,
    eye
} from './../../assets/images';

let PageSize = 25;

/**
 * Props
 */
export interface Props {

}

/**
 * Collections Page Component
 */
export default function CollectionsPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Collections';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Fetch collections
    const { data: collections, error, loading, mutate: mutateList } = usePublicApiFetch<CollectionInterface[]>('/api/collections');

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Query params 
    const {page} = useParams<{ page: any }>();

    // Sort collections
    const { items, requestSort, sortConfig } = useSortableData(collections);

    // Pagination settings
    const [currentPage, setCurrentPage] = useState<number>(page || 1);

    // Get current page collections
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    // Modals settings
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(0);
    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState('create');

    // Delete collection
    const confirmAction = async () => {

        // Hide confirmation modal
        setOpen(false);

        // Filter collections
        const newCollections = collections?.filter((c) => c.id !== item);

        // Update the list of collections
        await mutateList(newCollections, false);

        try {
           
            // Execute request
            const response = await requestInstance.delete(`/api/collections/${item}`);

        } catch (err) {
          console.log(err);
        }
    };

    // Create query state
    const [ query, setQuery ] = React.useState<string>('');

    // Initial values
    const initialValues = { search: '' };

    return (
        <DashboardLayout title="Collections" page="collections">
            {(loading ? <LoadingSpinner /> : 
                <>

            <div className="mt-10">
                
                <div className="md:space-y-1 flex justify-between items-end flex-wrap md:flex-nowrap mt-5">
                    <div className="pr-0 md:pr-6 w-full md:w-[300px]">
                        <p className="mt-1 text-sm text-gray-200 sr-only">Search by name</p>

                        <div className="mt-6 flex space-x-4">
                            <div className="flex-1 min-w-0">
                                <label htmlFor="search" className="sr-only">
                                Search by name
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 items-center pointer-events-none hidden">
                                    <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                </div>
                                <input 
                                    type="search" 
                                    name="search" 
                                    id="search" 
                                    placeholder="Search by name..."
                                    value={ query }
                                    ref={ref => ref && ref.focus()}
                                    onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                                    className="focus:ring-secondary focus:border-secondary text-white block w-full h-[48px] sm:text-sm border-none bg-gray-700 rounded-md placeholder-gray-300" 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                                />

                                </div>
                            </div>
                        </div>
   
                    </div>

                    <button 
                        className="bg-gradient mt-4 md:mt-0 border-none rounded-md shadow-sm w-full md:w-auto h-[48px] py-3 px-4 truncate inline-flex items-center justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none"
                        onClick={(e) => { e.preventDefault(); setAction('create'); setOpenForm(true) }}
                        >
                        + Whitelist <span className="hidden sm:block ml-2"> collection</span>
                    </button>
                </div>
                
                <div className="mt-10 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg">

                            {/* <div className="w-full h-[6px] bg-gradient"></div> */}
                            {true &&
                            <>
                            <table className="min-w-full">
                                <thead>
                                    <tr>
                                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'name' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('name')}
                                    >
                                        Name
                                        <img src={sort_mapping[sortConfig?.key === 'name' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                    </th>
                                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'market' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('market')}
                                    >
                                        Marketplace
                                        <img src={sort_mapping[sortConfig?.key === 'market' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                    </th>
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[170px]`}
                                        data-tooltip="How many percents of the floor price should be the payout amount."
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'floor_price_difference' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('floor_price_difference')}
                                        >
                                            FP Payout Diff.
                                            <img src={sort_mapping[sortConfig?.key === 'floor_price_difference' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                        <img id="floor_price_difference" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                                        <Tooltip 
                                            anchorId="floor_price_difference" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="How many percents of the floor price should be the payout amount."
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[180px]`}
                                        data-tooltip="How many days, after successful payout, does the user has to return the liquidity, before losing the NFT."
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'expiring_days' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('expiring_days')}
                                        >
                                            Days until Expire
                                            <img src={sort_mapping[sortConfig?.key === 'expiring_days' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                        <img id="expiring_days" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                                        <Tooltip 
                                            anchorId="expiring_days" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="How many days, after successful payout, does the user has to return the liquidity, before losing the NFT."
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[190px]`}
                                        data-tooltip="All received NFTs for that collection will be paid out automatically with the selected settings."
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'automatic_payout' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('automatic_payout')}
                                        >
                                            Automatic payout
                                            <img src={sort_mapping[sortConfig?.key === 'automatic_payout' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                        <img id="automatic_payout" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                                        <Tooltip 
                                            anchorId="automatic_payout" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="All received NFTs for that collection will be paid out automatically with the selected settings."
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[170px]`}
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'listing_floor_price_difference' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('listing_floor_price_difference')}
                                        >
                                            Listing FP Diff.
                                            <img src={sort_mapping[sortConfig?.key === 'listing_floor_price_difference' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                    </th>
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[180px]`}
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'listing_days' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('listing_days')}
                                        >
                                            Listing days
                                            <img src={sort_mapping[sortConfig?.key === 'listing_days' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                    </th>
                                    {/* <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[190px]`}
                                        data-tooltip="All received NFTs for that collection will be paid out automatically with the selected settings."
                                    >
                                        <div 
                                            className={`inline sort ${sortConfig?.key === 'auto_sale' ? sortConfig.direction : undefined}`}
                                            onClick={() => requestSort('auto_sale')}
                                        >
                                            Auto sale
                                            <img src={sort_mapping[sortConfig?.key === 'auto_sale' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                        </div>
                                        <img id="auto_sale2" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                                        <Tooltip 
                                            anchorId="auto_sale2" 
                                            place="top"
                                            className='tooltip-sm'
                                            html="Automatically list for sale when a token of this collection expires"
                                        />
                                    </th> */}
                                    <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('created_at_timestamp')}
                                    >
                                        Added At
                                        <img src={sort_mapping[sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                                    </tr>
                                </thead>
                                <tbody className="border-l border-b border-r border-gray-600 rounded-md">


                                <tr key="0" className="bg-secondary/3">
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        <strong>Default collection</strong>
                                        <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                                    </td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}></td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        {project?.floor_price_difference && project?.floor_price_difference + ' %' }
                                    </td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        { project?.expiring_days && project?.expiring_days + ( project?.expiring_days != '1' ? ' days' : ' day') }
                                    </td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        <span className={`inline-flex`}>
                                            { project?.automatic_payout == true
                                                ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                                : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                                            }
                                        </span>
                                    </td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        {project?.listing_floor_price_difference && project?.listing_floor_price_difference + ' %' }
                                    </td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        {project?.listing_days }
                                    </td>
                                    {/* <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        <span className={`inline-flex`}>
                                        { project?.auto_sale == true
                                            ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                            : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                                        }
                                        </span>
                                    </td> */}
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}></td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}></td>
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                        <a 
                                            href="#" 
                                            className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                            onClick={() => { setItem(0); setAction('edit'); setOpenForm(true) }}
                                        >
                                            <img src={edit} alt="delete" className="w-4 h-4 mr-2" />
                                            Edit
                                        </a>
                                    </td> 
                                    <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}></td>
                                </tr>

                                {currentTableData?.filter((collection) => collection.name.toLowerCase().includes(query.toLowerCase())).map((collection, collectionIdx) => (
                                        
                                    <tr key={collection.id}>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {collection.name}
                                            <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {collection.market?.name}
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {collection.floor_price_difference && collection.floor_price_difference + '%'}
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            { collection.expiring_days && collection.expiring_days + ( collection.expiring_days != '1' ? ' days' : ' day') }
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            <span className={`inline-flex`}>
                                            { collection.automatic_payout == true
                                                ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                                : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                                            }
                                            </span>
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {collection.listing_floor_price_difference && collection.listing_floor_price_difference + ' %' }
                                        </td>
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {collection.listing_days }
                                        </td>
                                        {/* <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            <span className={`inline-flex`}>
                                            { collection.auto_sale == true
                                                ? <><img src={check_green} className="mr-2" /> <span className="text-success">Yes</span></>
                                                : <><img src={cross} className="mr-2" /> <span className="text-danger">No</span></>
                                            }
                                            </span>
                                        </td> */}
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            {moment.unix(collection.created_at_timestamp).fromNow()}
                                        </td>
                                        
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            <a href={collection.collection_url} target="_blank" className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center">
                                                <img src={eye} alt="view" className="w-4 h-4 mr-2 text-primary" />
                                                View
                                            </a>
                                        </td> 
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            <a 
                                                href="#" 
                                                className="text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                                onClick={(e) => { e.preventDefault(); setItem(collection.id); setAction('edit'); setOpenForm(true) }}
                                            >
                                                <img src={edit} alt="delete" className="w-4 h-4 mr-2" />
                                                Edit
                                            </a>
                                        </td> 
                                        <td className={` px-3 py-3.5 text-sm text-white border-t border-gray-700`}>
                                            <button 
                                                type="submit" 
                                                className="ml-2 text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                                onClick={() => { setOpen(true); setItem(collection.id)}}
                                                >
                                                <img src={bin} alt="delete" className="w-4 h-4 mr-2" />
                                                Delete
                                            </button>
                                        </td> 
                                    </tr>
                                        
                                    ))}
                                </tbody>
                            </table>
                            <TableScript />
                            </>
                            }
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={collections?.length}
                                pageSize={PageSize}
                                onPageChange={(page: any) => setCurrentPage(page)}
                            />
                </div>

            </div>
            
            <ConfirmationModal open={open} onClose={() => setOpen(false)} confirmAction={confirmAction} action="delete" title="Delete collection?" text="Are you sure you want to remove this collection and its settings?" />
            <FormModal open={openForm} onClose={() => setOpenForm(false)} item={item} page={item ? 'collection' : 'default-collection'} action={action}>
                {action == 'create' 
                ? <CollectionCreateForm onClose={() => {setOpenForm(false); mutateList()}} /> 
                : action == 'edit' && item 
                    ? <CollectionEditForm item={item} onClose={() => {setOpenForm(false); mutateList()}} />
                    : <DefaultCollectionEditForm item={item} onClose={() => {setOpenForm(false)}} />
                }
            </FormModal>

            </>
            )}
        </DashboardLayout>
    )
}