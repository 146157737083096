import { BrowserRouter, Routes, Route } from 'react-router-dom';
import webRoutes from 'routes/webRoutes';

/**
 * Router Provider Component
 * 
 * Provides browser routes to access pages.
 */
export default function RouterProvider() {
    return (
        <BrowserRouter>
            <Routes>

                { webRoutes.map(route => 
                    <Route key={ route.name } path={ route.path } element={ <route.component /> } />
                )}

            </Routes>
        </BrowserRouter>
    )
}