import React from 'react';
import { config } from '@utilities/ConfigUtils';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import MainLayout from '@components/layouts/MainLayout';
import { useAuthContext, useGuestOrRedirect } from '@utilities/AuthUtils';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import {password_show_mapping} from './../../assets/data';

/**
 * Props
 */
export interface Props {

}

/**
 * Login Page Component
 */
export default function LoginPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Login';
    }, []);
    
    // Initial values
    const initialValues = { email: '', password: '', remember: false };

    // Ensure user is not authenticated to access this page
    useGuestOrRedirect();

    // Use auth context
    const authContext = useAuthContext();

    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = React.useState(false);

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {
    
        try {
            
            // Execute authentication
            await authContext.signIn({ email: data.email, password: data.password, remember: data.remember });
        }

        catch(exception) {
            
            // Clear form values
            helpers.resetForm();
            
            // Set message
            helpers.setFieldError('password', typeof exception == 'string' ? exception : 'Uknown error occurred! Please try again.');
        }
    }

    return (
        <MainLayout>
            <div className="flex items-center w-full lg:max-w-md justify-center lg:justify-start min-h-[calc(100vh-100px)]">
                <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-2xl md:text-3xl font-extrabold text-white">Sign in to your account</h2>
                </div>

                <Formik
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="mt-8 space-y-6">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div className="mb-6">
                                <label htmlFor="email-address" className="text-white text-md">
                                Email address
                                </label> 
                                <Field
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : 'border-none'}`}
                                    placeholder="Email address"
                                />
                            </div>
                            <div className="mb-6">
                                <div className="flex items-cente justify-between">
                                    <label htmlFor="password" className="text-white text-md">
                                        Password
                                    </label>
                                    <a href="/auth/password/forgot" className="font-medium text-secondary text-sm text-primary hover:text-indigo-500">
                                        Forgot your password?
                                    </a>
                                </div>
                                <div className="relative">
                                    <Field
                                        id="password"
                                        name="password"
                                        type={passwordShown ? "text" : "password"}
                                        autoComplete="current-password"
                                        required
                                        className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.password ? 'border-1 border-danger' : 'border-none'}`}
                                        placeholder="Password"
                                    />
                                    <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                        <img src={password_show_mapping[`${passwordShown}`]} onClick={ () => setPasswordShown((prev) => !prev)} className="w-4 h-4 inline ml-2" />
                                    </div>
                                </div>
                                <FieldErrorMessage message={ helpers.errors?.password } />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <Field
                                    id="remember"
                                    name="remember"
                                    type="checkbox"
                                    className="w-5 h-5 text-indigo-600 rounded"
                                    checked={ helpers.values.remember }
                                    value={ helpers.values.remember.toString() }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("remember", e.target.checked ? 1 : 0)}
                                />
                                <label htmlFor="remember" className="block ml-2 text-sm text-white">
                                Remember me
                                </label>
                            </div>

                        </div>

                        <div>
                            <FormButton loading={helpers.isSubmitting} label='Sign in' type='auth' />
                        </div>

                        <div className="relative">
                            <span className="px-2 text-white text-sm">
                                Need an account? <a href="/auth/register" className="ml-2 text-primary underline hover:text-indigo-500">Sign Up</a>
                            </span>
                        </div>

                    </Form>
                    )}
                </Formik>
                
                </div>
            </div>
        </MainLayout>
    )
}