import React from 'react';
interface Props {

    data: string[]
}
 
export default function CodeTree({ ...props }) {

    return (
        <>
            {!props.data.items
            ? <div className="mb-3 ml-8">
                <span className="text-sm text-fuchsia-300">"{props.data.column}":</span> <span className="text-sm text-green-400">{props.data.value},</span>
                </div>
            : <div className="mb-3 ml-8">
                <span className="text-sm text-fuchsia-300">"{props.data.column}":</span>{props.data.collection && <span className="text-gray-200 ml-1 mb-3">{'['}</span>}<span className="text-gray-200 block mb-3">{'{'}</span>
                    {props.data?.items.map((d: any, dIndex: any) => (
                        <React.Fragment key={Math.random()}>
                            <CodeTree data={d} />
                        </React.Fragment>
                    ))}
                <span className="text-gray-200">{'}'}{props.data.collection && <span className="text-gray-200 mb-3">{']'}</span>},</span>
                </div>
            }
        </>
    )
}