import {noData} from './../../assets/data';
import { 
    nodata 
} from './../../assets/images';


interface Props {

    item: string,

    // Layout children components
    children: React.ReactNode
}
 
const NoResults = ({ ...props }) => {

    return (

        <div className="items-center my-8 p-8 border border-gray-600 rounded-md flex flex-col">
            <div className="w-44 h-44 flex justify-center items-center bg-gray-700 rounded-full">
                <img src={noData.find(a => a.item === props.item)?.image || nodata} alt="nft pay" className="w-20" />
            </div>
            <h3 className="mt-4 text-md font-medium text-white">{noData.find(a => a.item === props.item)?.title || 'No data yet' }</h3>

            <div className="w-full max-w-[788px] text-center mt-4">
                {noData.find(a => a.item === props.item)?.text }
            </div>

            {props.children}
        </div>
    )
}
 
export default NoResults;