import React from "react";
import { ToastContainer } from 'react-toastify';
import { logo, auth } from './../../assets/images';

/**
 * Props
 */
export interface Props {

    // Layout children components
    children: React.ReactNode,
}

/**
 * Main Layout
 */
export default function MainLayout(props: Props) {
    return (
        <main>
            <div className="bg-gray-700 m-h-screen w-full pt-[39px] pb-[20px] px-5 lg:px-20 relative">
                <a href="/">
                    <img src={logo} alt="nft pay" className="w-[175px] h-[41px]" />
                </a>
                { props.children }
                <div className="hidden lg:block h-screen lg:w-[34%] xl:w-[43%] bg-gray-900 absolute right-0 top-0 bottom-0 rounded-3xl">
                    <img src={auth} alt="Nft pay" className="absolute max-h-[90vh] top-1/2 -translate-y-1/2 -left-32 pointer-events-none" />
                </div>
                <ToastContainer theme="dark" />
            </div>
        </main>
    )
}