/**
 * Authentication Utilities
 */
import React from "react";
import { AuthProviderValue } from "@contracts/AuthContract";
import { useNavigate } from "react-router-dom";
import { UserInterface } from "@contracts/UserContract";

/**
 * Auth context, used by provider component
 * @see AuthProvider
 */
export const AuthContext = React.createContext<AuthProviderValue | undefined>(undefined);

/**
 * Use auth context
 */
export function useAuthContext() {
    return React.useContext(AuthContext) as AuthProviderValue;
}

/**
 * Use user or redirect
 */
export function useUserOrRedirect(url: string = '/auth/login'): { user?: UserInterface, loading: boolean } {

    // Use auth context
    const authContext = useAuthContext();

    // Use navigation
    const navigation = useNavigate();

    // Get logged at time
    const loggedAt:any = localStorage.getItem("loggedAt");

    React.useEffect(() => {

        // Fetch is loading
        if(authContext.userLoading) return;

        // User not authenticated, redirect to page
        if(!authContext?.user) navigation(url);

        // check for expire login
        if(authContext?.user?.remember_token != true && (loggedAt === undefined || new Date().getTime() - loggedAt > 1000 * 60 * 60 * 24))
            authContext.signOut();

    }, [ authContext.user, authContext.userLoading ]);

    // Return
    return { user: authContext?.user, loading: authContext?.userLoading };
}

/**
 * Use guest or redirect
 */
export function useGuestOrRedirect(url: string = '/') {

    // Use auth context
    const authContext = useAuthContext();

    // Use navigation
    const navigation = useNavigate();

    React.useEffect(() => {

        // Fetch is loading
        if(authContext.userLoading) return;

        // User is authenticated, redirect to page
        if(authContext?.user) navigation(url);

    }, [ authContext.user, authContext.userLoading ]);
}