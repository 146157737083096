import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import CustomerCreateForm from '@components/forms/CustomerCreateForm';
import CustomerEditForm from '@components/forms/CustomerEditForm';
import { CustomerInterface } from "@contracts/CustomerContract";
import { useUserOrRedirect } from '@utilities/AuthUtils';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import LoadingSpinner from '@components/ui/LoadingSpinner';
import ConfirmationModal from '@components/ui/ConfirmationModal';
import FormModal from '@components/ui/FormModal';
import NoResults from '@components/ui/NoResults';
import TableScript from '@components/tables/TableScript';
import { useRequestInstance } from '@utilities/RequestUtils';
import { useProject } from '@utilities/ProjectUtils';
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import { Tooltip } from 'react-tooltip';
import {sort_mapping} from './../../assets/data';
import moment from 'moment/moment.js';
import __ from "lodash";

import { 
    bin,
    edit,
    info
} from './../../assets/images';

import {
    ExclamationIcon
  } from '@heroicons/react/outline'

let PageSize = 25;

/**
 * Props
 */
export interface Props {

}

/**
 * Customers Page Component
 */
export default function CustomersPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Customers';
    }, []);

    // Ensure user is authenticated
    useUserOrRedirect();

    // Fetch customers
    const { data: customers, error, loading, mutate: mutateList } = usePublicApiFetch<CustomerInterface[]>('/api/customers');

    // Use current project
    const { project } = useProject();

    // Use request instance
    const requestInstance = useRequestInstance(project?.auth_token);

    // Modals settings
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(0);
    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState('create');

    // Query params 
    const {page} = useParams<{ page: any }>();

    // Sort customers
    const { items, requestSort, sortConfig } = useSortableData(customers);

    // Pagination settings
    const [currentPage, setCurrentPage] = useState<number>(page || 1);

    // Create query state
    const [ query, setQuery ] = React.useState<string>('');

    // Get current page customers
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    // Delete customer
    const confirmAction = async () => {

        // Hide confirmation modal
        setOpen(false);

        // Filter customers
        const newCustomers = customers?.filter((c) => c.id !== item);

        // Update the list of customers
        await mutateList(newCustomers, false);

        try {
           
            // Execute request
            const response = await requestInstance.delete(`/api/customers/${item}`);

        } catch (err) {
          console.log(err);
        }
    };

    return (
        <DashboardLayout title="Customers" page="customers">

            {(loading ? <LoadingSpinner /> : 
                <>
                {(customers && customers.length ?
                <>
            <div className="">

                <div className="md:space-y-1 flex justify-between items-end flex-wrap md:flex-nowrap mt-5">
                    <div className="pr-0 md:pr-6 w-full md:w-[300px]">
                        <p className="mt-1 text-sm text-gray-300 sr-only">Search by name, email or identificator</p>

                        <div className="mt-6 flex space-x-4">
                            <div className="flex-1 min-w-0">
                                <label htmlFor="search" className="sr-only">
                                Search
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                <input 
                                    type="search" 
                                    name="search" 
                                    id="search" 
                                    placeholder="Search..."
                                    value={ query}
                                    className="focus:ring-secondary focus:border-secondary text-white block w-full h-[48px] sm:text-sm border-none bg-gray-700 rounded-md placeholder-gray-300" 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                                />
                                </div>
                            </div>
                        </div>
                    </div>

                    <a 
                        href="#" 
                        className="bg-gradient mt-4 md:mt-0 border-none rounded-md shadow-sm w-full md:w-auto h-[48px] py-3 px-4 truncate inline-flex items-center justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none"
                        onClick={(e) => { e.preventDefault(); setAction('create'); setOpenForm(true) }}
                        >
                        + Add customer
                    </a>
                </div>
                
                <div className="mt-10 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg" onScroll={() => //Tooltip.hide(true)
                    true
                }>
                    
                        {/* <div className="w-full h-[6px] bg-gradient"></div> */}
                        {true &&
                        <>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'name' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('name')}
                                    >
                                    Name
                                    <img src={sort_mapping[sortConfig?.key === 'name' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                </th>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'email' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('email')}
                                    >
                                    Email
                                    <img src={sort_mapping[sortConfig?.key === 'email' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                </th>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[140px] sort ${sortConfig?.key === 'email' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('email')}
                                    >
                                    Pending NFTs
                                    <img src={sort_mapping[sortConfig?.key === 'email' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                </th>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer min-w-[230px]`}
                                    data-tooltip="Unique identification which helps you identify the user in your systems. (Ex: user id, UUID)."
                                >
                                    <div 
                                        className={`inline sort ${sortConfig?.key === 'details' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('details')}
                                    >
                                        Additional Identificator
                                        <img src={sort_mapping[sortConfig?.key === 'details' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                    </div>
                                    <img id="additionalIdentificator" src={info} className="w-4 h-4 inline ml-2" onClick={(e) => e.preventDefault()} />
                                    <Tooltip 
                                        anchorId="additionalIdentificator" 
                                        place="top"
                                        className='tooltip-sm'
                                        html="Unique identification which helps you identify the user in your systems. (Ex: user id, UUID)."
                                    />
                                </th>
                                <th
                                    scope="col"
                                    className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer sort ${sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : undefined}`}
                                        onClick={() => requestSort('created_at_timestamp')}
                                    >
                                    Added at
                                    <img src={sort_mapping[sortConfig?.key === 'created_at_timestamp' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700"></th>

                                </tr>
                            </thead>
                            <tbody className="border-l border-b border-r border-gray-600 rounded-md">
                                {currentTableData?.filter((customer) => (customer.name && customer.name.toLowerCase().includes(query.toLowerCase())) || customer.email.toLowerCase().includes(query.toLowerCase()) || (customer.name && customer.name.toLowerCase().includes(query.toLowerCase()))).map((customer, customerIdx) => (
                                <tr key={customerIdx}>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                    <div className="font-medium text-white">
                                        {customer.settings?.find((s:any) => s.name === 'automatic_payout_disabled')?.value === '1'
                                            ?   <>
                                                    <ExclamationIcon id={`ExclamationIcon${customer.id}`} className="w-4 h-4 inline mr-2 text-danger cursor-pointer" />
                                                    <Tooltip 
                                                        anchorId={`ExclamationIcon${customer.id}`} 
                                                        place="top"
                                                        className='tooltip-sm'
                                                        html="Automatic payout for this client is disabled"
                                                    />
                                                </>
                                            : <div className="w-4 h-4 hidden xs:inline-block mr-2"></div>
                                        }
                                        {customer.name}
                                    </div>
                                    {customerIdx !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" /> : null}
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                    {customer.email}
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >

                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                    {customer.details ?? ''}
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                        {customer.created_at_timestamp && moment.unix(customer.created_at_timestamp).fromNow()}
                                    </td>
                                    
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                        <a 
                                            href="#" 
                                            className=" text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                            onClick={(e) => { e.preventDefault(); setItem(customer.id); setAction('edit'); setOpenForm(true) }}
                                        >
                                            <img src={edit} alt="delete" className="w-4 h-4 mr-2" />
                                            Edit
                                        </a>
                                    </td>
                                    <td
                                    className={` px-3 py-3.5 text-sm text-white ${ customerIdx === 0 ? '' : 'border-t border-gray-600' }`}
                                    >
                                        <button 
                                            type="submit" 
                                            className="ml-2 text-primary cursor-pointer border-none rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium focus:outline-none items-center"
                                            onClick={() => { setOpen(true); setItem(customer.id)}}
                                            >
                                            <img src={bin} alt="delete" className="w-4 h-4 mr-2" />
                                            Delete
                                        </button>
                                    </td>
                                    
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        <TableScript />
                        </>
                        }
                        <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={customers.length}
                        pageSize={PageSize}
                        onPageChange={(page: any) => setCurrentPage(page)}
                        />
                </div>

            </div>

            <ConfirmationModal open={open} onClose={() => setOpen(false)} confirmAction={confirmAction} action="delete" title="Delete customer?" text="Are you sure you want to remove this customer and its settings?" />
            <FormModal open={openForm} onClose={() => setOpenForm(false)} item={item} page="customer" action={action} >
                {action == 'create' 
                ? <CustomerCreateForm onClose={() => {setOpenForm(false); mutateList()}} /> 
                : <CustomerEditForm item={item} onClose={() => {setOpenForm(false); mutateList()}} />
                }
            </FormModal>

            </>
                :   <NoResults item="customers">
                        <>
                            <a 
                                href="#" 
                                className="bg-gradient mt-6 border-none rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none"
                                onClick={() => { setAction('create'); setOpenForm(true) }}
                                >
                                + Add customer
                            </a>
                            <FormModal open={openForm} onClose={() => setOpenForm(false)} item={item} page="customer" action={action} >
                                <CustomerCreateForm onClose={() => {setOpenForm(false); mutateList()}} /> 
                            </FormModal>
                        </>
                    </NoResults> )}
                </>
            )}

        </DashboardLayout>
    )
}