import React from 'react';
import { useParams } from "react-router-dom";
import { WalletTokenTransferInterface } from "@contracts/WalletTokenTransferContract";
import useSortableData from '@utilities/SortingUtils';
import Pagination from '@components/ui/Pagination';
import { usePublicApiFetch } from "@utilities/RequestUtils";
import TransferFormModal from '@components/forms/TransferFormModal';
import {sort_mapping} from './../../../assets/data';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment.js';

import { 
    copy_w
} from './../../../assets/images';

let PageSize = 25;


interface Props {}
 
export default function HistoryNftsTable({ ...props }) {
    
    // Query params 
    const {page} = useParams<{ page: any }>();

    // Fetch history wallet tokens
    const { data: tokens, error, loading } = usePublicApiFetch<WalletTokenTransferInterface[]>('/api/tokens/transfers-history');

    // Modal settings
    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState(0);

    // Tooltip text settings
    const [ keyCopied, setKeyCopied ] = React.useState<boolean>(false);

    // Sort tokens
    const { items, requestSort, sortConfig } = useSortableData(tokens);

    // Pagination settings
    const [currentPage, setCurrentPage] = React.useState<number>(page || 1);

    // Get current page nfts
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items?.slice(firstPageIndex, lastPageIndex);
    }, [items, currentPage]);

    return (
        <>
        
        <div className="table-scroll overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
            <table className="min-w-full rounded-md">
                <thead>
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700">
                        
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Token / Collection
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        To Address
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Type
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700 cursor-pointer  sort ${sortConfig?.key === 'status' ? sortConfig.direction : undefined}`}
                        onClick={() => requestSort('status')}
                    >
                        Status 
                        <img src={sort_mapping[sortConfig?.key === 'status' ? sortConfig.direction : 'undefined']} className="w-4 h-4 inline ml-2" />
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        Created at
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-semibold text-white bg-gray-700`}
                    >
                        TX ID
                    </th>
                    </tr>
                </thead>
                <tbody  className="border-l border-b border-r border-gray-600 rounded-md">
                    {currentTableData?.map((token, tokenIndex) => (
                        <tr key={token.id}>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div className="aspect-w-1 aspect-h-1 w-16 overflow-hidden rounded-lg bg-gray-200 block">
                                    <img
                                    src={token.wallet_token?.collection_token?.thumbnail_url}
                                    alt={token.wallet_token?.collection_token?.name}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div> 
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div id={`token6-${tokenIndex+1}`} className="font-medium text-white truncate max-w-[150px]">
                                    { token.wallet_token?.collection_token?.name }: #{ token.wallet_token?.collection_token?.token_id }
                                    <Tooltip 
                                        anchorId={`token6-${tokenIndex+1}`}
                                        place="top"
                                        className='tooltip-sm'
                                        html={ token.wallet_token?.collection_token?.token_id }
                                    />
                                </div>
                                <div className="flex flex-col mt-1 text-gray-400">
                                    { token.wallet_token?.collection_token?.collection?.name }
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.customer && 
                                <div className="font-medium text-white">
                                    {token.customer.name}
                                </div>
                                }
                                <div className="flex flex-col mt-1 text-gray-300 break-all">{ token.to_address }</div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.type == 'client_transfer' ? 'Client transfer' : 'Return to customer'}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                <div>
                                    <span className={`w-2 h-2 inline-block rounded-full mr-1 ${token.status == 'transferred' ? 'bg-info' : 'bg-primary'}`}></span>
                                    {token.status}
                                </div>
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.created_at_timestamp && moment.unix(token.created_at_timestamp).format("D/MM/YYYY H:s")}
                            </td>
                            <td className={`px-3 py-3.5 text-sm text-white hidden-text ${ tokenIndex === 0 ? '' : 'border-t border-gray-600' }`}>
                                {token.transaction?.txid &&
                                    <div id={`token4-${tokenIndex+1}`} className="flex mt-1 text-gray-300 justify-start cursor-pointer h-[20px]">
                                        <span className="show">{token.transaction?.txid}</span><span className="hide">{token.transaction?.txid.slice(token.transaction?.txid.length - 6)}</span>
                                        <img 
                                            src={copy_w} 
                                            className="w-4 ml-2" 
                                            onClick={() => {if(window?.navigator) window.navigator.clipboard.writeText(token.transaction?.txid); setKeyCopied(true); setTimeout(() => setKeyCopied(false), 1000);}}
                                        />
                                        <Tooltip 
                                            anchorId={`token4-${tokenIndex+1}`}
                                            place="top"
                                            className='tooltip-sm'
                                            html={keyCopied == true ? 'Copied!' : token.transaction?.txid}
                                        />
                                    </div>
                                }
                                <div className="">
                                    {token.transaction 
                                        ? <a href={token.transaction?.explorer_url} target="_blank" className="text-primary cursor-pointer border-none rounded-md text-sm font-medium  focus:outline-none">
                                            Etherscan
                                        </a>
                                        : '-'
                                    }
                                </div>
                            </td>
                        
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.tokens?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
            />
            </div>
            </div>

            <TransferFormModal open={open} onClose={() => setOpen(false)} item={item} />
        </>
    )
}