import React from 'react';
import { config } from '@utilities/ConfigUtils';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import MainLayout from '@components/layouts/MainLayout';
import { useGuestOrRedirect } from '@utilities/AuthUtils';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import { toast } from 'react-toastify';

/**
 * Props
 */
export interface Props {

}

/**
 * Forgot Password Page Component
 */
export default function ForgotPasswordPage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Forgot password page';
    }, []);

    // Get request instance
    const requestInstance = useRequestInstance();

    // Ensure user is not authenticated to access this page
    useGuestOrRedirect();
    
    // Initial values
    const initialValues = { email: '' };

    // Handle form submit
    const handleSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute api request to register user
            let response = await requestInstance.post('/auth/password/email', data);

            if(response?.data?.message){

                // get current date
                const now = new Date();

                // compose time string for every 10 seconds
                const timeString = now.getHours() + ':' + now.getMinutes() + ':' + String(now.getSeconds()).charAt(0);

                toast(response.data.message, {type: "info", toastId: timeString});
            }
        } 
        
        catch (exception: any) {

            // Handle form errors
            handleFormError(exception, helpers);
        }
    
    }

    return (
        <MainLayout>
            <div className="flex items-center w-full lg:max-w-md justify-center lg:justify-start min-h-[calc(100vh-100px)]">
                <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-2xl md:text-3xl font-extrabold text-white">Forgot your password?</h2>
                </div>

                <Formik
                    initialValues={ initialValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="mt-8 space-y-6">
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div className="mb-6">
                                <label htmlFor="email-address" className="text-white text-md">
                                Email address
                                </label>
                                <Field
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className={`mt-1 block h-12 w-full border-transparent bg-gray-900 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${helpers.errors?.email ? 'border-1 border-danger' : 'border-none'}`}
                                    placeholder="Email address"
                                />
                                <FieldErrorMessage message={ helpers.errors?.email } />
                            </div>
                            
                        </div>

                        <div>
                            <FormButton loading={helpers.isSubmitting} label='Request reset link' type='auth' />
                        </div>

                        <div className="relative text-sm">
                            <span className=" text-white">
                                <a href="/" className="text-secondary underline hover:text-indigo-500 ml-2">Home</a>
                            </span>
                        </div>
                    </Form>
                    )}
                </Formik>
                
                </div>
            </div>
        </MainLayout>
    )
}