import React, { useState  } from 'react';
import { config } from '@utilities/ConfigUtils';
import DashboardLayout from '@components/layouts/DashboardLayout';
import FieldErrorMessage from '@components/form/FieldErrorMessage';
import FormButton from '@components/form/FormButton';
import { useAuthContext, useUserOrRedirect } from '@utilities/AuthUtils';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { useRequestInstance } from '@utilities/RequestUtils';
import { handleFormError } from '@utilities/FormUtils';
import { toast } from 'react-toastify';

import {
    ClipboardCopyIcon
  } from '@heroicons/react/outline'

import {
    CheckCircleIcon
  } from '@heroicons/react/solid'

/**
 * Props
 */
export interface Props {

}

/**
 * Profile Page Component
 */
export default function ProfilePage(props: Props) {

    // Set page title
    React.useEffect(() => {
        document.title = config('app.name') + ' | Profile';
    }, []);

    // Ensure user is authenticated
    const { user } = useUserOrRedirect();

    // Use request instance
    const requestInstance = useRequestInstance(user?.token);

    // Use auth context
    const authContext = useAuthContext();

    // Initial Password values
    const initialPasswordValues = { current_password: '', password: '', password_confirmation: '' };

    // Initial values
    const initialValues = { name: user?.name, verified: false };

    // Resend verification email
    const resendVerificationEmail = async () => {

        try {
           
            // Execute request
            const response = await requestInstance.get('/auth/email/resend');

            toast("Email verification link sent on your email id");

        } catch (err) {
          //console.log(err);
        }
    };

    // Handle account form submit
    const handleAccountSettingsSubmit = async (data: typeof initialValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.put(`/users`, data);

            // Collection is created, redirect to collections page
            if(!response.data?.data?.errors){
                authContext.updateUser({...user, name: data.name});
                toast("Account information has been successfully updated");
            }
        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }

    // Handle form submit
    const handleSubmit = async (data: typeof initialPasswordValues, helpers: FormikHelpers<any>) => {

        try {

            // Execute request
            const response = await requestInstance.post('/auth/password/change', { current_password: data.current_password, password: data.password, password_confirmation: data.password_confirmation});

            if(!response.data?.data?.errors)
                toast("Password has been successfully changed");

            // Clear form values
            helpers.resetForm();

        }

        catch(exception: any) {

            handleFormError(exception, helpers);
        }
    }


    return (
        <DashboardLayout title="Profile" page="profile">

            <div className="mt-10 divide-y divide-gray-500">
                <div className="space-y-1 flex justify-between">
                    <h3 className="text-lg leading-6 font-medium text-white">Account settings</h3>
                    { user?.verified &&
                    <div className="flex flex-col mb-8 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                        <div className="sr-only">Account status</div>
                        <div className="flex items-center text-sm text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                            <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" />
                            Verified account
                        </div>
                    </div>
                    }
                </div>

                { !user?.verified &&
                <div className="mt-6 px-4 py-4 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg bg-gray-700">
                    <div className="mt-8 space-y-6 w-full max-w-md mx-auto">
                        <div>
                            <h2 className="text-lg leading-6 font-medium text-white">Email not verified</h2>
                            <p className="mt-1 text-sm text-white">
                                Resend verification email to confirm your account
                            </p>
                        </div>
                        <div className="mt-4 py-4 flex justify-between">
                            <dl className="flex flex-col sm:flex-row sm:flex-wrap">
                                <dt className="sr-only">Account status</dt>
                                <dd className="mt-3 flex items-center text-sm text-gray-400 font-medium sm:mr-6 sm:mt-0 capitalize">

                                    <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" /> Unverified account
                                </dd>
                            </dl>
                            <span className="ml-4 flex items-end">
                                <button 
                                    type="button" 
                                    className="relative justify-center px-4 py-2 text-sm font-medium text-white border-transparent rounded-md group focus:outline-none inline-flex bg-gradient hover:bg-sky-800"
                                    onClick={ resendVerificationEmail }
                                    >
                                    Resend email
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                }

                <div className="mt-6 px-4 py-4 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg bg-gray-700">
                    <Formik
                        enableReinitialize={true}
                        initialValues={ initialValues }
                        onSubmit={ handleAccountSettingsSubmit }>
                        {(helpers) => (
                        <Form className="mt-8 space-y-6  w-full max-w-md mx-auto">

                            <div>
                                <h2 className="text-lg leading-6 font-medium text-white">Change account settings</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                </p>
                            </div>

                            <div className="mt-6 grid grid-cols-12 gap-6">

                                <div className="col-span-12">
                                    <label 
                                        htmlFor="name" 
                                        className="block text-sm font-medium text-gray-200">Name</label>
                                    <Field 
                                        type="text" 
                                        name="name" 
                                        id="name"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => helpers.setFieldValue("name", e.target.value)}
                                        className="mt-1 block h-12 w-full border-none bg-gray-600 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm" 
                                    />
                                    <FieldErrorMessage message={ helpers.errors?.name } />
                                </div>

                            </div>

                        <div className="mt-4  py-4 flex justify-end">
                            <FormButton loading={helpers.isSubmitting} label='Submit' type='submit' />
                        </div>
                        </Form>
                        )}
                    </Formik>
                </div>

                <div className="mt-6 px-4 py-4 align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg bg-gray-700">
                <Formik
                    initialValues={ initialPasswordValues }
                    onSubmit={ handleSubmit }>
                    {(helpers) => (
                    <Form className="mt-8 space-y-6 w-full max-w-md mx-auto">
                        <div>
                            <h2 className="text-lg leading-6 font-medium text-white">Change password</h2>
                            <p className="mt-1 text-sm text-white">
                                Change account password
                            </p>
                        </div>

                        <div className="mt-6 grid grid-cols-6 gap-6">

                            <div className="col-span-6">
                                <label 
                                    htmlFor="current_password" 
                                    className="block text-sm font-medium text-white">Current password</label>
                                <Field 
                                    type="password" 
                                    name="current_password" 
                                    id="current_password" 
                                    className="mt-1 block h-12 w-full border-none bg-gray-600 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm" 
                                />
                                <FieldErrorMessage message={ helpers.errors?.current_password } />
                            </div>

                            <div className="col-span-6">
                                <label 
                                    htmlFor="password" 
                                    className="block text-sm font-medium text-white">New password</label>
                                <Field 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    className="mt-1 block h-12 w-full border-none bg-gray-600 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm" 
                                />
                                <FieldErrorMessage message={ helpers.errors?.password } />
                            </div>
                            
                            <div className="col-span-6">
                                <label 
                                    htmlFor="password_confirmation" 
                                    className="block text-sm font-medium text-white">Confirm password</label>
                                <Field 
                                    type="password" 
                                    name="password_confirmation" 
                                    id="password_confirmation" 
                                    className="mt-1 block h-12 w-full border-none bg-gray-600 text-white placeholder-gray-200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm" 
                                />
                                <FieldErrorMessage message={ helpers.errors?.password_confirmation } />
                            </div>

                        </div>

                    <div className="mt-4 py-4 flex justify-end">
                        <FormButton loading={helpers.isSubmitting} label='Submit' type='submit' />
                    </div>
                    </Form>
                    )}
                </Formik>
                </div>

                
            </div>
        </DashboardLayout>
    )
}